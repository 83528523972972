import React from 'react'
import Image from '../Image'
import styles from "./NoDataFound.module.sass";

const index = () => {
    return (
        <div>
            <Image
                className={styles.customImage}
                src="/images/content/no-data-found.png"
                srcdark="/images/content/no-data-found-light.png"
                alt="No data found"
            />
        </div>
    )
}

export default index;
