import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./DepositList.module.sass";
import Wallet from "../../components/Wallet";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const DepositList = () => {
    const [visibleWithdraw, setVisibleWithdraw] = useState(false);
    // const dispatch = useDispatch();
    const [getWalletDepositListData, setGetWalletDepositListData] = useState([]);
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [pageNo, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDepositList(pageNo);
    }, [pageNo]);

    const handleLoadMore = () => {
        setPageNo(pageNo + 1);
    };

    const getDepositList = async (page) => {
        setLoading(true);
        try {
            let data = {
                signature: localStorage.getItem("signature"),
                page: page,
            };
            const depositListPayload = await requestHandler("getDepositList", "post", data, "jwt_token");
            setLoading(false);
            setGetWalletDepositListData(depositListPayload?.data?.data);
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: "Something went wrong.",
                type: "danger"
            });
        };
    };

    return (
        <>
            <Wallet userStatus={userStatus}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <div className={styles.body}>
                            <Funds
                                getWalletDepositListData={getWalletDepositListData}
                                handleLoadMore={handleLoadMore}
                                pageNo={pageNo}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </Wallet>
            <Modal
                visible={visibleWithdraw}
                onClose={() => setVisibleWithdraw(false)}
            >
                <Withdraw />
            </Modal>
        </>
    );
};






export default DepositList