import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./FiatDepositList.module.sass";
import Wallet from "../../components/Wallet";
import Funds from "./Funds";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

var statusOptions = [
    { cId: parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_1), cValue: "Coupon Cashback" },
    { cId: parseInt(process.env.REACT_APP_COUPON_TYPE_PAYOUT_2), cValue: "Commission" },
];

const FiatDepositList = () => {
    const [cashbackListData, setCashbackListData] = useState([]);
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [pageNo, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [statusText, setStatusText] = useState(statusOptions[0].cValue);
    const [statusValue, setStatusValue] = useState(statusOptions[0].cId);

    useEffect(() => {
        getCashbackList();
    }, [statusValue]);

    const handleLoadMore = () => {
        setPageNo(pageNo + 1);
    };

    const getCashbackList = async () => {
        setLoading(true);
        try {
            let data = {
                signature: localStorage.getItem("signature"),
                type: statusValue,
            };
            const payload = await requestHandler("getReferralHistory", "post", data, "jwt_token");
            setLoading(false);
            setCashbackListData(payload?.data?.data);
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: "Something went wrong.",
                type: "danger"
            });
        }
    };

    return (
        <>
            <Wallet userStatus={userStatus}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <div className={styles.body}>
                            <Funds
                                cashbackListData={cashbackListData}
                                pageNo={pageNo}
                                handleLoadMore={handleLoadMore}
                                loading={loading}
                                statusOptions={statusOptions}
                                statusText={statusText}
                                setStatusText={setStatusText}
                                statusValue={statusValue}
                                setStatusValue={setStatusValue}
                            />
                        </div>
                    </div>
                </div>
            </Wallet>
        </>
    );
};


export default FiatDepositList;