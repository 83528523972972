import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";

const Successfully = ({ item, closeModal, cancelOrder }) => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Cancel!
        </div>
        <div className={styles.info}>
          Are you sure to cancel your withdraw request
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-small button-red", styles.button)}
            onClick={closeModal}
          >
            No
          </button>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={() => cancelOrder(item?.id, item?.reference)}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
