import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ item, index }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} >
          <div className={styles.col}>
            {index + 1}
          </div>
          <div className={styles.col}>
            {item?.uid}
          </div>
          <div className={styles.col}>
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) && (<div className={cn("category-blue")}>Pending</div>)}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_USER_EXIT) && (<div className={cn("category-blue")}>User Exit</div>)}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_UNDER_VERIFICATION) && (<div className={cn("category-blue")}>In Process</div>)}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_ERROR) && (<div className={cn("category-red")}>Cancelled</div>)}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) && (<div className={cn("category-green")}>Complete</div>)}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_INREVIEW) && (<div className={cn("category-blue")}>In process</div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
