import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ item }) => {
  const [visible, setVisible] = useState(false);

  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US');
  };

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} >
          <div className={styles.col}>
            {item?.name}
          </div>
          <div className={styles.col}>
            {item?.percentage}%
          </div>
          <div className={styles.col}>
            {item?.min_invites}
          </div>
          {/* <div className={styles.col}>
            {item?.max_invites}
          </div> */}
          <div className={styles.col}>
            {item?.min_volume === 0 ? "Not required" : formatNumberWithCommas(item?.min_volume)}
          </div>
          {/* <div className={styles.col}>
            {item?.max_volume}
          </div> */}
          <div className={styles.col}>
            {item?.assesment}
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
