
import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "./OrderBookSkeleton.module.sass";

const OrderBookSkeleton = ({ rowCount }) => {
    var themeMode = localStorage.getItem('darkMode');
    const getSkeleton = () => {
        return (
            <Skeleton
                height={15}
                width="95%"
                baseColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_BASE_DARK : process.env.REACT_APP_SKELETON_BASE_LIGHT}
                highlightColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_HIGHLIGHT_DARK : process.env.REACT_APP_SKELETON_HIGHLIGHT_LIGHT}
            />
        )
    }
    return (
        Array(rowCount)
            .fill()
            .map((item, index) => (
                <div className={styles.item} key={index}>
                    <div className={styles.price}> {getSkeleton()} </div>
                    <div className={styles.amount}> {getSkeleton()} </div>
                    <div className={styles.total}> {getSkeleton()} </div>
                </div>
            ))
    );
};

export default OrderBookSkeleton;

