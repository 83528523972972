import { Ref, useEffect, useRef, useState } from 'preact/hooks'
import Webcam, { WebcamProps } from 'react-webcam'
import { TrackScreenCallback } from '~types/hocs'
import { BlurDetectionResult } from '../OpenCV/BlurDetection'
import { DetectionParams } from '../OpenCV/DetectionParams'
import { EdgeDetectionResults } from '../OpenCV/EdgeDetection'
import { Rectangle } from '../OpenCV/Geometrie'
import { AnalyticsCollector } from './AnalyticsCollector'
import { ClassAttributes } from 'preact'
import { BaseDocumentAnalytics } from './types'

const useAnalytics = (
  initialParams: DetectionParams,
  documentAnalytics: BaseDocumentAnalytics,
  trackScreen: TrackScreenCallback,
  webcamRef: Ref<Webcam>,
  webcamProps: WebcamProps | ClassAttributes<Webcam> | null
) => {
  const analytics = useRef<AnalyticsCollector>(
    new AnalyticsCollector(documentAnalytics)
  )
  const [trackCaptureStart, setTrackCaptureStart] = useState(false)
  const [detectionRectangle, setDetectionRectangle] = useState<
    Rectangle | undefined
  >()

  useEffect(() => {
    analytics.current.setInitialParams(initialParams)
  }, [initialParams])

  useEffect(() => {
    if (
      !trackCaptureStart ||
      !detectionRectangle ||
      !webcamRef.current ||
      !webcamProps ||
      !analytics
    ) {
      return
    }

    analytics.current.setVideoConstraints(
      (webcamProps as WebcamProps)?.videoConstraints as MediaTrackConstraints
    )
    analytics.current.setCapabilities(
      webcamRef.current.stream?.getVideoTracks()[0].getCapabilities()
    )
    analytics.current.setSettings(
      webcamRef.current.stream?.getVideoTracks()[0].getSettings()
    )

    trackScreen(
      'capture_start',
      analytics.current.reportJsDocumentCaptureStart()
    )
    setTrackCaptureStart(false) // prevent other runs of this useEffect
  }, [
    trackCaptureStart,
    detectionRectangle,
    webcamRef,
    webcamProps,
    trackScreen,
  ])

  const collectTimeout = () =>
    analytics.current.setAutocaptureFailReason('timeout')

  const collectHoldStillPhaseEnded = () =>
    analytics.current.addCounter('hold_still_phase', 1)

  const collectAutoCaptureSuccess = (data: any) => {
    analytics.current.setSelectedImageLaplacianVariance(data.data.blurVariance)
    analytics.current.setAutoCaptureSuccess(true)
  }

  const collectSetDetectionError = () =>
    analytics.current.addCounter('warning_document', 1)

  const collectManualCapture = () =>
    analytics.current.setAutoCaptureSuccess(false)

  const collectEdgeResults = (
    result: EdgeDetectionResults,
    params: DetectionParams
  ) => analytics.current.addEdgeCounters(result, params)

  const collectDetectionRectangle = (rect: Rectangle) => {
    setDetectionRectangle(rect)
    analytics.current.setDetectionRectangle(rect)
  }

  const collectBlurResults = (
    result: BlurDetectionResult,
    params: DetectionParams
  ) => analytics.current.addBlurCounters(result, params)

  const collectFallbackParams = (params: DetectionParams) => {
    analytics.current.setFinalParams(params)
  }

  const trackJsDocumentCaptureScreen = () =>
    trackScreen(undefined, analytics.current.reportJsDocumentCaptureScreen())

  const trackAutoCaptureSuccessOrManualFrameSelected = () => {
    if (analytics.current.getAutoCaptureSuccess()) {
      const data = analytics.current.reportJsDocumentCaptureAutoCaptureSuccess()
      data && trackScreen('auto_success', data)
    } else {
      const data = analytics.current.reportJsDocumentCaptureManualFrameSelected()
      data && trackScreen('manual_frame_selected', data)
    }
  }

  const trackManualShutterPressed = () => {
    const data = analytics.current.reportJsDocumentManualShutterPressed()
    trackScreen('shutter_pressed', data)
  }

  const getAutoCaptureSuccess = () => analytics.current.getAutoCaptureSuccess()

  return {
    collectAutoCaptureSuccess,
    collectBlurResults,
    collectDetectionRectangle,
    collectEdgeResults,
    collectFallbackParams,
    collectHoldStillPhaseEnded,
    collectManualCapture,
    collectSetDetectionError,
    collectTimeout,
    getAutoCaptureSuccess,
    trackAutoCaptureSuccessOrManualFrameSelected,
    trackJsDocumentCaptureScreen,
    trackManualShutterPressed,
    setTrackCaptureStart,
  }
}

export { useAnalytics }
