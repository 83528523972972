import { isDoubleSidedDocument } from '~utils/Documents'
import { CountryData, DocumentSides, ErrorNames } from '~types/commons'
import { TrackScreenCallback } from '~types/hocs'
import { ErrorProp } from '~types/routers'
import { DocumentTypes } from '~types/steps'
import { lowerCase } from '~utils/string'
import {
  GenericDocumentAnalytics,
  DocumentAutoCaptureAnalytics,
} from '~utils/onfidoApi'
import { GlobalState } from '~types/redux'

const DOCUMENT_TRACKING_ERRORS: Array<ErrorNames> = [
  'BLUR_DETECTED',
  'GLARE_DETECTED',
  'CUTOFF_DETECTED',
  'DOCUMENT_DETECTION',
]

const isDocumentTrackingError = (error: ErrorNames) =>
  DOCUMENT_TRACKING_ERRORS.includes(error)

const trackDocumentError = (
  error: ErrorProp,
  trackScreen: TrackScreenCallback
) => {
  if (error.type === undefined) {
    // keep default behaviour for undefined type
    trackScreen(lowerCase(error.name), error.properties)
  }
  if (error.type === 'warning') {
    trackScreen(lowerCase(`${error.name}_warning`), {
      ...error.properties,
      ...error.analyticsProperties,
    })
  }
  if (error.type === 'error') {
    trackScreen(lowerCase(`${error.name}_error`), {
      ...error.properties,
      ...error.analyticsProperties,
    })
  }
}

const trackSuccessfulDocumentUpload = (
  documentType: DocumentTypes | undefined,
  side: DocumentSides,
  country: CountryData | undefined,
  trackScreen: TrackScreenCallback,
  autoCaptureAnalytics: DocumentAutoCaptureAnalytics,
  genericDocumentAnalytics: GenericDocumentAnalytics
): void => {
  if (side === 'front' && isDoubleSidedDocument(documentType)) {
    // wait for the back side.
    return
  }
  // DOCUMENT_CAPTURE_FLOW_COMPLETED
  trackScreen('flow_completed', {
    document_type: documentType,
    document_side: side,
    country_code: country?.country_alpha3,
    ...autoCaptureAnalytics,
    ...genericDocumentAnalytics,
  })
}

const getGenericDocumentAnalytics = (
  props: GlobalState
): GenericDocumentAnalytics => {
  const { title: generic_document_title, pages: generic_document_pages } =
    props.genericDocumentType || {}

  return {
    generic_document_title,
    generic_document_pages,
  }
}

export {
  isDocumentTrackingError,
  trackDocumentError,
  trackSuccessfulDocumentUpload,
  getGenericDocumentAnalytics,
}
