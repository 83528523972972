import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./StepTwo.module.sass";
import TextInput from "../../../components/TextInput";
import { addNotification } from "../../../components/Notification";
import requestHandler from "../../../actions/httpClient";
import SimpleReactValidator from "simple-react-validator";
import { userStatusCreator } from "../../../actions/getUserStatus";
import { useDispatch } from "react-redux";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LoaderScreen from "../../../components/LoaderScreen";

const StepTwo = ({ goNext, userStatus }) => {
  const [firstName, setFirstName] = useState(userStatus.fname)
  const [lastName, setLastname] = useState(userStatus.lname)
  const [email, setEmail] = useState(userStatus.email)
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonProperty, setButtonProperty] = useState()
  const [, setForceUpdate] = useState()
  const simpleValidator = useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (firstName === '' || lastName === '' || email === ''
      // || countryCode === "" || phoneNumber === ""
    ) {
      setButtonProperty(false)
    } else {
      setButtonProperty(true)
    }
  }, [])

  const submitHandler = async (firstName, lastName, email, country_code, mobile_no) => {
    setLoading(true);
    let dummyNumber = mobile_no;
    let finalNumber = dummyNumber.slice(countryCode.length);
    var data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      signature: localStorage.getItem('signature'),
      country_code: country_code,
      mobile_no: finalNumber,
    }
    try {
      const response = await requestHandler('getApplicantid', 'post', data, 'jwt_token')
      setLoading(false);
      if (response.status === 200) {
        dispatch(userStatusCreator())
        goNext()
      }
    } catch (error) {
      setLoading(false);
      dispatch(userStatusCreator())
    }
  }

  return (
    <div>
      <div className={styles.list}>
        <div className={styles.item}>
          {loading && <LoaderScreen />}
          <div className={styles.subtitle}>KYC Verification</div>
          <div className={styles.group}>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="First name"
                name="firstname"
                type="text"
                value={firstName === 'null' ? setFirstName("") : firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={buttonProperty}
                note="Your First Name As Stated On Official Documents (ID CARD, DRIVING LICENSE or PASSPORT)"
              />
            </div>
            <span>{simpleValidator.current.message("FirstName", firstName, "required")}</span>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Last name"
                name="lastname"
                type="text"
                value={lastName === 'null' || lastName === null ? setLastname("") : lastName}
                onChange={(e) => setLastname(e.target.value)}
                disabled={buttonProperty}
                note="Your Last Name As Stated On Official Documents (ID CARD, DRIVING LICENSE or PASSPORT)"
              />
            </div>
            <span>{simpleValidator.current.message("LastName", lastName, "required")}</span>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Email"
                name="Email"
                type="text"
                value={email}
                disabled={true}
              />
            </div>
            <PhoneInput
              country={'us'}
              value={phoneNumber}
              name="phone"
              countryCode
              onChange={(phone, country) => {
                setPhoneNumber(phone)
                setCountryCode(country.dialCode);
              }}
            />
            {/* <div className={styles.box}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Country Code"
                type="text"
                name="countryCode"
                placeholder="Please enter your country code"
                value={countryCode}
                maxLength={3}
                onChange={(e) => setCountryCode((v) => (e.target.validity.valid ? e.target.value : v))}
                pattern="[0-9]*"
                disabled={buttonProperty}
              />
            </div>
            <span>{simpleValidator.current.message("countryCode", countryCode, "required")}</span>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Phone Number"
                name="phoneNumber"
                placeholder="Please enter your phone number"
                type="text"
                value={phoneNumber}
                maxLength={15}
                onChange={(e) => setPhoneNumber((v) => (e.target.validity.valid ? e.target.value : v))}
                pattern="[0-9]*"
                disabled={buttonProperty}
              />
            </div>
            <span>{simpleValidator.current.message("phoneNumber", phoneNumber, "required")}</span> */}
          </div>

          <button className={cn("button", styles.button)} onClick={() => {
            if (simpleValidator.current.allValid()) {
              if (phoneNumber !== "" && phoneNumber.slice(countryCode.length)) {
                submitHandler(firstName, lastName, email, countryCode, phoneNumber);
              }
              else {
                addNotification({
                  title: "Alert",
                  message: "Please enter your phone number.",
                  type: "danger"
                });
              }
            }
            else {
              simpleValidator.current.showMessages();
              setForceUpdate(1);
            }
          }}>
            Continue
          </button>

        </div>
      </div>
    </div>
  );
};

export default StepTwo;
