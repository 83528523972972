import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h4>Invite your friends and earn up to {process.env.REACT_APP_COMMISSION_RATE}% commission rate.</h4>
          <p>
            1. Earn lifetime commission with Bitdenex, no cap, no time limit, get higher commission
            as you get more invitees.<br />
            2. The statistical objects are the users.<br />
            3. All referral commissions (both those received by inviters and those shared with their
            invited friends) are calculated in the particular coin or fiat and transferred to the
            respective Bitdenex accounts instantly after the trades are completed by your invitee.<br />
            4. In order for an invited friend to receive a cashback, they must register using the
            corresponding referral link (or referral ID). If they register using an old or not valid ID
            code of the person who invited them, instead of via the link, they will not receive any
            commission or cashback offered to them by their inviter.<br />
            5. There is no limit to the number of friends a single account can invite.<br />
            6. Bitdenex does not allow any user to self-invite through multiple accounts. Once such
            activity has been detected, all referrals will be cancelled and all referral commission
            cashbacks for the invitee’s accounts will be cancelled.<br />
            7. The term to receive your referral commission is undefined.<br />
            8. In case of an active coupon or a different cashback option is active, no commission
            will be paid out, until the coupon or cashback is fully utilized or not valid anymore.<br />
            9. Bitdenex does not credit commission for zero-fees trades. No trading fees paid, means
            no commission payout either.<br />
            10. Any fraud or misuse of the referral program will not be tolerated. In the event of any
            fraud or misuse, Bitdenex will close your account immediately without any notice.
            Your wallets will be freeze upon further notice.<br />
            11. Bitdenex may adjust the proportion of referrals at any point and reserves the right to
            adjust the Terms and Conditions of the referral program at any time at their sole
            discretion.<br />
            12. Bitdenex reserves the right to disqualify users from participating in referral & affiliate
            programs and revoke any commissions from users who engage in dishonest or abusive
            activities to earn commissions. This includes bulk-account registrations to obtain
            additional bonuses, using “phishing” websites containing referral links, posting
            referral links as advertisements on search engines, and any other activity concerning
            unlawful, fraudulent, or harmful purposes.<br />
            13. If you have any questions, please contact support@bitdenex.com.<br /><br />
            Definition of valid invitees:
            The users you directly invite to register, not users invited by sub-affiliates or referees. In case
            and in Bitdenex’s opinion, the invitee is not legitimate or seems not legitimate, this invitee
            will be blocked and excluded from any payouts, either commission, cashback, bonus etc.
          </p>
          <figure>
            <img srcSet="/images/content/referral/article.jpg" src="/images/content/referral/article.jpg" alt="Content" />
          </figure>

          <h3>Important Notice:</h3>
          <p>
            Bitdenex reserves the exclusive right to change the Terms and Conditions of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant at their sole discretion.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Article;
