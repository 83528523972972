import React, { useEffect, useState } from "react";
import styles from "./OpenOrdersList.module.sass";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import Order from "../../components/Order";
import Main from "./Main";
import requestHandler from "../../actions/httpClient";
import { userStatusCreator } from "../../actions/getUserStatus";
import { useDispatch } from "react-redux";
const OpenOrdersList = () => {

  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [allOrders, setAllOrders] = useState()
  const [reloadFlag, setReloadFlag] = useState()
  const dispatch = useDispatch();

  const handleReload = (value) => {
    setReloadFlag(value)
  }
  useEffect(() => {
    getOpenOrders(pageno)
    dispatch(userStatusCreator());
  }, [pageno, reloadFlag]);

  const handleLoadMore = () => {
    setPageno(pageno + 1)
  }

  const getOpenOrders = async (page) => {
    try {
      if (page === 1)
        setLoading(true);
      else
        setBtnLoading(true)

      const data = {
        signature: localStorage.getItem("signature"),
        page: page
      }

      const payload = await requestHandler('getAllOpenOrders', 'post', data, 'jwt_token')

      setAllOrders(payload);
      if (page === 1)
        setLoading(false);
      else
        setBtnLoading(false)

    } catch (error) {
      setLoading(false);
    }
  }


  return (
    <>
      <Order >
        <Main />
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>Open Orders</div>
            <div className={styles.body}>
              <Funds allOrders={allOrders} handleLoadMore={handleLoadMore} handleReload={handleReload} pageno={pageno} loading={loading} btnLoading={btnLoading} />
            </div>
          </div>
        </div>
      </Order>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw />
      </Modal>
    </>
  );
};

export default OpenOrdersList;
