import { CV, Mat, Point } from '@onfido/opencv'
import { ComputedParams, Position } from './EdgeDetection'
import { Rectangle } from './Geometrie'
const RED = [255, 0, 0, 255]
const GREEN = [0, 255, 0, 255]
const BLUE = [0, 0, 255, 255]
const lineWeight = 2

export type Color = 'RED' | 'GREEN' | 'BLUE'

const drawLine = (
  cv: CV,
  dst: Mat,
  startPoint: Point,
  endPoint: Point,
  color: Color = 'RED'
) => {
  let col = RED
  switch (color) {
    case 'BLUE':
      col = BLUE
      break
    case 'RED':
      col = RED
      break
    case 'GREEN':
      col = GREEN
      break
  }
  cv.line(dst, startPoint, endPoint, col, lineWeight)
}

const drawRectangle = (cv: CV, rect: Rectangle, dst: Mat) => {
  cv.line(
    dst,
    new cv.Point(rect.left, rect.top),
    new cv.Point(rect.right, rect.top),
    RED,
    lineWeight
  )
  cv.line(
    dst,
    new cv.Point(rect.left, rect.bottom),
    new cv.Point(rect.right, rect.bottom),
    RED,
    lineWeight
  )
  cv.line(
    dst,
    new cv.Point(rect.left, rect.top),
    new cv.Point(rect.left, rect.bottom),
    RED,
    lineWeight
  )
  cv.line(
    dst,
    new cv.Point(rect.right, rect.top),
    new cv.Point(rect.right, rect.bottom),
    RED,
    lineWeight
  )
}

const drawSelectedSegments = (
  cv: CV,
  key: Position,
  score: number,
  debugSegments: Mat,
  cp: ComputedParams,
  threshold: number,
  detected: boolean
) => {
  const humanScore = Math.round(score * 100) / 100 + ''
  cv.putText(
    debugSegments,
    threshold + '',
    new cv.Point(
      (cp.scaledOverlayCoordinates.right - cp.scaledOverlayCoordinates.left) /
        2,
      (cp.scaledOverlayCoordinates.bottom - cp.scaledOverlayCoordinates.top) / 2
    ),
    cv.FONT_HERSHEY_SIMPLEX,
    1,
    BLUE,
    lineWeight
  )
  switch (key) {
    case 'bottom':
      cv.putText(
        debugSegments,
        humanScore,
        new cv.Point(
          (cp.scaledOverlayCoordinates.right -
            cp.scaledOverlayCoordinates.left) /
            2,
          cp.scaledOverlayCoordinates.bottom - 15
        ),
        cv.FONT_HERSHEY_SIMPLEX,
        1,
        detected ? BLUE : RED,
        lineWeight
      )
      if (detected) {
        cv.line(
          debugSegments,
          new cv.Point(
            cp.scaledOverlayCoordinates.left,
            cp.scaledOverlayCoordinates.bottom
          ),
          new cv.Point(
            cp.scaledOverlayCoordinates.right,
            cp.scaledOverlayCoordinates.bottom
          ),
          BLUE,
          lineWeight
        )
      }
      break
    case 'top':
      cv.putText(
        debugSegments,
        humanScore,
        new cv.Point(
          (cp.scaledOverlayCoordinates.right -
            cp.scaledOverlayCoordinates.left) /
            2,
          cp.scaledOverlayCoordinates.top + 30
        ),
        cv.FONT_HERSHEY_SIMPLEX,
        1,
        detected ? BLUE : RED,
        lineWeight
      )
      if (detected) {
        cv.line(
          debugSegments,
          new cv.Point(
            cp.scaledOverlayCoordinates.left,
            cp.scaledOverlayCoordinates.top
          ),
          new cv.Point(
            cp.scaledOverlayCoordinates.right,
            cp.scaledOverlayCoordinates.top
          ),
          BLUE,
          lineWeight
        )
      }
      break
    case 'left':
      cv.putText(
        debugSegments,
        humanScore,
        new cv.Point(
          cp.scaledOverlayCoordinates.left + 15,
          (cp.scaledOverlayCoordinates.bottom -
            cp.scaledOverlayCoordinates.top) /
            2
        ),
        cv.FONT_HERSHEY_SIMPLEX,
        1,
        detected ? BLUE : RED,
        lineWeight
      )
      if (detected) {
        cv.line(
          debugSegments,
          new cv.Point(
            cp.scaledOverlayCoordinates.left,
            cp.scaledOverlayCoordinates.bottom
          ),
          new cv.Point(
            cp.scaledOverlayCoordinates.left,
            cp.scaledOverlayCoordinates.top
          ),
          BLUE,
          lineWeight
        )
      }
      break
    case 'right':
      cv.putText(
        debugSegments,
        humanScore,
        new cv.Point(
          cp.scaledOverlayCoordinates.right - 60,
          (cp.scaledOverlayCoordinates.bottom -
            cp.scaledOverlayCoordinates.top) /
            2
        ),
        cv.FONT_HERSHEY_SIMPLEX,
        1,
        detected ? BLUE : RED,
        lineWeight
      )
      if (detected) {
        cv.line(
          debugSegments,
          new cv.Point(
            cp.scaledOverlayCoordinates.right,
            cp.scaledOverlayCoordinates.bottom
          ),
          new cv.Point(
            cp.scaledOverlayCoordinates.right,
            cp.scaledOverlayCoordinates.top
          ),
          BLUE,
          lineWeight
        )
      }
      break
  }
}

export { drawLine, drawRectangle, drawSelectedSegments }
