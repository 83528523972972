import styles from "./SelectQuestions.module.sass";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import { useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../../../components/Notification";

const transferFund = ["Yes", "No"];

const EnterAddress = ({ handleSubmit, residentData }) => {
    const [zipCode, setZipcode] = useState('')
    const [address, setAddress] = useState('')
    const [appartment, setAppartment] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [addressProof, setAddressProof] = useState('')

    //   const [city, setCity] = useState(userStatus.city)


    return (
        <div className={styles.wrap}>
            <div className={styles.title}>As per Wwft Regulations in the Netherlands and Europe, please provide us the following information.</div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label="ADDRESS"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter address"
                    // disabled={buttonProperty}

                    />
                </div>
                <div className={styles.col}>
                    <TextInput
                        label="Apartment/suite number"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        //value={appartment == 'null' || appartment == null ? setAppartment("") : appartment}
                        placeholder="Enter apartment address"
                        onChange={(e) => setAppartment(e.target.value)}
                    />
                </div>
            </div>
            <br />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label="City"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Enter your city"
                    />
                </div>
                <div className={styles.col}>
                    <TextInput
                        label="State"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="Enter you state"
                    />
                </div>
            </div>
            <br />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label="Country"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="Enter your country"
                    />
                </div>
                <div className={styles.col}>
                    <TextInput
                        label="Zip/Postal code"
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        onChange={(e) => setZipcode(e.target.value)}
                        placeholder="Enter zip code"
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <br />
                    <TextInput
                        className={styles.dropdown}
                        label="Scan With Proof Of address"
                        name="addressProof"
                        type="file"
                        note="Use Screenshot of your address where it exists."
                        required
                        onChange={e => setAddressProof(e.target.files[0])}
                    />

                </div>
            </div>
            <br />

            <button className={cn("button", styles.button)} onClick={(e) => {
                if (zipCode !== "" && address !== "" && appartment !== "" && country !== "" && state !== "" && city !== "" && addressProof !== "") {

                    e.preventDefault();
                    residentData(zipCode, address, appartment, country, state, city, addressProof);
                    handleSubmit()
                } else {
                    addNotification({
                        title: "Alert",
                        message: "Please fill all the fields.",
                        type: "Danger"
                    });
                }
            }
            }>
                Continue
            </button>
        </div>
    )
};

export default EnterAddress;
