import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import History from "../../../../components/History";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Item = ({ item, children, active }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)} >
          <div className={styles.col}>
            <div className={styles.info}><small>{item.orderId}</small></div>
          </div>
          <div className={styles.col}>{item?.slug?.toUpperCase()}</div>
          <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (
              <div className={styles.positive}>Buy</div>
            )}
            {(item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (
              <div className={styles.negative}>Sell</div>
            )}
          </div>
          {/* <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER)) && (<div className={styles.info}>Limit</div>)}
            {(item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (<div className={styles.info}>Market</div>)}
          </div> */}
          {active && (
            <div className={styles.col}>
              {(item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item?.orderType === process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                <div className={styles.positive}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small> {item?.slug.split("-")[1].toUpperCase()} </small> </div>
              ) :
                (<div className={styles.negative}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small> {item?.slug.split("-")[1].toUpperCase()} </small> </div>)}
            </div>
          )}

          {/* <div className={styles.col}>
            {(item?.orderType === process.env.REACT_APP_BUY_LIMIT_ORDER || process.env.REACT_APP_BUY_MARKET_ORDER) ? (
              <div className={styles.positive}> {getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)}  <small> {item?.coin?.toUpperCase()} </small>   </div>
            ) :
              (<div className={styles.negative}> {getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)}  <small> {item?.coin?.toUpperCase()}  </small>   </div>)}
          </div> */}
          {
            (active && item.status === parseInt(process.env.REACT_APP_PARTIALLY_FILLED_ORDER)) ?
              (<div className={styles.col}>
                {(item?.orderType === process.env.REACT_APP_BUY_LIMIT_ORDER || process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                  <div className={styles.positive}> {getDigitsAfterDecimal(parseFloat(item.crypto_amount) - parseFloat(item.remCrypto), item.amountDecimalPrecision)}  <small> {item?.coin?.toUpperCase()} </small>   </div>
                ) :
                  (<div className={styles.negative}> {getDigitsAfterDecimal(parseFloat(item.crypto_amount) - parseFloat(item.remCrypto), item.amountDecimalPrecision)}  <small> {item?.coin?.toUpperCase()}  </small>   </div>)}
              </div>) :
              <div className={styles.col}>
                {(item?.orderType === process.env.REACT_APP_BUY_LIMIT_ORDER || process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                  <div className={styles.positive}> {getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)}  <small> {item?.coin?.toUpperCase()} </small>   </div>
                ) :
                  (<div className={styles.negative}> {getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)}  <small> {item?.coin?.toUpperCase()}  </small>   </div>)}
              </div>
          }
          {/* <div className={styles.col}>
            {(item?.orderType === process.env.REACT_APP_BUY_LIMIT_ORDER || process.env.REACT_APP_BUY_MARKET_ORDER) ? (
              <div className={styles.positive}> {getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}  <small> {item?.currency?.toUpperCase()} </small>   </div>
            ) :
              (<div className={styles.negative}> {getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}  <small> {item?.currency?.toUpperCase()}  </small>   </div>)}
          </div> */}
          {(active && item.status === parseInt(process.env.REACT_APP_PARTIALLY_FILLED_ORDER)) ?
            <div className={styles.col}>
              {(item?.orderType === process.env.REACT_APP_BUY_LIMIT_ORDER || process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                // <div className={styles.positive}> {getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}  <small> {item?.currency?.toUpperCase()} </small>   </div>
                <div className={styles.positive}> {getDigitsAfterDecimal(parseFloat(item.fiat_amount) - parseFloat(item.remFiat), item.priceDecimalPrecision)}  <small> {item?.currency?.toUpperCase()} </small>   </div>
              ) :
                (<div className={styles.negative}> {getDigitsAfterDecimal(parseFloat(item.fiat_amount) - parseFloat(item.remFiat), item.priceDecimalPrecision)}  <small> {item?.currency?.toUpperCase()}  </small>   </div>)}
            </div> :
            <div className={styles.col}>
              {(item?.orderType === process.env.REACT_APP_BUY_LIMIT_ORDER || process.env.REACT_APP_BUY_MARKET_ORDER) ? (
                <div className={styles.positive}> {getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}  <small> {item?.currency?.toUpperCase()} </small>   </div>
              ) :
                (<div className={styles.negative}> {getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)}  <small> {item?.currency?.toUpperCase()}  </small>   </div>)}
            </div>
          }
          {/* {active && (<div className={styles.col}>{getDigitsAfterDecimal(item?.fiatFee, item?.priceDecimalPrecision)} <span className={styles.conv}> {item?.currency?.toUpperCase()}  </span></div>)} */}

          {active && (
            <div className={styles.col}>
              {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) ?
                <>
                  {getDigitsAfterDecimal(item?.fiatFee, item?.priceDecimalPrecision, true)} <small> {item?.currency?.toUpperCase()}  </small>
                </>
                :
                (item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) ?
                  <>
                    {getDigitsAfterDecimal(item?.cryptoFee, item?.amountDecimalPrecision)} <small> {item?.coin?.toUpperCase()}  </small>
                  </>
                  :
                  null}
            </div>
          )}
          <div className={styles.col}>
            <div className={cn("category-green", styles.complete)}> Filled </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}><small>{toLocaleStringDateFormat(item.updatedAt)}</small></div>
          </div>
        </div>
        <div className={styles.btns}>
          <History
            orders={item.order_matching_history}
            coin={item?.coin}
            currency={item?.currency}
            decimalPrecison={item?.priceDecimalPrecision}
            amountPrecision={item?.amountDecimalPrecision}
            active={active}
            item={item}
          />
        </div>
      </div>
    </>
  );
};

export default Item;
