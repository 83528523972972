import * as cv from '@onfido/opencv'
import { CV } from '@onfido/opencv'

/* The first import (* as cv), will make the cv module attach to the root object. This is the only file where we should use `import * as cv from '@onfido/opencv'` to avoid confusion */

let _openCvGlobal: CV

export const waitForOpenCV = (): Promise<CV> => {
  if (_openCvGlobal) {
    return new Promise((resolve) => resolve(_openCvGlobal))
  }
  // cv here is a promise object. Read opencv.js source for more information
  // @ts-ignore
  return cv.then((data: any) => {
    _openCvGlobal = data
    return _openCvGlobal
  })
}

export const handleCvExceptions = (error: any): any => {
  if (typeof error === 'number' && _openCvGlobal) {
    return _openCvGlobal.exceptionFromPtr(error)
  }
  return error
}
