import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import SimpleReactValidator from "simple-react-validator";
import Icon from "../../../components/Icon";
import { addNotification } from "../../../components/Notification";
import { resendOtp } from "../../../components/helper";

const Code = ({
  userStatus,
  verifyOtpHandler,
  resendOtpType
}) => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  const [fourthField, setFourthField] = useState("");
  const [fifthField, setFifthField] = useState("");
  const [sixthField, setSixthField] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator())
  const [twoFA, setTwofa] = useState(false)
  const [verificationType, setVerificationType] = useState('otp')
  const [count, setCount] = useState(30)
  const [buttonVisible, setButtonVisible] = useState(false)

  useEffect(() => {
    handleVerificationType()
  }, [twoFA])

  const handleVerificationType = () => {
    if (twoFA) {
      setVerificationType('2fa')
    } else {
      setVerificationType('otp')
    }
  }

  const someFunction = () => {
    setCount(count - 1);
  };

  useEffect(() => {
    if (count >= 1) {
      setTimeout(someFunction, 1000);
    }
    else {
      setButtonVisible(true)
    };
  }, [count]);

  return (
    <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
      <h3 className={cn("h5", styles.title)}>Enter your security code.</h3>
      <div className={styles.subHeading}>
        Please enter the code below which you get on your email address.
      </div>
      <div className={styles.code}>
        <div className={styles.number}>
          <input
            type="tel"
            name="field"
            maxLength="1" ref={ref1}
            value={firstField}
            autoFocus
            onChange={(e) => {
              if (e.target.value) {
                setFirstField(e.target.value);
                ref2.current.focus();
              }
              else if (e.target.value === '') {
                setFirstField('');
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
        </div>
        <div className={styles.number}>
          <input type="tel"
            name="field"
            maxLength="1"
            ref={ref2}
            value={secondField}
            onChange={(e) => {
              if (e.target.value) {
                setSecondField(e.target.value);
                ref3.current.focus();
              }
              else if (e.target.value === '') {
                setSecondField('');
                ref1.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
        </div>
        <div className={styles.number}>
          <input type="tel"
            name="field"
            maxLength="1"
            ref={ref3}
            value={thirdField}
            onChange={(e) => {
              if (e.target.value) {
                setThirdField(e.target.value);
                ref4.current.focus();
              }
              else if (e.target.value === '') {
                setThirdField('');
                ref2.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
        </div>
        <div className={styles.number}>
          <input type="tel"
            name="field"
            maxLength="1"
            ref={ref4}
            value={fourthField}
            onChange={(e) => {
              if (e.target.value) {
                setFourthField(e.target.value);
                ref5.current.focus();
              }
              else if (e.target.value === '') {
                setFourthField('');
                ref3.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
        </div>
        <div className={styles.number}>
          <input type="tel"
            name="field"
            maxLength="1"
            ref={ref5}
            value={fifthField}
            onChange={(e) => {
              if (e.target.value) {
                setFifthField(e.target.value);
                ref6.current.focus();
              }
              else if (e.target.value === '') {
                setFifthField('');
                ref4.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
        </div>
        <div className={styles.number}>
          <input type="tel"
            name="field"
            maxLength="1"
            ref={ref6}
            value={sixthField}
            onChange={(e) => {
              if (e.target.value) {
                setSixthField(e.target.value);
              }
              else if (e.target.value === '') {
                setSixthField('');
                ref5.current.focus();
              }
            }}
            onBlur={() => { simpleValidator.current.showMessageFor("field") }}
          />
        </div>
      </div>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (firstField !== "" && secondField !== "" && thirdField !== "" && fourthField !== "" && fifthField !== "" && sixthField !== "") {
            verifyOtpHandler(firstField, secondField, thirdField, fourthField, fifthField, sixthField);
          }
          else {
            addNotification({
              title: 'Alert',
              message: "Please enter a valid otp.",
              type: 'danger'
            });
          };
        }}
      >
        <span>Continue</span>
        <Icon name="arrow-right" size="16" />
      </button>
      {buttonVisible ? <button className={styles.button} onClick={() => {
        resendOtp(userStatus?.email, resendOtpType);
        setCount(30);
        setButtonVisible(false);
      }}>
        Resend OTP
      </button>
        :
        <button className={styles.button}>
          Resend OTP in {count} seconds
        </button>
      }
    </form>
  );
};

export default Code;
