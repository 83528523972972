import {
  h,
  Fragment,
  FunctionComponent,
  VNode,
  ComponentChildren,
} from 'preact'
import classNames from 'classnames'
import theme from './style.scss'
import { forwardRef } from 'preact/compat'

type ScreenLayoutProps = {
  actions?: VNode
  className?: string
  pageId?: string
  children?: ComponentChildren
}

const ScreenLayout: FunctionComponent<ScreenLayoutProps> = forwardRef<
  HTMLDivElement,
  ScreenLayoutProps
>(({ actions, children, className, pageId }, ref) => {
  return (
    <Fragment>
      <div
        ref={ref}
        className={classNames(theme.scrollableContent, className)}
        data-page-id={pageId}
        tabIndex={0}
      >
        {children}
      </div>
      <div className={theme.actionsContainer}>{actions}</div>
    </Fragment>
  )
})

export default ScreenLayout
