import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h3>Various Jurisdictions</h3>
          <p>
            <strong>United States:</strong><br />
            - Bitdenex does not allow residents of the United States of America to trade on its platform. <br />
            - Bitdenex is not offered in the United States of America.
          </p>
          <p>
            <strong>China:</strong><br />
            - Bitdenex does not allow residents of China to trade on its platform. <br />
            - Bitdenex is not offered in China.
          </p>

          <figure>
            <img src="/images/content/location/article.jpg" alt="Content" />
          </figure>

          <h3>Restricted Jurisdictions</h3>
          <p>
            We does not onboard or provide services to corporate accounts of entities located in, established in, or a resident of the United States of America, Cuba, Crimea and Sevastopol, Iran, Afghanistan, Syria, or North Korea. We also does not onboard any users from Ontario, or non-professional investors from Hong Kong.
          </p>
          <p>
            We does not onboard or provide services to personal accounts of current residents of the United States of America, Cuba, Crimea and Sevastopol, Iran, Afghanistan, Syria or North Korea. There may be partial restrictions in other jurisdictions, potentially including Hong Kong, Thailand, Malaysia, and Canada. We does not onboard any users from Ontario, or non-professional investors from Hong Kong.
          </p>

          <h3>Trading</h3>
          <p>
            - Bitdenex offers a platform for users to match against each other. <br />
            - Bitdenex does not actively trade itself. <br />
            - Bitdenex does not use any other exchanges for trading. <br />
            - Bitdenex maintains backstop funds to prevent losses.
          </p>

          <h3>Deposits and Withdrawals</h3>
          <p>
          
          - Bitdenex strives to process all deposits and withdrawals promptly. <br />
          - Bitdenex maintains 24/7 withdrawal availability. <br />
          - Bitdenex has never had an outage of deposit or withdrawal services. <br />
          - Users who deposit to incorrect addresses may lose their funds, though in some cases Bitdenex may be able to recover them for a fee. <br />
          - Bitdenex encourages its customers to make use of the full suite of tools available to protect their accounts, and mandates 2-factor authentication on all active accounts.
          </p>

          <p>
            <strong>If you have any questions in this regard, please contact us at support@bitdenex.com</strong>
          </p>
        </div>

      </div>
    </div>
  );
};

export default Article;
