import React, { useState } from "react";
import cn from "classnames";
import styles from "./Merchant.module.sass";
import Item from "./Item";


const items = [
  {
    id: 1,
    slug: 'get-bitdenex-pay-for-your-business',
    title: "Get Bitdenex Pay for your business",
    currency: "Bitdenex Pay For Business",
    content: `Accept cryptocurrency payments and tap into our growing Pay users.`,
    category: "red",
    categoryText: "new",
    image: "/images/content/pay/pay-merchant.jpg",
    image2x: "/images/content/pay/pay-merchant@2x.jpg",
    url: "/bitdenex-partners",
  }
];

const Merchant = ({ scrollToRef }) => {
  const [activeIndex, setActiveIndex] = useState(0);
 
  return (
    <div className={cn("section", styles.releases)} ref={scrollToRef} >
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}></div>
          <div className={styles.wrap}>
            <h3 className={cn("h3", styles.title)}> Become a Merchant </h3>
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Merchant;
