import { Button } from '@onfido/castor-react'
import classNames from 'classnames'
import CustomFileInput from 'components/CustomFileInput'
import PageTitle from 'components/PageTitle'
import Spinner from 'components/Spinner'
import { Fragment, h } from 'preact'
import { StateUpdater, useState } from 'preact/hooks'
import theme from '../../Theme/style.scss'
import { BlurDetectionResult, blurValidation } from '../OpenCV/BlurDetection'
import { defaultParams } from '../OpenCV/DetectionParams'
import {
  DetectSegmentResult,
  EdgeDetectionResults,
  edgeValidation,
} from '../OpenCV/EdgeDetection'
import { handleCvExceptions, waitForOpenCV } from '../OpenCV/waitForOpenCv'
import style from './CameraPanel.scss'
import devToolsCss from './DevTools.scss'

const getImageDataFromFile = (
  file: File,
  imgDataUpdater: StateUpdater<ImageData | undefined>
) => {
  const canvas = document.createElement('canvas')
  const img = new Image()
  img.onload = function () {
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    ctx && ctx.drawImage(img, 0, 0)

    const imgData = ctx?.getImageData(0, 0, img.width, img.height)
    imgDataUpdater(imgData)
    canvas.remove()
    img.remove()
  }
  img.src = URL.createObjectURL(file)
}

// I got this from my personal phone. If you wonder how to create new tests.
const rectangle = {
  bottom: 1381,
  left: 72,
  right: 1368,
  top: 539,
}

export const DocumentAutoTestPage = () => {
  const [iteration, setIteration] = useState<number>(0)
  const [filename, setFilename] = useState<string | undefined>()
  const [imgData, setImgData] = useState<ImageData | undefined>()
  const [edgeResult, setEdgeResult] = useState<
    DetectSegmentResult | undefined
  >()
  const [blurResult, setBlurResult] = useState<
    BlurDetectionResult | undefined
  >()
  const [error, setError] = useState<any>()

  const clearState = () => {
    setError(undefined)
    setEdgeResult(undefined)
    setBlurResult(undefined)
  }

  const doBlurDetection = async (iterationCount: number) => {
    if (!imgData) {
      return
    }
    let result: BlurDetectionResult | undefined
    for (let i = 0; i <= iterationCount; i++) {
      const cv = await waitForOpenCV()
      try {
        result = blurValidation(
          cv,
          { ...defaultParams, debug: true },
          rectangle,
          imgData
        )
        setIteration(i)
      } catch (err) {
        const cvError = handleCvExceptions(err)
        console.error(cvError)
        setError(cvError)
        return
      }
    }
    setBlurResult(result)
  }

  const doEdgeDetection = async (iterationCount: number) => {
    if (!imgData) {
      return
    }
    let result: EdgeDetectionResults | undefined
    for (let i = 0; i <= iterationCount; i++) {
      const cv = await waitForOpenCV()
      try {
        result = edgeValidation(
          cv,
          { ...defaultParams, debug: true },
          rectangle,
          imgData
        )
        setIteration(i)
      } catch (err) {
        const cvError = handleCvExceptions(err)
        console.error(cvError)
        setError(cvError)
        return
      }
    }
    setEdgeResult(result?.segments)
  }
  const Canvas = (
    <Fragment>
      <canvas id="canvasSegments" style={style.debugCanvas} />
      <canvas id="canvasCanny" style={{ display: 'none' }} />
      <canvas id="canvasHough" style={{ display: 'none' }} />
      <canvas id="canvasCapture" style={{ display: 'none' }} />
    </Fragment>
  )
  return (
    <Fragment>
      <div data-page-id="DocumentAutoCaptureQA">
        <PageTitle title={'Document Test Page - QA only'} />
        {!imgData && (
          <CustomFileInput
            onChange={(file) => {
              setFilename(file.name)
              getImageDataFromFile(file, setImgData)
            }}
          >
            <Button
              type="button"
              variant={'primary'}
              className={classNames(
                theme['button-centered'],
                theme['button-lg']
              )}
            >
              {'Upload picture'}
            </Button>
          </CustomFileInput>
        )}
        {imgData && (
          <Fragment>
            <div className={devToolsCss.buttonRow}>
              <Button
                id="button-edge"
                type="button"
                variant={'primary'}
                onClick={async () => {
                  clearState()
                  await doEdgeDetection(1)
                }}
              >
                {'Edge'}
              </Button>
              <Button
                id="button-edge1000"
                type="button"
                variant={'primary'}
                onClick={async () => {
                  clearState()
                  setTimeout(async () => {
                    await doEdgeDetection(1000)
                  }, 0)
                }}
              >
                {'Edge x1000'}
              </Button>
              <Button
                id="button-blur"
                type="button"
                variant={'primary'}
                onClick={async () => {
                  clearState()
                  await doBlurDetection(1)
                }}
              >
                {'Blur'}
              </Button>
              <Button
                id="button-blur1000"
                type="button"
                variant={'primary'}
                onClick={async () => {
                  clearState()
                  setTimeout(async () => {
                    await doBlurDetection(1000)
                  }, 0)
                }}
              >
                {'Blur x1000'}
              </Button>
            </div>
            {Canvas}
            {filename && <div>{filename}</div>}
            {iteration && (
              <div>
                <span>Iteration: </span>
                {iteration}
              </div>
            )}
            {!error && edgeResult && (
              <div id="result" className={devToolsCss.resultRow}>
                {JSON.stringify({
                  top: edgeResult.top,
                  bottom: edgeResult.bottom,
                  left: edgeResult.left,
                  right: edgeResult.right,
                })}
              </div>
            )}
            {!error && blurResult && (
              <div id="result" className={devToolsCss.resultRow}>
                {JSON.stringify({
                  variance: blurResult.variance,
                  detected: blurResult.detected,
                })}
              </div>
            )}
            {error && (
              <div id="error">
                Error: <strong>{JSON.stringify(error)}</strong>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}
