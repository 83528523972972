import React from "react";
import { QRCode } from 'react-qrcode-logo';

const QrCode = ({ value, logoPath, width, height }) => {
    return (

        <QRCode value={value}
            logoImage={logoPath}
            logoWidth={width}
            logoHeight={height}
        />

    );
};

export default QrCode;
