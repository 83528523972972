// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%22740%22 height%3D%221800%22%3E%0D  %3Cpath fill-opacity%3D%22.80%22 d%3D%22M0 1800V0h740v1800H0zm237-914.056c0 71.601 59.104 175.672 132.021 175.672s132.022-101.453 132.022-175.672c0-83.917-59.107-151.944-132.022-151.944C296.108 734 237 802.027 237 885.944z%22%2F%3E%0D  %3Cpath fill%3D%22none%22 stroke%3D%22%23FFF%22 stroke-width%3D%223%22 d%3D%22M237 885.944c0 71.601 59.104 175.672 132.021 175.672s132.022-101.453 132.022-175.672c0-83.917-59.107-151.944-132.022-151.944C296.108 734 237 802.027 237 885.944z%22%2F%3E%0D%3C%2Fsvg%3E%0D", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%22740%22 height%3D%221800%22%3E%0D  %3Cpath fill-opacity%3D%22.66%22 d%3D%22M0 1800V0h740v1800H0z%22%2F%3E%0D  %3Cpath opacity%3D%22.2%22 fill%3D%22none%22 stroke%3D%22%23FFF%22 stroke-width%3D%223%22 d%3D%22M237 885.944c0 71.601 59.104 175.672 132.021 175.672s132.022-101.453 132.022-175.672c0-83.917-59.107-151.944-132.022-151.944C296.108 734 237 802.027 237 885.944z%22%2F%3E%0D%3C%2Fsvg%3E%0D", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-Overlay-face{background-size:46.25em 112.5em;background-position:50% calc(-39.4375em);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;height:100%;pointer-events:none;position:absolute;top:0;left:0;width:100%}@media(max-width: 359px){.onfido-sdk-ui-Overlay-face{background-size:39.3125em 95.625em;background-position:50% calc(-32.5375em)}}.onfido-sdk-ui-Overlay-isWithoutHole .onfido-sdk-ui-Overlay-face{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.onfido-sdk-ui-Overlay-ariaLabel{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"face": "onfido-sdk-ui-Overlay-face",
	"isWithoutHole": "onfido-sdk-ui-Overlay-isWithoutHole",
	"ariaLabel": "onfido-sdk-ui-Overlay-ariaLabel"
};
export default ___CSS_LOADER_EXPORT___;
