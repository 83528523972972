import { Fragment, h } from 'preact'
import { useContainerDimensions } from '~contexts'
import { StateValue } from 'xstate'
import type { EdgeDetectionResults } from '../OpenCV/EdgeDetection'

export type InfoPanelProps = {
  video: HTMLVideoElement | null | undefined
  frame?: DOMRect
  stateValue: StateValue
  edgeDetection?: EdgeDetectionResults
  stream: MediaStream | null | undefined
}
export const InfoPanel = ({
  video,
  frame,
  stateValue,
  edgeDetection,
  stream,
}: InfoPanelProps) => {
  const container = useContainerDimensions()
  if (!stream || !container) {
    return null
  }

  const track = stream.getVideoTracks()[0]
  const settings = track.getSettings()
  const capabilities = track.getCapabilities()

  return (
    <Fragment>
      <h4>Detection:</h4>
      <div>State: {stateValue}</div>
      <h4>Video</h4>
      <div>Container: {`w:${container.width} * h:${container.height}`}</div>
      <div>Frame: {`w:${frame?.width} * h:${frame?.height}`}</div>
      <div>
        Resolution: {`w: ${video?.videoWidth} * h: ${video?.videoHeight}`}
      </div>

      <h4>Stream track settings:</h4>
      <div>Tracks: {stream?.getTracks().length}</div>
      <div>Enabled: {track.enabled ? 'yes' : 'no'}</div>
      <div>Label: {track.label}</div>
      {Object.keys(settings).map((k) => {
        // @ts-ignore
        return <div key={k}>{`${k}: ${settings[k]}`}</div>
      })}

      <h4>Stream track capabilities:</h4>
      {Object.keys(capabilities).map((k) => {
        // @ts-ignore
        return <div key={k}>{`${k}: ${JSON.stringify(capabilities[k])}`}</div>
      })}
    </Fragment>
  )
}
