import React, { useState } from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import TextInput from "../TextInput";
import CustomDropdown from "../CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCoinsCreator } from "../../actions/getCoins";
import { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../Notification";
import requestHandler from "../../actions/httpClient";
import Code from "./Code";
import { userStatusCreator } from "../../actions/getUserStatus";
import LoaderScreen from "../LoaderScreen";
import { getDigitsAfterDecimal } from "../helper";

const Transfer = ({ setVisibleModal, pageRefreshFlag, setPageRefreshFlag }) => {
  const dispatch = useDispatch();
  const [coinData, setCoinData] = useState([]);
  const [coinText, setCoinText] = useState("Please select a value");
  const [coinValue, setCoinValue] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [userId, setUserId] = useState("");
  const [amount, setAmount] = useState("");
  const [activeIndex, seActiveIndex] = useState(0);
  const [resendOtpType, setResendOtpType] = useState("");
  const [loading, setLoading] = useState(false);
  const [userBal, setUserBal] = useState("");

  useEffect(() => {
    getUsersCoinHandler();
  }, []);

  const getUsersCoinHandler = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
      };
      const userStatus = await requestHandler('getCustomerVerifictionStatus', 'post', data, 'jwt_token')
      setLoading(false);
      const newArray = userStatus?.data?.data?.wallet?.balances?.map((x) => {
        return { "cId": x?.coinId, "cValue": `${x.name.toUpperCase()}(${x.symbol.toUpperCase()})`, "cGatewayId": x.balance }
      });
      setCoinData([...newArray]);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const sendCryptoHandler = async () => {
    setLoading(true);
    let data = {
      amount: amount,
      uid: userId,
      coinId: coinValue,
      signature: localStorage.getItem("signature"),
    };
    try {
      const sendCryptoPayload = await requestHandler("transferCrypto", "post", data, "jwt_token");
      setLoading(false);
      if (sendCryptoPayload && sendCryptoPayload.status === 201) {
        seActiveIndex(1);
        setResendOtpType(sendCryptoPayload?.data?.message?.type);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message:
          e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  const codeScreenHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField, type) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    setLoading(true);
    try {
      let data = {
        code: code,
        verification_type: type,
        amount: amount,
        uid: userId,
        coinId: coinValue,
        signature: localStorage.getItem("signature"),
      };
      const otpVerificationPayload = await requestHandler("transferCryptoVerify", "post", data, "jwt_token");
      setLoading(false);
      if (otpVerificationPayload && otpVerificationPayload.status === 200) {
        addNotification({
          title: "Success",
          message:
            otpVerificationPayload?.data?.message[0]?.msg,
          type: "success",
        });
      }
      setVisibleModal(false);
      setPageRefreshFlag(!pageRefreshFlag);
      dispatch(userStatusCreator());
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message:
          e?.data?.message[0]?.msg,
        type: "danger",
      });
    }
  };

  return (
    <>
      {loading && <LoaderScreen />}
      {activeIndex === 0 &&
        (
          <div className={styles.transfer}>
            <div className={cn("h4", styles.title)}>
              Transfer Crypto
            </div>
            <div className={styles.wrap}>
              <div className={styles.category}>
                Available <br></br>balance
              </div>
              <div className={styles.details}>
                <div className={styles.currency}>{userBal ? getDigitsAfterDecimal(userBal, 8) : "0.00"}</div>
                <div className={styles.price}>{coinText === "Please select a value" ? "---" : coinText.split("(")[0].toUpperCase()}</div>
              </div>
            </div>
            <div className={styles.field}>
              <CustomDropdown
                className={styles.dropdown}
                label="Select Crypto"
                value={coinValue}
                setValue={setCoinValue}
                text={coinText}
                setText={setCoinText}
                options={coinData}
                flag={true}
                setCGatewayId={setUserBal}
              />
            </div>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                label="User Id"
                name="userId"
                type="text"
                placeholder="Please enter User Id"
                required
                value={userId}
                onChange={(e) => {
                  const re = /^\d+$/;
                  if (e.target.value == "" || re.test(e.target.value)) {
                    setUserId(e.target.value)
                  }
                }
                }
                onBlur={() => {
                  simpleValidator.current.showMessageFor("userId");
                }}
              />
            </div>
            <span className={styles.danger}>
              {simpleValidator.current.message("userId", userId, "required")}
            </span>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                label="Amount to transfer"
                name="amount"
                type="text"
                value={amount}
                placeholder="Please enter amount"
                required
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value == "" || re.test(e.target.value)) {
                    setAmount(e.target.value)
                  }
                }
                }
                onBlur={() => {
                  simpleValidator.current.showMessageFor("amount");
                }}
              />
            </div>
            <span className={styles.danger}>
              {simpleValidator.current.message("amount", amount, "required")}
            </span>
            <button className={cn("button", styles.button)} onClick={(e) => {
              e.preventDefault();
              if (simpleValidator.current.allValid()) {
                if (coinText === "Please select a value") {
                  addNotification({
                    title: 'Alert',
                    message: "Please select coin.",
                    type: 'danger'
                  });
                }
                else if (parseFloat(amount) <= 0) {
                  addNotification({
                    title: 'Alert',
                    message: "You can't transfer 0 amount.",
                    type: 'danger'
                  });
                }
                else if (parseFloat(amount) > parseFloat(userBal)) {
                  addNotification({
                    title: 'Alert',
                    message: "You don't have that much of amount available in your wallet.",
                    type: 'danger'
                  });
                }
                else {
                  sendCryptoHandler();
                }
              }
              else {
                simpleValidator.current.showMessages();
                setForceUpdate(1);
              }
            }}>Transfer</button>
          </div>)}

      {activeIndex === 1 && <Code
        codeScreenHandler={codeScreenHandler}
        resendOtpType={resendOtpType} />}
    </>

  );
};

export default Transfer;
