import React, { useEffect, useState } from "react";
import Main from "./Main";
import Article from "./Article";
import Catalog from "./Catalog";
import { articles } from "../../mocks/articles";
import { useParams } from "react-router-dom";

const LearnCrypto = () => {

  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    let result = articles.find((result) => result.slug === slug);
    if (result)
      setBlog(result);

  }, []);

  return (
    <>
      <Main blog={blog} />
      <Article blog={blog} />
      <Catalog articles={articles} />
    </>
  );
};

export default LearnCrypto;
