import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";

const Successfully = () => {
  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Success!
        </div>
      </div>
    </>
  );
};

export default Successfully;
