
import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "./BankSkeleton.module.sass";

const BankSkeleton = ({ count, height }) => {
    var themeMode = localStorage.getItem('darkMode');
    return (
        Array(count)
            .fill()
            .map((item, index) => (
                <div className={styles.row} key={index} >
                    <div className={styles.col} >
                        <Skeleton
                            height={height}
                            baseColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_BASE_DARK : process.env.REACT_APP_SKELETON_BASE_LIGHT}
                            highlightColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_HIGHLIGHT_DARK : process.env.REACT_APP_SKELETON_HIGHLIGHT_LIGHT}
                        />
                    </div>
                </div>
            ))
    );
};

export default BankSkeleton;

