import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { Link } from "react-router-dom";

const Successfully = ({ value }) => {
  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h2", styles.title)}>
          Success!
        </div>
        {/* <div className={styles.info}>
          You successfully sold <br></br>1.356 <span>BTC</span> for Bitcloud
        </div> */}
        <div className={styles.info}>
          Order successfull
        </div>
        {/* <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
              </div>
            </div>
          ))}
        </div> */}
        <div className={styles.btns}>
          <Link className={cn("button-stroke", styles.button)} to={`/exchange/${value.slug}`}>
            Trade
          </Link>
          <Link className={cn("button", styles.button)} to="/wallet-overview">
            Wallet
          </Link>
        </div>
      </div>
    </>
  );
};

export default Successfully;
