import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Finance.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";


const items = [
  {
    title: "Bitdenex Pay",
    icon: "laptop",
    content: "Instant send and receive crypto",
    url: "/bitdenex-pay",
    new: true
  },
  {
    title: "Bitdenex Partners",
    icon: "laptop",
    content: "Meet our partners",
    url: "/bitdenex-partners",
    new: true
  }
];

const Finance = ({ className }) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.dropdown, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          Finance
          <Icon name="arrow-down" size="16" />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) =>
              x.url ? (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.title}>{x.title} {x.new === true && (<small className={styles.soon}>(New)</small>)} </div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </Link>
              ) : (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{x.title}</div>
                      <Theme className={styles.theme} small />
                    </div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Finance;
