import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";

const Successfully = ({
  redeemingItem,
  activeTab
}) => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h4", styles.title)}>
          Stake Details
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsGrid}>
            <div className={styles.detail}>
              <span className={styles.label}>Contract Id:</span>
              <span className={styles.value}>{redeemingItem?.contract_id}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Coin Name:</span>
              <span className={styles.value}>{redeemingItem?.coinName?.toUpperCase()}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Coin Symbol:</span>
              <span className={styles.value}>{redeemingItem?.coinSymbol?.toUpperCase()}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Staked Amount:</span>
              <span className={styles.value}>{getDigitsAfterDecimal(redeemingItem?.stakeAmount, redeemingItem?.amountDecimalPrecision)}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Early Redeem Penalty:</span>
              <span className={styles.value}>{redeemingItem?.penality}%</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Annual Percentage Yield:</span>
              <span className={styles.value}>{redeemingItem?.annualPercentage}%</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Total Days:</span>
              <span className={styles.value}>{redeemingItem?.dayDifference}/{redeemingItem?.totalDays}</span>
            </div>
            {/* <div className={styles.detail}>
              <span className={styles.label}>Yesterday's earnings:</span>
              <span className={styles.value}>{getDigitsAfterDecimal(redeemingItem?.yesterdayProfit, redeemingItem?.amountDecimalPrecision)} <small>{redeemingItem?.coinSymbol?.toUpperCase()}</small></span>
            </div> */}
            {activeTab?.toLowerCase() === "redeemed" ?
              <div className={styles.detail}>
                <span className={styles.label}>Total earnings:</span>
                <span className={styles.value}>{getDigitsAfterDecimal(redeemingItem?.recieveStakeProfit, redeemingItem?.amountDecimalPrecision)} <small>{redeemingItem?.coinSymbol?.toUpperCase()}</small></span>
              </div>
              :
              <div className={styles.detail}>
                <span className={styles.label}>Total earnings:</span>
                <span className={styles.value}>{getDigitsAfterDecimal(redeemingItem?.totalStakeProfit, redeemingItem?.amountDecimalPrecision)} <small>{redeemingItem?.coinSymbol?.toUpperCase()}</small></span>
              </div>
            }
            <div className={styles.detail}>
              <span className={styles.label}>Date:</span>
              <span className={styles.value}>{toLocaleStringDateFormat(redeemingItem?.createdAt)} </span>
            </div>
            {activeTab?.toLowerCase() === "redeemed" &&
              <div className={styles.detail}>
                <span className={styles.label}>Redeem date:</span>
                <span className={styles.value}>{toLocaleStringDateFormat(redeemingItem?.updatedAt)} </span>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Successfully;
