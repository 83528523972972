import { useEffect, useState } from 'preact/hooks'
import { Dispatch, SetStateAction } from 'react'
import { Buffer } from 'buffer'

const useUrlSearchParam = <T>(
  paramKey: string,
  defaultParam: T
): [T, Dispatch<SetStateAction<T>>] => {
  const urlParams = new URLSearchParams(window.location.search)

  let parsedOrDefaultParams: T = defaultParam
  const param = urlParams.get(paramKey)
  if (param) {
    parsedOrDefaultParams = JSON.parse(base64Decode(param)) as T
  }

  const [value, setValue] = useState(parsedOrDefaultParams)

  useEffect(() => {
    const toBase64 = base64Encode(JSON.stringify(value))
    urlParams.delete(paramKey)
    urlParams.set(paramKey, toBase64)

    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${urlParams.toString()}`

    if (window.location.toString() !== newurl) {
      window.history.pushState({ path: newurl }, '', newurl)
    }
  }, [urlParams, value, paramKey])

  return [value, setValue]
}

const getBooleanSearchParam = (paramKey: string): boolean => {
  const urlParams = new URLSearchParams(window.location.search)
  const param = urlParams.get(paramKey)
  return param === 'true'
}

const base64Decode = (s: string) => {
  return Buffer.from(s, 'base64').toString('binary')
}

const base64Encode = (s: string) => {
  return Buffer.from(s, 'binary').toString('base64')
}

export { useUrlSearchParam, getBooleanSearchParam }
