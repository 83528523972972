export class Point {
  public y: number
  public x: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }
}

export type Rectangle = {
  left: number
  top: number
  right: number
  bottom: number
}

export class Rect {
  private readonly p1: Point
  private readonly p2: Point

  private constructor(p1: Point, p2: Point) {
    this.p1 = p1
    this.p2 = p2
  }

  static fromCoordinates(
    left: number,
    top: number,
    right: number,
    bottom: number
  ): Rect {
    const { min, max } = Math
    return new Rect(
      new Point(min(left, right), min(top, bottom)),
      new Point(max(left, right), max(top, bottom))
    )
  }

  public scale(scaleFactor: number): Rect {
    return Rect.fromCoordinates(
      this.left * scaleFactor,
      this.top * scaleFactor,
      this.right * scaleFactor,
      this.bottom * scaleFactor
    )
  }

  public addMargin(margin: number): Rect {
    return Rect.fromCoordinates(
      this.left + margin,
      this.top + margin,
      this.right - margin,
      this.bottom - margin
    )
  }

  public get width(): number {
    return Math.abs(this.p1.x - this.p2.x)
  }

  public contains(x: number, y: number) {
    return (
      x >= this.left && x <= this.right && y >= this.top && y <= this.bottom
    )
  }

  public containsPoint(p: Point) {
    return this.contains(p.x, p.y)
  }

  public get height(): number {
    return Math.abs(this.p1.y - this.p2.y)
  }

  public get x(): number {
    return this.p1.x
  }

  public get y(): number {
    return this.p1.y
  }

  public get left(): number {
    return this.x
  }

  public get top(): number {
    return this.y
  }

  public get right(): number {
    return this.p2.x
  }

  public get bottom(): number {
    return this.p2.y
  }
}
