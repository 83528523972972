import cn from "classnames";
import styles from "./Main.module.sass";
import { addNotification } from "../../../components/Notification";
import Icon from "../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userStatusCreator } from "../../../actions/getUserStatus";

const Main = ({ levelData }) => {
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => { return state.getUserStatus });

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userStatusCreator());
    }
  }, []);

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h2", styles.title)}>
            Refer Your Friends & Earn Crypto Together.
          </h1>
          <div className={cn("stage", styles.stage)}>
            <h5 className={styles.title}>
              You're just steps away from receiving your crypto commission rewards.
            </h5>
            Share your referral link with your friends, invite friends to sign up and earn up to {levelData[levelData?.length - 1]?.percentage}%
            commission on every Bitdenex Spot trade.
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/referral/referral-main@2x.png 2x"
            src="/images/content/referral/referral-main.png"
            alt="Cards"
          />
        </div>
      </div>
      <div className={styles.referralProgram}>
        <div className={styles.referral}>
          <div className={styles.leftText}>Referral Code</div>
          {userStatus?.refferal_code === undefined || userStatus?.refferal_code === null ? <div className={styles.rightText}>---</div> :
            <div className={styles.rightText}>{userStatus?.refferal_code}
              <button className={styles.copy} onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(userStatus?.refferal_code).then(() => {
                  addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                })
              }}>
                <div className={styles.btnClass}>
                  <Icon name="copy" size="16" />
                </div>
              </button>
            </div>
          }
        </div>
        <div className={styles.referral}>
          <div className={styles.leftText}>Referral Link</div>
          {userStatus?.refferal_code === undefined || userStatus?.refferal_code === null ? <div className={styles.rightText}>---</div> :
            <div className={styles.rightText}>{`https://bitdenex.com/sign-up?ref=${userStatus?.refferal_code}`}
              <button className={styles.copy} onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(`https://bitdenex.com/sign-up?ref=${userStatus?.refferal_code}`).then(() => {
                  addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                })
              }}>
                <div className={styles.btnClass}>
                  <Icon name="copy" size="16" />
                </div>
              </button>
            </div>
          }
        </div>
      </div>
    </div >
  );
};

export default Main;
