import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./Releases.module.sass";
import Modal from "../../../components/Modal";
import Successfully from "./StakingModal";
import { getDigitsAfterDecimal } from "../../../components/helper";
import FundTransfer from "../../../components/FundTransfer";

const Releases = ({
  scrollToRef,
  visibleStakeModal,
  setVisibleStakeModal,
  setStakeAmount,
  stakeAmount,
  acknowledge,
  setAcknowledge,
  setAgreement,
  agreement,
  addStakeAmountHandler,
  availableAmount,
  stakeDetails,
  stakeData,
  visibleFundTransfer,
  setVisibleFundTransfer,
}) => {

  const { tier, nextTierAmount, apy } = useMemo(() => {
    const findTier = (totalAmount, stakeData) => {
      let tier = null;
      let nextTierAmount = null;
      let apy = null;

      for (let stake = 0; stake < stakeData.length; stake++) {
        if (parseFloat(totalAmount) < parseFloat(stakeData[stake].stakeAmount)) {
          tier = stakeData[stake]?.tier;
          nextTierAmount = parseFloat(stakeData[stake]?.stakeAmount) - parseFloat(totalAmount);
          apy = stakeData[stake]?.annualPercentage;
          break;
        }
        else {
          tier = stakeData[stakeData?.length - 1]?.tier;
          nextTierAmount = 0;
          apy = stakeData[stakeData?.length - 1]?.annualPercentage;
        }
      }
      return { tier, nextTierAmount, apy };
    };
    return findTier(parseFloat(stakeDetails?.totalStakedAmountToUse), stakeData);
  }, [stakeDetails?.totalStakedAmountToUse]);

  return (
    <>
      <div
        className={cn("section-bg section-mb0", styles.releases)}
        ref={scrollToRef}
      >
        <div className={cn("container", styles.container)}>
          <div className={styles.dashboardContainer}>
            <div className={styles.stakingInfo}>
              <h2 className={styles.stakeTitle}>My staked BDNX</h2>
              <div className={styles.amount}>
                <img src="/images/content/stakingIcon.png" alt="Coin Image" className={styles.coinImage} />
                <h3 className={styles.profitAmount}>{Object.keys(stakeDetails).length === 0 ? "0.00" : getDigitsAfterDecimal((stakeDetails?.totalStakedAmountToUse * stakeDetails?.marketPrice), stakeDetails?.priceDecimalPrecision)} {stakeDetails?.symbol}</h3>
              </div>
              <div className={styles.stakedAmount}>
                <span className={styles.key}>Total Staked Amount</span>
                <span className={styles.value}>{Object.keys(stakeDetails).length === 0 ? "0.00" : parseFloat(stakeDetails?.totalStakedAmountToUse).toLocaleString()} BDNX</span>
              </div>
              <div className={styles.availableAmount}>
                <span className={styles.key}>Available balance</span>
                <span className={styles.value}>{Object.keys(stakeDetails).length === 0 ? "0.00" : parseFloat(availableAmount).toLocaleString()} BDNX</span>
              </div>
              {/* <div className={styles.redeem}>
                <span className={styles.key}>Redeeming</span>
                <span className={styles.value}>{totalStakedAmountToUse} BDNX</span>
              </div> */}
              <div className={styles.actions}>
                <button className={cn("button", styles.button)}
                  onClick={() => {
                    setVisibleStakeModal(true);
                  }}
                >Stake</button>
                {/* <button className={styles.redeemBtn} disabled>Redeem</button> */}
              </div>
              {/* <p className={styles.stakeInfo}>*Express redemption is supported and incurs a transaction fee of <span>{apy}%</span> of the principal. The redeemed amount will be credited within 10 minutes.</p> */}
              <p className={styles.customWrap}>*The redeemed amount will be credited within 10 minutes.</p>
            </div>
            <div className={styles.profitInfo}>
              <div className={styles.profitHeader}>
                <span className={styles.myProfitText}>My Total Earnings</span>
                <div className={styles.account}>
                  {/* <span className={styles.goToAccount}>Go to account</span>
                  <span className={styles.goToHistory}>Go to History</span> */}
                  <div className={styles.goToAccount}>
                    <button className={cn("button-small", styles.button)}
                      onClick={() => {
                        setVisibleFundTransfer(true);
                      }}
                    >Transfer</button>
                    {/* <button className={styles.redeemBtn} disabled>Redeem</button> */}
                  </div>
                </div>
              </div>
              <div className={styles.earnings}>
                <div className={styles.totalEarning}>
                  <span className={styles.totalEarningFirstSpan}>Total earnings</span>
                  <span className={styles.totalEarningSecondSpan}>{Object.keys(stakeDetails).length === 0 ? "0.00" : getDigitsAfterDecimal(stakeDetails?.totalEarning, stakeDetails?.priceDecimalPrecision)} BDNX</span>
                  <span className={styles.totalEarningThirdSpan}>≈ {Object.keys(stakeDetails).length === 0 ? "0.00" : getDigitsAfterDecimal((stakeDetails?.totalEarning * stakeDetails?.marketPrice), stakeDetails?.priceDecimalPrecision)} {stakeDetails?.symbol}</span>
                </div>
                {/* <div className={styles.totalEarning}>
                  <span className={styles.totalEarningFirstSpan}>Yesterday's earnings</span>
                  <span className={styles.totalEarningSecondSpan}>{Object.keys(stakeDetails).length === 0 ? "0.00" : getDigitsAfterDecimal(stakeDetails?.yesterdayEarning, stakeDetails?.priceDecimalPrecision)} BDNX</span>
                  <span className={styles.totalEarningThirdSpan}>≈ {Object.keys(stakeDetails).length === 0 ? "0.00" : getDigitsAfterDecimal((stakeDetails?.yesterdayEarning * stakeDetails?.marketPrice), stakeDetails?.priceDecimalPrecision)} {stakeDetails?.symbol}</span>
                </div> */}
              </div>
              {/* <div className={styles.currentTier}>
                Current Tier {tier}
              </div>
              {
                nextTierAmount === 0 ?
                  <p className={styles.stakeInfo}>You have reached max limit.</p>
                  :
                  <p className={styles.stakeInfo}>You still need <span>{Object.keys(stakeDetails).length === 0 ? "0.00" : parseFloat(nextTierAmount).toLocaleString()}</span> BDNX to upgrade to the next tier.</p>
              } */}
              {/* <div className={styles.perks}>
                {
                  <p className={styles.apy}>APY<span>{Object.keys(stakeDetails).length === 0 ? "0" : apy}%</span></p>
                }
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={visibleStakeModal}
        onClose={() => {
          setVisibleStakeModal(false);
          setAcknowledge(false);
          setAgreement(false);
          setStakeAmount("");
        }}
      >
        <Successfully
          setStakeAmount={setStakeAmount}
          stakeAmount={stakeAmount}
          acknowledge={acknowledge}
          setAcknowledge={setAcknowledge}
          setAgreement={setAgreement}
          agreement={agreement}
          addStakeAmountHandler={addStakeAmountHandler}
          availableAmount={availableAmount}
        />
      </Modal >

      <Modal
        onClose={() => { setVisibleFundTransfer(false); }}
        visible={visibleFundTransfer}
      >
        <FundTransfer
          setVisibleFundTransfer={setVisibleFundTransfer} />
      </Modal>
    </>
  );
};

export default Releases;
