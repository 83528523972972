import React, { useEffect, useState } from "react";
import styles from "./OrdersHistory.module.sass";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import Main from "./Main";
import Order from "../../components/Order";
import requestHandler from "../../actions/httpClient";

const OrdersHistory = () => {
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [completeOrders, setCompleteOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);

  const handleClick = () => {
    setActive(!active);
  };

  useEffect(() => {
    if (active) {
      getCompleteOrderData(pageno);
    }
    else {
      getCompletedMarketOrdersData(pageno);
    }
  }, [pageno, active]);

  useEffect(() => {
    getCompleteOrderData(pageno)
  }, [pageno]);

  const handleLoadMore = () => {
    setPageno(pageno + 1)
  }

  const getCompleteOrderData = async (page) => {
    try {
      setLoading(true)
      const data = {
        signature: localStorage.getItem("signature"),
        page: page
      }
      const payload = await requestHandler('getAllCompleteOrders', 'post', data, 'jwt_token')
      setCompleteOrder(payload?.data?.data)
      setLoading(false);
    } catch (error) {
      setLoading(false)
    }
  }

  const getCompletedMarketOrdersData = async (page) => {
    try {
      setLoading(true)
      const data = {
        signature: localStorage.getItem("signature"),
        page: page
      }
      const payload = await requestHandler('getAllCompleteMarketOrders', 'post', data, 'jwt_token')
      // setCompleteOrder(payload)
      createMarketOrders(payload.data.data)
      setLoading(false);
    } catch (error) {
      setLoading(false)
    }
  };

  const createMarketOrders = (orders) => {
    let createOrders = [];
    let tempData = [];
    for (let order of orders) {
      if (tempData.indexOf(parseFloat(order.orderId)) < 0) {
        createOrders.push(order)
        tempData.push(parseFloat(order.orderId));
      } else {
        let index = tempData.indexOf(parseFloat(order.orderId));
        createOrders[index].crypto_amount = parseFloat(order.crypto_amount) + parseFloat(createOrders[index].crypto_amount);
        createOrders[index].fiat_amount = parseFloat(order.fiat_amount) + parseFloat(createOrders[index].fiat_amount);
      }
    }
    setCompleteOrder(createOrders)
  }

  return (
    <>
      <Order userAuth={'userAuth'}>
        <Main
          active={active}
          handleClick={handleClick}
        />
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.body}>
              <Funds
                completeOrders={completeOrders}
                handleLoadMore={handleLoadMore}
                pageno={pageno}
                loading={loading}
                active={active}
              />
            </div>
          </div>
        </div>
      </Order>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw />
      </Modal>
    </>
  );
};

export default OrdersHistory;
