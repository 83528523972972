import { h } from 'preact'
import {
  sendMultiframeSelfie,
  UploadDocumentPayload,
  uploadFacePhoto,
  uploadFaceVideo,
  UploadVideoPayload,
} from '~utils/onfidoApi'
import { CapturePayload, FaceCapture } from '~types/redux'

import {
  CallbackNames,
  CALLBACK_TYPES,
  ConfirmProps,
  OnApiErrorCallback,
  OnApiSuccessCallback,
  OnSubmitCallback,
  useConfirm,
} from './useConfirm'
import Spinner from 'components/Spinner'
import Previews from './Previews'
import { useEffect } from 'preact/hooks'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'

export type SelfieConfirmProps = Omit<
  ConfirmProps,
  'upload' | 'submitUpload' | 'method'
>

export const SelfieConfirm = (props: SelfieConfirmProps) => {
  useEffect(() => {
    // 'FACE_SELFIE_CONFIRMATION'
    props.trackScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    face_selfie_capture,
    face_video_capture,
  } = useSdkConfigurationService()

  const handleSelfieUpload = (
    { snapshot, ...selfie }: FaceCapture,
    url: string,
    token: string,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback
  ) => {
    const shouldSign = !!face_selfie_capture?.sign_upload
    // if snapshot is present, it needs to be uploaded together with the user initiated selfie
    if (snapshot) {
      sendMultiframeSelfie(
        snapshot,
        selfie,
        token,
        shouldSign,
        url,
        onApiSuccess,
        onApiError
      )
    } else {
      const { blob, filename, sdkMetadata } = selfie
      // filename is only present for images taken via webcam.
      // Captures that have been taken via the Uploader component do not have filename
      // and the blob is a File type
      const filePayload = filename ? { blob, filename } : blob
      uploadFacePhoto(
        { file: filePayload, sdkMetadata },
        url,
        token,
        shouldSign,
        onApiSuccess,
        onApiError
      )
    }
  }

  const handleVideoUpload = (
    payload: UploadVideoPayload,
    url: string,
    token: string,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback
  ) => {
    const shouldSign = !!face_video_capture?.sign_upload
    uploadFaceVideo(
      payload as UploadVideoPayload,
      url,
      token,
      shouldSign,
      onApiSuccess,
      onApiError
    )
  }

  const uploadCaptureToOnfido = (
    capture: CapturePayload,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback,
    onSubmitCallback: OnSubmitCallback
  ) => {
    const { token, language, isDecoupledFromAPI, urls } = props
    const { blob, variant, challengeData, sdkMetadata } = capture
    const url = urls.onfido_api_url

    if (!token) {
      throw new Error('token not provided')
    }

    if (!url) {
      throw new Error('url not provided')
    }

    if (variant === 'video') {
      const data: UploadVideoPayload = {
        challengeData,
        blob,
        language,
        sdkMetadata,
      }
      if (isDecoupledFromAPI) {
        onSubmitCallback(data, CALLBACK_TYPES.video)
      } else {
        handleVideoUpload(data, url, token, onApiSuccess, onApiError)
      }
    } else if (isDecoupledFromAPI) {
      onSubmitCallback(capture, CALLBACK_TYPES.selfie)
    } else {
      handleSelfieUpload(
        capture as FaceCapture,
        url,
        token,
        onApiSuccess,
        onApiError
      )
    }
  }

  const submitUpload = (
    data: FaceCapture | UploadVideoPayload | UploadDocumentPayload,
    callbackName: CallbackNames,
    url: string,
    token: string,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback
  ) => {
    if (callbackName === CALLBACK_TYPES.video) {
      handleVideoUpload(
        data as UploadVideoPayload,
        url,
        token,
        onApiSuccess,
        onApiError
      )
    } else if (callbackName === CALLBACK_TYPES.selfie) {
      handleSelfieUpload(
        data as FaceCapture,
        url,
        token,
        onApiSuccess,
        onApiError
      )
    }
  }

  const {
    actions,
    capture,
    completeStep,
    crossDeviceClientError,
    enterpriseFeatures,
    nextStep,
    previousStep,
    resetSdkFocus,
    token,
    trackScreen,
    triggerOnError,
    urls,
  } = props

  const {
    uploadInProgress,
    confirmError,
    onConfirm,
    onRetake,
    onVideoPreviewError,
  } = useConfirm(
    actions,
    capture,
    'face',
    token,
    urls,
    completeStep,
    nextStep,
    previousStep,
    resetSdkFocus,
    submitUpload,
    trackScreen,
    triggerOnError,
    uploadCaptureToOnfido,
    crossDeviceClientError,
    enterpriseFeatures
  )

  if (uploadInProgress) {
    return <Spinner />
  }

  return (
    <Previews
      isFullScreen={!!props.isFullScreen}
      capture={props.capture}
      retakeAction={onRetake}
      confirmAction={onConfirm}
      isUploading={uploadInProgress}
      error={confirmError}
      trackScreen={props.trackScreen}
      method={'face'}
      documentType={props.documentType}
      forceRetake={confirmError?.type === 'error'}
      onVideoError={onVideoPreviewError}
    />
  )
}

export default SelfieConfirm
