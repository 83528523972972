import { trackException } from 'Tracker'
import { BlurDetectionResult } from '../OpenCV/BlurDetection'
import { DetectionParams } from '../OpenCV/DetectionParams'
import { EdgeDetectionResults } from '../OpenCV/EdgeDetection'
import { Rectangle } from '../OpenCV/Geometrie'
import {
  BaseDocumentAnalytics,
  AutoCaptureFailReason,
  JsDocumentCaptureScreenAnalytics,
  JsDocumentCaptureStartAnalytics,
  JsDocumentCaptureManualFrameSelectedAnalytics,
  JsDocumentCaptureAutoCaptureSuccessAnalytics,
  AutocaptureResultAnalytics,
  JsDocumentCaptureManualShutterPressed,
} from './types'

export type Counters =
  | 'blur_success'
  | 'blur_time'
  | 'blur_variance'
  | 'warning_document'
  | 'edge_total_time'
  | 'edge_detect_segment_time'
  | 'edge_hough_line_time'
  | 'edge_success'
  | 'number_edges'
  | 'edge_top_detected'
  | 'edge_top_score'
  | 'edge_bottom_detected'
  | 'edge_bottom_score'
  | 'edge_left_detected'
  | 'edge_left_score'
  | 'edge_right_detected'
  | 'edge_right_score'
  | 'hold_still_phase'

export class AnalyticsCollector {
  readonly baseDocumentAnalytics: BaseDocumentAnalytics
  constructor(baseDocumentAnalytics: BaseDocumentAnalytics) {
    this.baseDocumentAnalytics = baseDocumentAnalytics
  }

  counters: Partial<Record<Counters, number[]>> = {}
  initialParams?: DetectionParams
  finalParams?: DetectionParams
  rectangle?: Rectangle
  videoConstraints?: MediaTrackConstraints
  capabilites?: MediaTrackCapabilities
  settings?: MediaTrackSettings
  autocaptureFailReason?: AutoCaptureFailReason
  latestEdgeResults?: EdgeDetectionResults
  selectedImageLaplacianVariance?: number
  autocaptureSuccess?: boolean

  public setInitialParams = (initialParams: DetectionParams) => {
    this.initialParams = initialParams
  }
  public setFinalParams = (params: DetectionParams) => {
    this.finalParams = params
  }
  public setDetectionRectangle = (rectangle: Rectangle) => {
    this.rectangle = rectangle
  }
  public setSettings = (settings?: MediaTrackSettings) => {
    this.settings = settings
  }
  public setCapabilities = (capabilities?: MediaTrackCapabilities) => {
    this.capabilites = capabilities
  }

  public setVideoConstraints = (constraints?: MediaTrackConstraints) => {
    this.videoConstraints = constraints
  }

  public setAutocaptureFailReason = (reason?: AutoCaptureFailReason) => {
    this.autocaptureFailReason = reason
  }

  public setAutoCaptureSuccess = (success: boolean) =>
    (this.autocaptureSuccess = success)

  public getAutoCaptureSuccess = () => this.autocaptureSuccess

  public addCounter = (counter: Counters, value: number) => {
    if (!this.counters[counter]) {
      this.counters[counter] = []
    }
    this.counters[counter]?.push(value)
  }

  private addBoolCounter = (counter: Counters, value: boolean) =>
    this.addCounter(counter, value ? 1 : 0)

  private setLatestEdgeResults = (edgeResults: EdgeDetectionResults) =>
    (this.latestEdgeResults = edgeResults)

  public addEdgeCounters = (
    edgeResults: EdgeDetectionResults,
    params: DetectionParams
  ) => {
    this.setLatestEdgeResults(edgeResults)
    this.addCounter('edge_total_time', edgeResults.analytics.totalDuration)
    this.addCounter(
      'edge_detect_segment_time',
      edgeResults.analytics.detectSegmentDuration
    )
    this.addCounter(
      'edge_hough_line_time',
      edgeResults.analytics.houghLinesDuration
    )
    this.addCounter('number_edges', edgeResults.segments.totalDetected)
    this.addCounter('edge_bottom_score', edgeResults.segments.bottom.score)
    this.addBoolCounter(
      'edge_bottom_detected',
      edgeResults.segments.bottom.detected
    )
    this.addCounter('edge_left_score', edgeResults.segments.left.score)
    this.addBoolCounter(
      'edge_left_detected',
      edgeResults.segments.left.detected
    )
    this.addCounter('edge_right_score', edgeResults.segments.right.score)
    this.addBoolCounter(
      'edge_right_detected',
      edgeResults.segments.right.detected
    )
    this.addCounter('edge_top_score', edgeResults.segments.top.score)
    this.addBoolCounter('edge_top_detected', edgeResults.segments.top.detected)

    if (
      edgeResults.segments.totalDetected >= params.edgeDetection.requiredEdges
    ) {
      this.addCounter('edge_success', 1)
    }
  }

  public addBlurCounters = (
    blurResults: BlurDetectionResult,
    params: DetectionParams
  ) => {
    if (blurResults.variance >= params.blur.threshold) {
      this.addCounter('blur_success', 1)
    }
    this.addCounter('blur_time', blurResults.analytics.duration)
    this.addCounter('blur_variance', blurResults.variance)
  }

  public setSelectedImageLaplacianVariance = (variance: number) =>
    (this.selectedImageLaplacianVariance = variance)

  public mean = (counter: Counters) => {
    if (!this.counters[counter]?.length) {
      return
    }

    const sum = this.counters[counter]!.reduce(
      (runningSum, current) => runningSum + current
    )
    return sum / this.counters[counter]!.length
  }

  public count = (counter: Counters) => this.counters[counter]?.length || 0

  public best = (counter: Counters) =>
    this.counters[counter]?.sort((a, b) => b - a)[0]

  public reportJsDocumentCaptureScreen = (): JsDocumentCaptureScreenAnalytics => {
    return { dont_format_properties: true, ...this.baseDocumentAnalytics }
  }

  public reportJsDocumentManualShutterPressed = (): JsDocumentCaptureManualShutterPressed => {
    return { dont_format_properties: true, ...this.baseDocumentAnalytics }
  }

  public reportJsDocumentCaptureStart = ():
    | JsDocumentCaptureStartAnalytics
    | undefined => {
    if (!this.rectangle) {
      console.error('missing rectangle')
      return
    }
    if (!this.capabilites) {
      console.error('missing capabilities')
      return
    }
    if (!this.settings) {
      console.error('missing settings')
      return
    }
    if (!this.videoConstraints) {
      console.error('missing constraints')
      return
    }
    return {
      dont_format_properties: true,
      ...this.baseDocumentAnalytics,
      rectangle_coordinates: this.rectangle,
      selected_camera_capabilities: this.capabilites,
      selected_camera_settings: this.settings,
      video_constraints: this.videoConstraints,
    }
  }

  private buildAutocaptureResults = ():
    | AutocaptureResultAnalytics
    | undefined => {
    if (!this.initialParams) {
      trackException('missing initialParams')
      return
    }
    return {
      algorithms_count_tries: {
        blur_detection_count: this.count('blur_time'),
        blur_detection_success: this.count('blur_success'),
        edge_detection_count: this.count('edge_total_time'),
        edge_detection_success: this.count('edge_success'),
      },
      blur_results: {
        best_laplacian_variance: this.best('blur_variance'),
        mean_laplacian_variance: this.mean('blur_variance'),
      },
      edge_detection_results: {
        mean_number_edges_detected: this.mean('number_edges'),
        mean_bottom_detected: this.mean('edge_bottom_detected'),
        mean_bottom_score: this.mean('edge_bottom_score'),
        mean_top_detected: this.mean('edge_top_detected'),
        mean_top_score: this.mean('edge_top_score'),
        mean_right_detected: this.mean('edge_right_detected'),
        mean_right_score: this.mean('edge_right_score'),
        mean_left_detected: this.mean('edge_left_detected'),
        mean_left_score: this.mean('edge_left_score'),
      },
      hold_still_phase: this.count('hold_still_phase'),
      initial_detection_parameters: { ...this.initialParams },
      initial_detection_parameters_overridden: !!this.finalParams,
      final_detection_parameters: this.finalParams
        ? { ...this.finalParams }
        : undefined,
      on_device_warnings: {
        document: this.count('warning_document'),
      },
      timings: {
        mean_edge_detect_segment_time_ms: this.mean('edge_detect_segment_time'),
        mean_edge_hough_line_time_ms: this.mean('edge_hough_line_time'),
        mean_time_blur_detection_ms: this.mean('blur_time'),
        mean_time_total_edge_detection_ms: this.mean('edge_total_time'),
      },
    }
  }

  public reportJsDocumentCaptureManualFrameSelected = ():
    | JsDocumentCaptureManualFrameSelectedAnalytics
    | undefined => {
    const autoCaptureResults = this.buildAutocaptureResults()
    if (!autoCaptureResults) {
      return
    }
    return {
      dont_format_properties: true,
      ...this.baseDocumentAnalytics,
      ...autoCaptureResults,
      autocapture_fail_reason: this.autocaptureFailReason,
    }
  }

  public reportJsDocumentCaptureAutoCaptureSuccess = ():
    | JsDocumentCaptureAutoCaptureSuccessAnalytics
    | undefined => {
    const autoCaptureResults = this.buildAutocaptureResults()
    if (!autoCaptureResults) {
      return
    }
    if (!this.latestEdgeResults) {
      trackException('no latestEdgeResults')
      return
    }
    if (this.selectedImageLaplacianVariance === undefined) {
      trackException('no selectedImageLaplacianVariance')
    }

    return {
      dont_format_properties: true,
      ...this.baseDocumentAnalytics,
      ...autoCaptureResults,
      selected_image_blur_results: {
        laplacian_variance: this.selectedImageLaplacianVariance,
      },
      latest_edge_detection_results: {
        top_detected: this.latestEdgeResults.segments.top.detected,
        top_score: this.latestEdgeResults.segments.top.score,
        bottom_detected: this.latestEdgeResults.segments.bottom.detected,
        bottom_score: this.latestEdgeResults.segments.bottom.score,
        left_detected: this.latestEdgeResults.segments.left.detected,
        left_score: this.latestEdgeResults.segments.left.score,
        right_detected: this.latestEdgeResults.segments.right.detected,
        right_score: this.latestEdgeResults.segments.right.score,
        number_edges_detected: this.latestEdgeResults.segments.totalDetected,
      },
    }
  }
}
