// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ActiveVideo-Disclaimer-disclaimer{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;padding:12px 16px;border-radius:var(--ods-border-radius-large);background:rgba(var(--ods-color-primary-100), 1);text-align:initial;margin-bottom:16px}[dir=\"ltr\"] .onfido-sdk-ui-ActiveVideo-Disclaimer-disclaimer svg{margin-right:12px}[dir=\"rtl\"] .onfido-sdk-ui-ActiveVideo-Disclaimer-disclaimer svg{margin-left:12px}.onfido-sdk-ui-ActiveVideo-Disclaimer-disclaimer svg{min-width:24px;fill:rgba(var(--ods-color-primary-500), 1)}.onfido-sdk-ui-ActiveVideo-Disclaimer-disclaimer .onfido-sdk-ui-ActiveVideo-Disclaimer-text{font-family:inherit;font-size:.875rem;font-weight:400;line-height:1.5rem;text-transform:none;color:rgba(var(--ods-color-neutral-800), 1);line-height:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": "onfido-sdk-ui-ActiveVideo-Disclaimer-disclaimer",
	"text": "onfido-sdk-ui-ActiveVideo-Disclaimer-text"
};
export default ___CSS_LOADER_EXPORT___;
