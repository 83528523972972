import React, { useState } from "react";
import cn from "classnames";
import styles from "./SelectCrypto.module.sass";
import Icon from "../Icon";
import { getDigitsAfterDecimal } from "../helper";

// const items = [
//   {
//     title: "Bitcoin",
//     currency: "BTC",
//     price: "$36,201.34",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/bitcoin.svg",
//   },
//   {
//     title: "Ethereum",
//     currency: "ETH",
//     price: "$2,605.95",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/ethereum.svg",
//   },
//   {
//     title: "EOS",
//     currency: "EOS.IO",
//     price: "$426.32",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/eos.svg",
//   },
//   {
//     title: "Tether",
//     currency: "USDT",
//     price: "$1.00",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/tether.svg",
//   },
//   {
//     title: "Cardano",
//     currency: "ADA",
//     price: "$1.86",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/cardano.svg",
//   },
//   {
//     title: "Dogecoin",
//     currency: "DOGE",
//     price: "$0.4139",
//     negativeDay: "-0.56",
//     image: "/images/content/currency/dogecoin.svg",
//   },
//   {
//     title: "XRP",
//     currency: "XRP",
//     price: "$1.05",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/ripple.svg",
//   },
//   {
//     title: "Polkadot",
//     currency: "DOT",
//     price: "$27.72",
//     negativeDay: "-0.56",
//     image: "/images/content/currency/polkadot.svg",
//   },
//   {
//     title: "USD Coin",
//     currency: "USDC",
//     price: "$1.00",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/usd-coin.svg",
//   },
//   {
//     title: "Uniswap",
//     currency: "UNI",
//     price: "$28.67",
//     positiveDay: "+6.04%",
//     image: "/images/content/currency/uniswap.svg",
//   },
// ];

const SelectCrypto = ({ goNext, marketData, handleOrder, loading }) => {
  const [search, setSearch] = useState("");
  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div className={styles.crypto}>
      <div className={styles.title}>Select crypto</div>
      <form className={styles.form}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search coin by name"
          required
        />
        <button onClick={(e) => { e.preventDefault() }} className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div >#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting" onClick={() => handleOrder()}>Name</div>
          </div>
          <div className={styles.col}>
            <div >Price</div>
          </div>
          <div className={styles.col}>24h %</div>
        </div>
        {marketData.filter((data) => {
          return data.name.toLowerCase().includes(search.toLowerCase())
        }).map((x, index) => (
          <div className={styles.row} key={index} onClick={() => goNext(x)}>
            <div className={styles.col}>
              <div className={styles.line}>
                <button className={cn("favorite", styles.favorite)}>
                  {/* <Icon name="star-outline" size="16" /> */}
                </button>
                {index + 1}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={x.icon} alt="Coin" />
                </div>
                <div className={styles.details}>
                  <span className={styles.subtitle}>{x.name}</span>
                  {/* <span className={styles.currency}>{x.currency}</span> */}
                </div>
              </div>
            </div>
            <div className={styles.col}> {getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}</div>
            <div className={styles.col}>
              {parseFloat(x.dayChange) < 0 ? (
                <div className={styles.negative}>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
              ) : (
                <div className={styles.positive}><small>+</small>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
              )
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCrypto;
