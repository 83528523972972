import { CV } from '@onfido/opencv'
import { DetectionParams } from './DetectionParams'
import { cropImage, resizeImage } from './Resize'
import { Rect, Rectangle } from './Geometrie'

export type BlurDetectionAnalytics = {
  duration: number
}

export type BlurDetectionResult = {
  variance: number
  detected: boolean
  analytics: BlurDetectionAnalytics
}

export const blurValidation = (
  cv: CV,
  params: DetectionParams,
  rectangle: Rectangle,
  image: ImageData
): BlurDetectionResult => {
  const start = Date.now()

  const original = cv.matFromImageData(image)
  const laplacian = new cv.Mat()
  const cropCoordinatesInOriginalFrameWithoutOffset = Rect.fromCoordinates(
    rectangle.left,
    rectangle.top,
    rectangle.right,
    rectangle.bottom
  )
  const croppedImage = cropImage(
    cv,
    original,
    cropCoordinatesInOriginalFrameWithoutOffset,
    params.crop.offset
  )
  const resized = resizeImage(cv, params.resizePixels, croppedImage)

  cv.cvtColor(resized, resized, cv.COLOR_BGR2GRAY)
  cv.Laplacian(resized, laplacian, cv.CV_64F)

  const mean = new cv.Mat(1, 4, cv.CV_64F)
  const stddev = new cv.Mat(1, 4, cv.CV_64F)

  cv.meanStdDev(laplacian, mean, stddev)

  const variance = stddev.doublePtr(0, 0) * stddev.doublePtr(0, 0)

  croppedImage.delete()
  laplacian.delete()
  resized.delete()
  mean.delete()
  stddev.delete()
  original.delete()

  return {
    detected: variance < params.blur.threshold,
    variance,
    analytics: {
      duration: Date.now() - start,
    },
  }
}
