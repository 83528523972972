import {
    DEPOSIT_FIAT_VERIFY_PAYMENT_FAILED,
    DEPOSIT_FIAT_VERIFY_PAYMENT_REQUEST,
    DEPOSIT_FIAT_VERIFY_PAYMENT_SUCCESS,
    DEPOSIT_FIAT_VALUEE_GET
} from "../actions/depositFiatVerifyPayment/actionType";

const initialState = {
    depositFiatVerifyPaymentLoading: false,
    depositFiatVerifyPaymentError: "",
    depositFiatVerifyPaymentData: [],
    gateWayId: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case DEPOSIT_FIAT_VERIFY_PAYMENT_REQUEST:
            return {
                ...state,
                depositFiatVerifyPaymentLoading: true,
                depositFiatVerifyPaymentError: "",
            };

        case DEPOSIT_FIAT_VERIFY_PAYMENT_SUCCESS:
            return {
                ...state,
                depositFiatVerifyPaymentLoading: false,
                depositFiatVerifyPaymentData: action.payload,
            };

        case DEPOSIT_FIAT_VERIFY_PAYMENT_FAILED:
            return {
                ...state,
                depositFiatVerifyPaymentLoading: false,
                depositFiatVerifyPaymentError: action.message,
            };
        case DEPOSIT_FIAT_VALUEE_GET:
            return {
                ...state,
                gateWayId: action.value
            };
        default:
            return state
    };
};

export default reducer;