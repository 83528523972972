import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";

const Successfully = ({
  setVisibleNotificationModal,
  accountStatus
}) => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h4", styles.title)}>
          Switch to Link Account!
        </div>
        {
          parseInt(accountStatus) === parseInt(process.env.REACT_APP_LINKED_ACCOUNT_STATUS_APPROVED) ?
            <div className={cn("h5", styles.subTitle)}>
              Your account has been approved.
            </div>
            :
            <div className={cn("h5", styles.subTitle)}>
              You have already requested for Link account.
            </div>
        }
        <div className={cn("h6", styles.title)}>
          *Additional local T&C's may apply!
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              setVisibleNotificationModal(false);
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
