// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-IntroVideo-introVideo{position:relative;margin:0 auto;font-size:0}.onfido-sdk-ui-IntroVideo-videoWrapper{background:none;border-radius:0;padding:0;margin:0;font-size:0;overflow:hidden;border:0}.onfido-sdk-ui-IntroVideo-videoWrapper:active{background:none;-webkit-box-shadow:none;box-shadow:none}.onfido-sdk-ui-IntroVideo-videoWrapper:focus,.onfido-sdk-ui-IntroVideo-videoWrapper:focus-visible{outline:2px solid var(--osdk-color-border-button-primary-active);outline-offset:-1px;border-radius:var(--ods-border-radius-small)}.onfido-sdk-ui-IntroVideo-video{max-width:100%;width:100%}.onfido-sdk-ui-IntroVideo-video:focus,.onfido-sdk-ui-IntroVideo-video:focus-visible{outline:0}.onfido-sdk-ui-IntroVideo-button{position:absolute;right:8px;bottom:8px;width:48px;height:48px;border-radius:50% !important;padding:0}.onfido-sdk-ui-IntroVideo-button svg{width:40px;height:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"introVideo": "onfido-sdk-ui-IntroVideo-introVideo",
	"videoWrapper": "onfido-sdk-ui-IntroVideo-videoWrapper",
	"video": "onfido-sdk-ui-IntroVideo-video",
	"button": "onfido-sdk-ui-IntroVideo-button"
};
export default ___CSS_LOADER_EXPORT___;
