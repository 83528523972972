
import Item from "./Item";
import cn from "classnames";
import styles from './Funds.module.sass'
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import OrderSkeleton from "../../../components/Skeleton/OrderSkeleton";

const Funds = ({ completeOrders, handleLoadMore, children, pageno, loading, active }) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>

        <button className={styles.link}>
          <span>Order History</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Market</div>
          <div className={styles.col}>Type</div>
          {/* <div className={styles.col}>Category</div> */}
          {active && (<div className={styles.col}>Rate</div>)}
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Value</div>
          {active && (<div className={styles.col}>Fee</div>)}
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date</div>
        </div>
        {loading && active ?
          <OrderSkeleton rowCount={10} colCount={9} />
          :
          loading ? <OrderSkeleton rowCount={10} colCount={7} />
            :
            completeOrders?.length > 0 ?
              <>
                {completeOrders?.map((x, index) => (
                  <Item
                    className={styles.item}
                    item={x}
                    key={index}
                    children={children}
                    active={active}
                  />
                ))}
                {completeOrders?.length === (10 * pageno) && <div className={styles.customButton}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                    <span>Load more...</span>
                  </button>
                </div>
                }
              </>
              :
              <div className={styles.btns}>
                <NoDataFound />
              </div>
        }

      </div>
    </div>
  );
};

export default Funds;
