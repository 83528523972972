import { useEffect, useMemo, useLayoutEffect, useState } from 'preact/hooks'

import {
  AnyStateMachine,
  InternalMachineOptions,
  interpret,
  InterpreterFrom,
  Prop,
  StateFrom,
} from 'xstate'

type UseMachineReturn<
  TMachine extends AnyStateMachine,
  TInterpreter = InterpreterFrom<TMachine>
> = [StateFrom<TMachine>, Prop<TInterpreter, 'send'>, TInterpreter]

type MachineOptions<TMachine extends AnyStateMachine> = {
  context?: Partial<TMachine['__TContext']>
} & InternalMachineOptions<
  TMachine['__TContext'],
  TMachine['__TEvent'],
  TMachine['__TResolvedTypesMeta']
>

export const useMachine = <TMachine extends AnyStateMachine>(
  machine: TMachine,
  options: MachineOptions<TMachine>
): UseMachineReturn<TMachine> => {
  const service = useMemo(() => {
    const { context, ...config } = options

    const machineWithConfig = machine
      .withConfig(config as any)
      .withContext({ ...machine.context, ...context })

    return interpret(machineWithConfig)
  }, [])

  const store = useSyncExternalStore(
    (handler: any) => {
      const { unsubscribe } = service.subscribe(handler)
      return unsubscribe
    },
    () => service.getSnapshot()
  )

  useEffect(() => {
    service.start()
    return () => {
      service.stop()
    }
  }, [])

  return [store, service.send, service] as any
}

/* The following code including comments was taken from https://github.com/preactjs/preact/blob/master/compat/src/index.js */

/**
 * This is taken from https://github.com/facebook/react/blob/main/packages/use-sync-external-store/src/useSyncExternalStoreShimClient.js#L84
 * on a high level this cuts out the warnings, ... and attempts a smaller implementation
 */
function useSyncExternalStore(subscribe: any, getSnapshot: any) {
  const value = getSnapshot()

  const [{ _instance }, forceUpdate] = useState({
    _instance: { _value: value, _getSnapshot: getSnapshot },
  })

  useLayoutEffect(() => {
    _instance._value = value
    _instance._getSnapshot = getSnapshot

    if (!is(_instance._value, getSnapshot())) {
      forceUpdate({ _instance })
    }
  }, [subscribe, value, getSnapshot])

  useEffect(() => {
    if (!is(_instance._value, _instance._getSnapshot())) {
      forceUpdate({ _instance })
    }

    return subscribe(() => {
      if (!is(_instance._value, _instance._getSnapshot())) {
        forceUpdate({ _instance })
      }
    })
  }, [subscribe])

  return value
}

/**
 * Check if two values are the same value
 * @param {*} x
 * @param {*} y
 * @returns {boolean}
 */
function is(x: any, y: any) {
  return (x === y && (x !== 0 || 1 / x === 1 / y)) || (x !== x && y !== y)
}
