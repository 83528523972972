import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Cards.module.sass";
import { getDigitsAfterDecimal, getPostiveNegativeNum } from "../../../../components/helper";
import CardSkeleton from "../../../../components/Skeleton/CardSkeleton";

const Cards = ({ className, marketsData, loading }) => {

  return (
    <div className={cn(className, styles.cards)}>
      {loading ? <CardSkeleton count={5} />
        :
        marketsData
          .slice(0, 5)
          .map((x, index) => (
            <Link className={styles.card} key={index} to={localStorage.getItem("signature") ? `/exchange/${x.slug}` : process.env.REACT_APP_GLOBAL_URL}>

              <div className={styles.icon}>
                <img src={x.icon} alt="Currency" />
              </div>
              <div className={styles.details}>

                <div className={styles.line}>
                  <div className={styles.title}>{x.slug}</div>
                  {getPostiveNegativeNum(x.dayChange) ?
                    <div className={styles.positive}>{getDigitsAfterDecimal(x.dayChange, 2)}%</div>
                    :
                    <div className={styles.negative}>{getDigitsAfterDecimal(x.dayChange, 2)}%</div>
                  }
                </div>
                <div className={styles.price}>{getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}</div>
              </div>
            </Link>
          ))}
    </div>
  );
};

export default Cards;
