// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[dir=\"ltr\"] .onfido-sdk-ui-Confirm-actions{margin-right:-1em}[dir=\"rtl\"] .onfido-sdk-ui-Confirm-actions{margin-left:-1em}.onfido-sdk-ui-Confirm-actions{padding:0;width:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}@media(max-width: 479px){[dir=\"ltr\"] .onfido-sdk-ui-Confirm-actions{margin-right:-0.5em}[dir=\"rtl\"] .onfido-sdk-ui-Confirm-actions{margin-left:-0.5em}}.onfido-sdk-ui-Confirm-actions.onfido-sdk-ui-Confirm-vertical{-webkit-box-orient:vertical;-webkit-box-direction:reverse;-webkit-flex-direction:column-reverse;flex-direction:column-reverse;-webkit-box-align:center;-webkit-align-items:center;align-items:center}.onfido-sdk-ui-Confirm-singleAction{display:block;margin-bottom:.5em}[dir=\"ltr\"] .onfido-sdk-ui-Confirm-retakeAction{margin-right:1em}[dir=\"rtl\"] .onfido-sdk-ui-Confirm-retakeAction{margin-left:1em}@media(max-width: 479px){[dir=\"ltr\"] .onfido-sdk-ui-Confirm-retakeAction{margin-right:.5em}[dir=\"rtl\"] .onfido-sdk-ui-Confirm-retakeAction{margin-left:.5em}}[dir=\"ltr\"] .onfido-sdk-ui-Confirm-retakeAction.onfido-sdk-ui-Confirm-vertical{margin-right:0}[dir=\"rtl\"] .onfido-sdk-ui-Confirm-retakeAction.onfido-sdk-ui-Confirm-vertical{margin-left:0}.onfido-sdk-ui-Confirm-retakeAction.onfido-sdk-ui-Confirm-vertical{margin-top:.5em;width:19.4285714286em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"actions": "onfido-sdk-ui-Confirm-actions",
	"vertical": "onfido-sdk-ui-Confirm-vertical",
	"singleAction": "onfido-sdk-ui-Confirm-singleAction",
	"retakeAction": "onfido-sdk-ui-Confirm-retakeAction"
};
export default ___CSS_LOADER_EXPORT___;
