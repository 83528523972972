import en_US from '../../locales/en_US/en_US.json'
import { LocaleMap, BaseLanguageMap } from './types'

export const defaultLanguage = en_US
export const defaultLocaleTag = 'en_US'

export const rtl = ['he_IL', 'ar', 'fa_IR']

export type SupportedLanguages =
  | 'bg'
  | 'da'
  | 'hr'
  | 'cs'
  | 'nl'
  | 'et'
  | 'fi'
  | 'fr'
  | 'de'
  | 'el'
  | 'en'
  | 'he'
  | 'hi'
  | 'hu'
  | 'in'
  | 'it'
  | 'ja'
  | 'ko'
  | 'lv'
  | 'lt'
  | 'no'
  | 'no_NO'
  | 'nb'
  | 'fa'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'sr'
  | 'sk'
  | 'sl'
  | 'es'
  | 'sv'
  | 'th'
  | 'tr'
  | 'uk'
  | 'vi'
  | 'zh'
  | 'ar'
  | 'bg_BG'
  | 'cs_CZ'
  | 'da_DK'
  | 'de_DE'
  | 'el_GR'
  | 'en_GB'
  | 'en_US'
  | 'es_419'
  | 'es_ES'
  | 'et_EE'
  | 'fa_IR'
  | 'fi_FI'
  | 'fr_CA'
  | 'fr_FR'
  | 'he_IL'
  | 'hi_IN'
  | 'hr_HR'
  | 'hu_HU'
  | 'hy'
  | 'id_ID'
  | 'it_IT'
  | 'ja_JP'
  | 'ko_KR'
  | 'lt_LT'
  | 'lv_LV'
  | 'ms'
  | 'nl_NL'
  | 'no'
  | 'pl_PL'
  | 'pt_BR'
  | 'pt_PT'
  | 'ro_RO'
  | 'ru_RU'
  | 'sk_SK'
  | 'sl_SI'
  | 'sr_Latn_RS'
  | 'sv_SE'
  | 'th_TH'
  | 'tr_TR'
  | 'uk_UA'
  | 'vi_VN'
  | 'zh_CN'
  | 'zh_TW'

export const baseLanguageMap: BaseLanguageMap = {
  bg: 'bg_BG',
  da: 'da_DK',
  hr: 'hr_HR',
  cs: 'cs_CZ',
  nl: 'nl_NL',
  et: 'et_EE',
  fi: 'fi_FI',
  fr: 'fr_FR',
  de: 'de_DE',
  el: 'el_GR',
  en: 'en_US',
  he: 'he_IL',
  hi: 'hi_IN',
  hu: 'hu_HU',
  in: 'id_ID',
  it: 'it_IT',
  ja: 'ja_JP',
  ko: 'ko_KR',
  lv: 'lv_LV',
  lt: 'lt_LT',
  no: 'no',
  no_NO: 'no',
  nb: 'nb_NO',
  fa: 'fa_IR',
  pl: 'pl_PL',
  pt: 'pt_PT',
  ro: 'ro_RO',
  ru: 'ru_RU',
  sr: 'sr_Latn_RS',
  sk: 'sk_SK',
  sl: 'sl_SI',
  es: 'es_ES',
  sv: 'sv_SE',
  th: 'th_TH',
  tr: 'tr_TR',
  uk: 'uk_UA',
  vi: 'vi_VN',
  zh: 'zh_CN',
}

export const languages: LocaleMap = {
  en_US: () => Promise.resolve(en_US),
  en: () => Promise.resolve(en_US),

  // Bokmål and Nynorsk.
  no_NO: () =>
    import(/* webpackChunkName: "lang_no" */ '../../locales/no/no.json'),
  nb_NO: () =>
    import(/* webpackChunkName: "lang_no" */ '../../locales/no/no.json'),

  ar: () =>
    import(/* webpackChunkName: "lang_ar" */ '../../locales/ar/ar.json'),
  bg_BG: () =>
    import(
      /* webpackChunkName: "lang_bg_BG" */ '../../locales/bg_BG/bg_BG.json'
    ),
  cs_CZ: () =>
    import(
      /* webpackChunkName: "lang_cs_CZ" */ '../../locales/cs_CZ/cs_CZ.json'
    ),
  da_DK: () =>
    import(
      /* webpackChunkName: "lang_da_DK" */ '../../locales/da_DK/da_DK.json'
    ),
  de_DE: () =>
    import(
      /* webpackChunkName: "lang_de_DE" */ '../../locales/de_DE/de_DE.json'
    ),
  el_GR: () =>
    import(
      /* webpackChunkName: "lang_el_GR" */ '../../locales/el_GR/el_GR.json'
    ),
  en_GB: () =>
    import(
      /* webpackChunkName: "lang_en_GB" */ '../../locales/en_GB/en_GB.json'
    ),
  es_419: () =>
    import(
      /* webpackChunkName: "lang_es_419" */ '../../locales/es_419/es_419.json'
    ),
  es_ES: () =>
    import(
      /* webpackChunkName: "lang_es_ES" */ '../../locales/es_ES/es_ES.json'
    ),
  et_EE: () =>
    import(
      /* webpackChunkName: "lang_et_EE" */ '../../locales/et_EE/et_EE.json'
    ),
  fa_IR: () =>
    import(
      /* webpackChunkName: "lang_fa_IR" */ '../../locales/fa_IR/fa_IR.json'
    ),
  fi_FI: () =>
    import(
      /* webpackChunkName: "lang_fi_FI" */ '../../locales/fi_FI/fi_FI.json'
    ),
  fr_CA: () =>
    import(
      /* webpackChunkName: "lang_fr_CA" */ '../../locales/fr_CA/fr_CA.json'
    ),
  fr_FR: () =>
    import(
      /* webpackChunkName: "lang_fr_FR" */ '../../locales/fr_FR/fr_FR.json'
    ),
  he_IL: () =>
    import(
      /* webpackChunkName: "lang_he_IL" */ '../../locales/he_IL/he_IL.json'
    ),
  hi_IN: () =>
    import(
      /* webpackChunkName: "lang_hi_IN" */ '../../locales/hi_IN/hi_IN.json'
    ),
  hr_HR: () =>
    import(
      /* webpackChunkName: "lang_hr_HR" */ '../../locales/hr_HR/hr_HR.json'
    ),
  hu_HU: () =>
    import(
      /* webpackChunkName: "lang_hu_HU" */ '../../locales/hu_HU/hu_HU.json'
    ),
  hy: () =>
    import(/* webpackChunkName: "lang_hy" */ '../../locales/hy/hy.json'),
  id_ID: () =>
    import(
      /* webpackChunkName: "lang_id_ID" */ '../../locales/id_ID/id_ID.json'
    ),
  it_IT: () =>
    import(
      /* webpackChunkName: "lang_it_IT" */ '../../locales/it_IT/it_IT.json'
    ),
  ja_JP: () =>
    import(
      /* webpackChunkName: "lang_ja_JP" */ '../../locales/ja_JP/ja_JP.json'
    ),
  ko_KR: () =>
    import(
      /* webpackChunkName: "lang_ko_KR" */ '../../locales/ko_KR/ko_KR.json'
    ),
  lt_LT: () =>
    import(
      /* webpackChunkName: "lang_lt_LT" */ '../../locales/lt_LT/lt_LT.json'
    ),
  lv_LV: () =>
    import(
      /* webpackChunkName: "lang_lv_LV" */ '../../locales/lv_LV/lv_LV.json'
    ),
  ms: () =>
    import(/* webpackChunkName: "lang_ms" */ '../../locales/ms/ms.json'),
  nl_NL: () =>
    import(
      /* webpackChunkName: "lang_nl_NL" */ '../../locales/nl_NL/nl_NL.json'
    ),
  no: () =>
    import(/* webpackChunkName: "lang_no" */ '../../locales/no/no.json'),
  pl_PL: () =>
    import(
      /* webpackChunkName: "lang_pl_PL" */ '../../locales/pl_PL/pl_PL.json'
    ),
  pt_BR: () =>
    import(
      /* webpackChunkName: "lang_pt_BR" */ '../../locales/pt_BR/pt_BR.json'
    ),
  pt_PT: () =>
    import(
      /* webpackChunkName: "lang_pt_PT" */ '../../locales/pt_PT/pt_PT.json'
    ),
  ro_RO: () =>
    import(
      /* webpackChunkName: "lang_ro_RO" */ '../../locales/ro_RO/ro_RO.json'
    ),
  ru_RU: () =>
    import(
      /* webpackChunkName: "lang_ru_RU" */ '../../locales/ru_RU/ru_RU.json'
    ),
  sk_SK: () =>
    import(
      /* webpackChunkName: "lang_sk_SK" */ '../../locales/sk_SK/sk_SK.json'
    ),
  sl_SI: () =>
    import(
      /* webpackChunkName: "lang_sl_SI" */ '../../locales/sl_SI/sl_SI.json'
    ),
  sr_Latn_RS: () =>
    import(
      /* webpackChunkName: "lang_sr_Latn_RS" */ '../../locales/sr_Latn_RS/sr_Latn_RS.json'
    ),
  sv_SE: () =>
    import(
      /* webpackChunkName: "lang_sv_SE" */ '../../locales/sv_SE/sv_SE.json'
    ),
  th_TH: () =>
    import(
      /* webpackChunkName: "lang_th_TH" */ '../../locales/th_TH/th_TH.json'
    ),
  tr_TR: () =>
    import(
      /* webpackChunkName: "lang_tr_TR" */ '../../locales/tr_TR/tr_TR.json'
    ),
  uk_UA: () =>
    import(
      /* webpackChunkName: "lang_uk_UA" */ '../../locales/uk_UA/uk_UA.json'
    ),
  vi_VN: () =>
    import(
      /* webpackChunkName: "lang_vi_VN" */ '../../locales/vi_VN/vi_VN.json'
    ),
  zh_CN: () =>
    import(
      /* webpackChunkName: "lang_zh_CN" */ '../../locales/zh_CN/zh_CN.json'
    ),
  zh_TW: () =>
    import(
      /* webpackChunkName: "lang_zh_TW" */ '../../locales/zh_TW/zh_TW.json'
    ),
}
