import { assign, createMachine, EventObject, InvokeCreator } from 'xstate'

export const SELECTION_TIMEOUT = 1000

interface Context {
  recordedBlobs: Array<Blob> | undefined
  mediaRecorder: MediaRecorder | undefined
  video: Blob | undefined
}

const defaultContext: Context = {
  recordedBlobs: undefined,
  mediaRecorder: undefined,
  video: undefined,
}

export const createMultiFrameCaptureMachine = ({
  startRecording,
  stopRecording,
}: {
  startRecording: InvokeCreator<Context, EventObject>
  stopRecording: InvokeCreator<Context, EventObject>
}) =>
  createMachine<Context>(
    {
      id: 'multiFrameCapture',
      initial: 'idle',
      context: defaultContext,
      predictableActionArguments: true,

      states: {
        idle: {
          after: {
            CAPTURE_DELAY: {
              target: 'startCapturing',
            },
          },
        },

        startCapturing: {
          invoke: {
            src: 'startRecording',
            onDone: {
              actions: 'assignData',
              target: 'capturing',
            },
          },
        },

        capturing: {
          after: {
            CAPTURE_TIMEOUT: {
              target: 'stopCapturing',
            },
          },
        },

        stopCapturing: {
          invoke: {
            src: 'stopRecording',
            onDone: {
              actions: 'assignVideo',
              target: 'done',
            },
          },
        },

        done: {
          type: 'final',
          data: (ctx) => ctx.video,
        },
      },
    },
    {
      actions: {
        assignData: assign(({}, { data }) => data),
        assignVideo: assign({
          video: ({}, { data }) => data,
        }),
      },
      services: {
        startRecording,
        stopRecording,
      },
      delays: {
        CAPTURE_DELAY: 1000,
        CAPTURE_TIMEOUT: 1500,
      },
    }
  )
