import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Player from "../../../components/Player";

const breadcrumbs = [
  {
    title: "Legal Center",
    url: "/legal-center",
  },
  {
    title: "Terms & Conditions",
  },
];

const Main = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>
            Application: Token/Coin Listing on Bitdenex
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Main;
