import React from "react";
import Main from "./Main";
import Partners from '../../components/Partners'
import Popular from './Popular'
const BitdenexPartners = () => {
  return (
    <>
      <Main />
      <Popular/>
      <Partners/>
    </>
  );
};

export default BitdenexPartners;
