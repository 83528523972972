import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from '../../../components/helper'
import { Link } from "react-router-dom";
import ExchangeSkeleton from "../../../components/Skeleton/ExchangeSkeleton";
import { useDispatch } from "react-redux";
import requestHandler from "../../../actions/httpClient";
import { userStatusCreator } from "../../../actions/getUserStatus";

const navigation = [
  {
    "currencyId": 1,
    "name": "EURO"
  },
  {
    "currencyId": 3,
    "name": "USDT"
  },
  {
    "currencyId": 2,
    "name": "BTC"
  }
];

const sortMarketNav = ["All", "Favorites"];

const Currency = ({
  allMarketsData,
  marketLoading,
  dataMarkets,
  setDataMarkets,
  favoriteMarkets,
  setFavoriteMarkets
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState({ column: null, order: 'ASC' });
  const [activeColumn, setActiveColumn] = useState({ key: "", value: false });
  const [activeTab, setActiveTab] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    filterData();
  }, [dataMarkets, favoriteMarkets, activeTab]);

  const filterData = () => {
    let newFilteredData = [];
    switch (activeTab) {
      case 'All':
        newFilteredData = dataMarkets;
        break;
      case 'Favorites':
        newFilteredData = dataMarkets?.filter(item => favoriteMarkets?.includes(item?.id));
        break;
      default:
        break;
    }
    setFilteredData(newFilteredData);
  };

  const toggleFavorite = async (item) => {
    let data = {
      signature: localStorage.getItem("signature"),
      marketId: item?.id
    };
    try {
      const favoritePayload = await requestHandler("addRemoveFavMarkets", "post", data, "jwt_token");
      if (favoritePayload?.status === 200) {
        dispatch(userStatusCreator());
        setFavoriteMarkets(prevFavorites => {
          const isFavorite = prevFavorites?.includes(item.id);
          if (isFavorite) {
            const updatedFavorites = prevFavorites?.filter(id => id !== item.id);
            if (activeTab === "Favorites") {
              const updatedFilteredData = dataMarkets?.filter(dataItem => updatedFavorites?.includes(dataItem.id));
              setFilteredData(updatedFilteredData);
            }
            return updatedFavorites;
          }
          else {
            return [...prevFavorites, item?.id];
          }
        });
      }
    }
    catch (e) {
    }
  };

  const isFavorite = (marketId) => {
    return favoriteMarkets?.includes(marketId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSort = (columnName) => {
    if (columnName === "name") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "price") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "dayChange") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["dayChange"] > b["dayChange"] ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["dayChange"] < b["dayChange"] ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setOrder(sortingType);
    setActiveColumn({ key: columnName, value: true });
  };

  return (
    <div className={styles.currency}>
      <div className={styles.stickyHeaderNav}>
        <div className={styles.nav}>
          {allMarketsData?.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => { setActiveIndex(index); setCurrencyId(x.currencyId) }}
              key={index}
            >
              {x.currency}
            </button>
          ))}
        </div>
        <form className={styles.form} action="" onSubmit={(e) => handleSubmit(e)}>
          <input
            className={styles.input}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            name="search"
            placeholder="Search by pair"
            required
          />
          <button className={styles.result}>
            <Icon name="search" size="20" />
          </button>
        </form>
        <div className={styles.foot}>
          <div className={styles.nav}>
            {sortMarketNav.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: activeTab === x,
                })}
                onClick={() => { setActiveTab(x) }}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.customRow}>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "name" })}
                onClick={() => { handleSort("name") }}>
                Pair
              </div>
            </div>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "price" })}
                onClick={() => { handleSort("price") }}>
                Price
              </div>
            </div>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "dayChange" })}
                onClick={() => { handleSort("dayChange") }}>
                Change
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.table}>
        {/* <div className={styles.col}>
          <div >Pair</div>
        </div>
        <div className={styles.col}>
          <div >Price</div>
        </div>
        <div className={styles.col}>
          <div>Change</div>
        </div> */}
        {marketLoading ? <ExchangeSkeleton rowCount={22} colCount={3} />
          :
          filteredData?.map((x, index) => {
            return (search !== "" && x.slug.replace("-", "/").toLowerCase().indexOf(search.toLowerCase()) === -1) ? null :
              <div className={styles.row} key={index}>
                <div className={styles.col}>
                  <div className={styles.line}>
                    <button
                      className={cn(styles.favoriteLine, {
                        [styles.active]: filteredData?.includes(x?.id),
                      })}
                      onClick={() => toggleFavorite(x, index)}
                    >
                      {isFavorite(x.id) ? (
                        <Icon name="star" size="16" fill="#22767d" />
                      ) : (
                        <Icon name="star-outline" size="16" />
                      )}
                    </button>
                  </div>
                  <div className={styles.line}>
                    <Link to={`/exchange/${x.slug}`} className={styles.market}  >
                      <div className={styles.info}>
                        {x.slug.replace("-", "/").toUpperCase()}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className={styles.col}>  {getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}</div>
                <div className={styles.col}>
                  {parseFloat(x.dayChange) < 0 ? (
                    <div className={styles.negative}>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
                  ) : (
                    <div className={styles.positive}><small>+</small>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
                  )
                  }
                </div>
              </div>
          })
        }
      </div>
    </div>
  );
};

export default Currency;
