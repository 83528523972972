import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Withdraw.module.sass";
import TextInput from "../TextInput";
import Successfully from "./Successfully";
import Code from "../../screens/WalletOverview/Code";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../Notification";
import Dropdown from "../Dropdown";
import requestHandler from "../../actions/httpClient";
import { getDigitsAfterDecimal } from "../helper";
import LoaderScreen from "../LoaderScreen";

const Withdraw = ({
  state, setVisibleSuccessfully,
  coinsData,
  withdrawAddressHandler, codeScreenHandler,
  activeIndex, userStatus,
  networkSlug,
  loader
}) => {
  const [visibleWithdraw, setVisibleWithdraw] = useState(true);
  const [amount, setAmount] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();
  const [newAddress, setNewAddress] = useState(false);
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [ownership, setOwnership] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("0.0");
  const [token, setToken] = useState("Please select a value");
  const [tokenOptions, setTokenOptions] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [tokenMaxFee, setTokenMaxFee] = useState([]);
  const [tokenIndex, setTokenIndex] = useState();
  const [btnProperty, setBtnProperty] = useState(true)
  const [visibleProperty, setVisibleProperty] = useState(true);
  const [warning, setWarning] = useState()
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState("");
  const [showTagField, setShowTagField] = useState();

  useEffect(() => {
    if (coinsData) {
      coinsData?.data?.data?.filter((x) =>
        x.symbol === state.symbol
      ).map((x) => {
        setTokenMaxFee(x?.network_max_fee);
        setSelectedAddress(x.maxFee);
        setTokenOptions(x.network_slug);
        x?.is_tag_enable === 1 ? setShowTagField(true) : setShowTagField(false);
      })
    }
  }, []);

  const handleClick = () => {
    setVisibleWithdraw(false);
  };

  const handleCode = () => {
    setVisibleSuccessfully(true);
  };

  const addressVerify = async () => {
    setLoading(true);
    if (withdrawAddress != '') {
      const data = {
        signature: localStorage.getItem("signature"),
        address: withdrawAddress,
        coinId: state.coinId,
      };
      try {
        const adressPayload = await requestHandler("checkInternalAddress", "post", data, "jwt_token");
        setLoading(false);
        if (adressPayload.data.message[0].status === false) {
          setVisibleProperty(true);
          // setBtnProperty(false);
        } else {
          setVisibleProperty(false);
          // setBtnProperty(true);
          addNotification({
            title: 'Alert',
            message: "This address belongs to bitdenex , Please transfer directly through crypto withdraw.",
            type: 'danger'
          });
        }
      } catch (error) {
        setLoading(false);
      }
    }
    else {
      setLoading(false);
      // setBtnProperty(true);
    }
  };

  return (
    <>
      {visibleWithdraw && (
        <div className={styles.withdraw}>
          {loading && <LoaderScreen />}
          {!newAddress && (
            <>
              <div className={cn("h4", styles.title)}>
                Withdraw {state.symbol.toUpperCase()}
              </div>
              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Address"
                  name="address"
                  type="text"
                  placeholder="Please enter address"
                  required
                  value={withdrawAddress}
                  onChange={(e) => { setWithdrawAddress(e.target.value); }}
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("address");
                    addressVerify()
                  }}
                />
              </div>
              <span className={styles.danger}>
                {simpleValidator.current.message("address", withdrawAddress, "required")}
              </span>

              {(showTagField) &&
                <>
                  <div className={styles.box}>
                    <TextInput
                      className={styles.field}
                      label="Enter tag"
                      name="tag"
                      type="text"
                      placeholder="Please enter a tag"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                    />
                  </div>
                  <span className={styles.danger}>
                    {simpleValidator.current.message("tag", tag, "required")}
                  </span>
                </>
              }

              {!visibleProperty &&
                <div className={styles.customWrap}>
                  <div className={styles.info}>
                    Note: This address belongs to Bitdenex, you can continue without whitelisting this address.
                  </div>
                </div>
              }
              <div className={styles.wrap}>
                <div className={styles.category}>
                  Available<br></br>balance
                </div>
                <div className={styles.details}>
                  <div className={styles.currency}>{`${getDigitsAfterDecimal(state?.balance, state?.decimalPrecision)} ${state.symbol.toUpperCase()}`}</div>
                </div>
              </div>

              {state?.stableCoin ===
                parseInt(process.env.REACT_APP_UNSTABLE_COIN) ? (
                <>
                  <div className={styles.field}>
                    <Dropdown
                      className={styles.dropdown}
                      label="Select token network"
                      value={token}
                      setValue={setToken}
                      options={tokenOptions}
                      setIndex={setTokenIndex}
                    />
                  </div>
                  <span className={styles.danger}>
                    {simpleValidator.current.message("token", token, "required")}
                  </span>
                </>
              ) : null}
              {warning}
              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Amount to withdraw"
                  name="amount"
                  type="text"
                  required
                  value={amount}
                  placeholder="Please enter amount"
                  onChange={(e) => {
                    const re = /^\d*\.?\d*$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setAmount(e.target.value)
                    }
                  }
                  }
                  onBlur={() => { simpleValidator.current.showMessageFor("amount") }}
                />
                <button className={cn("button-stroke button-small", styles.button)} onClick={() => setAmount(getDigitsAfterDecimal(state.balance, state?.decimalPrecision))}>
                  Max amount
                </button>
              </div>
              <span className={styles.danger}>
                {simpleValidator.current.message("amount", amount, "required")}
              </span>
              {visibleProperty &&
                <>
                  {state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) ?
                    <TextInput
                      className={styles.field}
                      label="transaction fee"
                      name="transaction"
                      type="text"
                      placeholder="0.0"
                      disabled={true}
                      required
                      value={tokenMaxFee[tokenIndex]
                        ? parseFloat(tokenMaxFee[tokenIndex])
                        : "0.0"}
                    /> :
                    <TextInput
                      className={styles.field}
                      label="transaction fee"
                      name="transaction"
                      type="text"
                      placeholder="0.0"
                      disabled={true}
                      required
                      value={parseFloat(selectedAddress) ? parseFloat(selectedAddress) : "0.00"}
                    />
                  }
                </>
              }

              {visibleProperty &&
                <>
                  {state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) ?
                    <TextInput
                      className={styles.field}
                      label="amount to receive"
                      name="total"
                      type="text"
                      placeholder="0.0"
                      required
                      disabled={true}
                      // value={(parseFloat(amount) - parseFloat(tokenMaxFee[tokenIndex]) > 0) ? getDigitsAfterDecimal((!amount
                      //   ? "0.0"
                      //   : parseFloat(amount) - parseFloat(tokenMaxFee[tokenIndex]).toFixed(state?.decimalPrecision)), state?.decimalPrecision) : "0.00"}
                      value={(parseFloat(amount) - parseFloat(tokenMaxFee[tokenIndex]) > 0) ? getDigitsAfterDecimal((!amount
                        ? "0.0"
                        : parseFloat(amount) - parseFloat(tokenMaxFee[tokenIndex]).toFixed(state?.decimalPrecision)), state?.decimalPrecision) : "0.00"}

                    />
                    :
                    <TextInput
                      className={styles.field}
                      label="amount to receive"
                      name="total"
                      type="text"
                      placeholder="0.0"
                      required
                      disabled={true}
                      // value={
                      //   (parseFloat(amount) - parseFloat(selectedAddress) > 0) ?
                      //     getDigitsAfterDecimal((!amount
                      //       ? "0.0"
                      //       : parseFloat(amount) - parseFloat(selectedAddress)
                      //     ), state?.decimalPrecision) : "0.00"
                      // }
                      value={
                        (parseFloat(amount) - parseFloat(selectedAddress) > 0) ? getDigitsAfterDecimal((!amount
                          ? "0.0"
                          : parseFloat(parseFloat(amount) - parseFloat(selectedAddress)).toFixed(state?.decimalPrecision)), state?.decimalPrecision) : "0.00"
                      }
                    />
                  }
                </>
              }
              <button
                // disabled={btnProperty}
                className={cn("button", styles.button)}
                onClick={(e) => {
                  e.preventDefault();
                  if (simpleValidator.current.allValid()) {
                    if (parseFloat(amount) > parseFloat(state.balance) || parseFloat(amount) <= 0) {
                      addNotification({
                        title: 'Alert',
                        message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                        type: 'danger'
                      });
                    }
                    else if (
                      state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && token === "Please select a value"
                    ) {
                      addNotification({
                        title: "Alert",
                        message: "Please select coin network",
                        type: "danger",
                      });
                    }
                    else if (
                      state.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) &&
                      parseFloat(amount) - parseFloat(tokenMaxFee[tokenIndex]) <= 0
                    ) {
                      addNotification({
                        title: "Alert",
                        message: "Entered amount is too low to withdraw",
                        type: "danger",
                      });
                    }
                    else if (showTagField) {
                      if (state.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN) && visibleProperty === false && withdrawAddress != "" && amount !== "", tag !== "") {
                        if (parseFloat(state?.balance) < parseFloat(amount)) {
                          addNotification({
                            title: "Alert",
                            message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                            type: "danger",
                          });
                        }
                        else {
                          withdrawAddressHandler(state.coinId, amount, withdrawAddress, token, tag);
                          handleClick();
                        }
                      }
                    }
                    else if (state.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN) && visibleProperty === false && withdrawAddress != "" && amount !== "") {
                      if (parseFloat(state?.balance) < parseFloat(amount)) {
                        addNotification({
                          title: "Alert",
                          message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                          type: "danger",
                        });
                      }
                      else {
                        withdrawAddressHandler(state.coinId, amount, withdrawAddress, token);
                        handleClick();
                      }
                    }
                    else if (state.stableCoin !== parseInt(process.env.REACT_APP_UNSTABLE_COIN) &&
                      parseFloat(amount) - parseFloat(selectedAddress) <= 0) {
                      addNotification({
                        title: "Alert",
                        message: "Entered amount is too low to withdraw",
                        type: "danger",
                      });
                    }

                    else {
                      withdrawAddressHandler(state.coinId, amount, withdrawAddress, token);
                      handleClick();
                    }
                  }
                  else {
                    if (showTagField) {
                      if (visibleProperty === false && withdrawAddress != "" && amount !== "" && tag !== "") {
                        if (parseFloat(state?.balance) < parseFloat(amount) || parseFloat(amount) <= 0) {
                          addNotification({
                            title: "Alert",
                            message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                            type: "danger",
                          });
                        }
                        else {
                          withdrawAddressHandler(state.coinId, amount, withdrawAddress, token, tag);
                          handleClick();
                        }
                      }
                      else {
                        simpleValidator.current.showMessages();
                        setForceUpdate(forceUpdate + 1);
                      }
                    }
                    else {
                      if (visibleProperty === false && withdrawAddress != "" && amount !== "") {
                        if (parseFloat(state?.balance) < parseFloat(amount) || parseFloat(amount) <= 0) {
                          addNotification({
                            title: "Alert",
                            message: "You don't have that much of amount available in your wallet or you can't withdraw 0 amount.",
                            type: "danger",
                          });
                        }
                        else {
                          withdrawAddressHandler(state.coinId, amount, withdrawAddress, token);
                          handleClick();
                        }
                      }
                      else {
                        simpleValidator.current.showMessages();
                        setForceUpdate(forceUpdate + 1);
                      }
                    }
                    simpleValidator.current.showMessages();
                    setForceUpdate(forceUpdate + 1);
                  };
                }
                }
              >
                Withdraw
              </button>
            </>
          )}
        </div>
      )}
      {loader && <LoaderScreen />}
      {activeIndex === 1 && <Code
        state={state}
        handleCode={handleCode}
        codeScreenHandler={codeScreenHandler}
        userStatus={userStatus}
      />}
      {activeIndex === 2 && <Successfully />}
    </>
  );
};

export default Withdraw;
