import React, { useEffect, useRef } from 'react';
import useDarkMode from 'use-dark-mode';

let tvScriptLoadingPromise;

export default function TradingViewWidget({ slug }) {
    const onLoadScriptRef = useRef();
    const darkMode = useDarkMode(false);

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_8c5b4') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        autosize: true,
                        symbol: slug,
                        interval: "D",
                        // timezone: "Etc/UTC",
                        // theme: "dark",
                        theme: darkMode.value ? 'dark' : 'light',
                        style: "1",
                        locale: "en",
                        enable_publishing: false,
                        allow_symbol_change: true,
                        container_id: "tradingview_8c5b4",
                        "width": "100%",
                        "height": "100%",
                    });
                }
            }
        },
        [slug]
    );

    return (
        <div className='tradingview-widget-container'>
            <div id='tradingview_8c5b4' />
        </div>
    );
}
