import React, { useState } from "react";
import cn from "classnames";
import { NavLink, Link, useLocation } from "react-router-dom";
import styles from "./Order.module.sass";
import Icon from "../Icon";
import Modal from "../Modal";
import Withdraw from "../Withdraw";
import Transfer from "../Transfer";

const navigation = [
  // {
  //   title: "Overview",
  //   color: "#23262F",
  //   url: "/order-overview",
  //   separator: true,
  // },
  {
    title: "Open Orders",
    color: "#58BD7D",
    url: "/open-orders-list",
  },
  {
    title: "Orders History",
    color: "#FF6838",
    url: "/orders-history",
    separator: true,
  },
];

const Order = ({ className, children, userAuth }) => {
  const { pathname } = useLocation();
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);

  const activeItem = navigation.find((x) => pathname.includes(x.url));

  return (
    <>
      <div className={cn(className, styles.wallet)}>
        <div className={styles.sidebar}>
          <div className={cn(styles.group, { [styles.active]: visibleMenu })}>
            <div
              className={styles.top}
              onClick={() => setVisibleMenu(!visibleMenu)}
            >
              <div
                className={styles.bg}
                style={{ backgroundColor: activeItem.color }}
              ></div>
              {activeItem.title}
            </div>
            <div className={styles.menu}>
              {navigation.map((item, index) =>
                item.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.separator]: item.separator,
                    })}
                    activeClassName={styles.active}
                    to={item.url}
                    key={index}
                  >
                    {item.color && (
                      <div
                        className={styles.bg}
                        style={{ backgroundColor: item.color }}
                      ></div>
                    )}
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </NavLink>
                ) : (
                  <button
                    className={cn(styles.item, {
                      [styles.separator]: item.separator,
                    })}
                    key={index}
                    onClick={() => setVisibleTransfer(!visibleTransfer)}
                  >
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </button>
                )
              )}
            </div>
          </div>
          <div className={styles.btns}>
            <Link
              className={cn("button button-small", styles.button)}

            // to={userAuth.kycstatus == process.env.REACT_APP_KYC_STATUS_VERIFIED ? '/buy-crypto': 'kyc-verification'} 
            to={'/buy-crypto'}
            >
              Buy Crypto
            </Link>
            <Link
              className={cn("button button-small button-red", styles.button)}
              to={'/sell-crypto'}
            //  to={userAuth.kycstatus == process.env.REACT_APP_KYC_STATUS_VERIFIED ? '/sell-crypto': 'kyc-verification'} 
            >
              Sell Crypto
            </Link>
            {/* <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={() => setVisibleWithdraw(!visibleWithdraw)}
            >
              Sell Crypto
            </button> */}
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={() => setVisibleTransfer(!visibleTransfer)}
            >
              Transfer
            </button>
          </div>
        </div>
        <div className={styles.wrapper}>{children}</div>
      </div>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw />
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <Transfer />
      </Modal>
    </>
  );
};

export default Order;
