import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";

const Item = ({ className, item }) => {
  return (
    <Link
      className={cn(className, styles.item)}
      to={item.url}
    >
      <div className={styles.preview}>
        <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Item" />
      </div>
      <div className={styles.details}>

        <h4 className={cn("h4", styles.title)}>{item.title}</h4>
        <div className={styles.currency}>{item.currency}</div>
        <div className={styles.content}>{item.content}</div>
      </div>

    </Link>
  );
};

export default Item;
