import {
    GET_COINS_FAILED,
    GET_COINS_REQUEST,
    GET_COINS_SUCCESS,
    GET_EMPTY_COINS
} from "../actions/getCoins/actionType";

const initialState = {
    coinsLoading: false,
    coinsError: "",
    coinsData: [],
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COINS_REQUEST:
            return {
                ...state,
                coinsLoading: true,
                coinsError: "",
                coinsData: [],
            };

        case GET_COINS_SUCCESS:
            return {
                ...state,
                coinsLoading: false,
                coinsData: action.payload,
            };

        case GET_COINS_FAILED:
            return {
                ...state,
                coinsLoading: false,
                coinsError: action.message,
            };
        case GET_EMPTY_COINS:
            return {
                state: null
            };
        default:
            return state
    };
};

export default reducer;