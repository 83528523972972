import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import TextInput from "../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import Checkbox from "../../../../components/Checkbox";
import { addNotification } from "../../../../components/Notification";

const Successfully = ({
  setStakeAmount,
  stakeAmount,
  setAgreement,
  agreement,
  addStakeAmountHandler,
  availableAmount
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();

  const submitHandler = (amount) => {
    if (simpleValidator.current.allValid()) {
      if (parseFloat(amount) > 0) {
        if (parseFloat(amount) > parseFloat(availableAmount)) {
          addNotification({
            title: "Alert",
            message: "Insufficient balance",
            type: "danger",
          });
        }
        else {
          addStakeAmountHandler(amount);
        }
      }
      else {
        addNotification({
          title: "Alert",
          message: "Please enter amount greater than 0.",
          type: "danger",
        });
      }
    }
    else {
      simpleValidator.current.showMessages();
      setForceUpdate(1);
    }
  };

  // const submitHandler = (amount) => {
  //   if (simpleValidator.current.allValid()) {
  //     if (parseFloat(amount) === 0) {
  //       addNotification({
  //         title: "Alert",
  //         message: "Please enter amount greater than 0.",
  //         type: "danger",
  //       });
  //     }
  //     else if (parseFloat(amount) > parseFloat(availableAmount)) {
  //       addNotification({
  //         title: "Alert",
  //         message: "Insufficient balance",
  //         type: "danger",
  //       });
  //     }
  //     else {
  //       addStakeAmountHandler(amount);
  //     }
  //   } else {
  //     simpleValidator.current.showMessages();
  //     setForceUpdate(1);
  //   }
  // };

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h4", styles.title)}>
          Stake BDNX!
        </div>
        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="Staking Amount"
            name="stakeAmount"
            value={stakeAmount}
            type="text"
            placeholder="Enter a value"
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setStakeAmount(e.target.value)
              }
            }}
            onBlur={() => {
              simpleValidator.current.showMessageFor("stakeAmount");
            }}
          />
        </div>
        <span className={styles.danger}>{simpleValidator.current.message("amount", stakeAmount, "required")}</span>
        <div className={styles.availableAmount}>
          <span>
            Available: {parseInt(availableAmount).toLocaleString()} <small>BDNX</small>
          </span>
        </div>
        {/* <div className={styles.availableAmount}>
          <span>
            Enter a multiple of 100 BDNX to stake (min: 100 BDNX).
          </span>
        </div> */}
        {/* <div className={styles.stakeContent}>
          <div>
            • The staked BDNX cannot be used for any other purpose. You may submit a request to redeem your BDNX.</div>
          <div>
            • Instant redemption: Staked BDNX will be credited within 10 minutes after paying a 2.00% fee.
          </div>
          <div>
            • Scheduled redemption: Staked BDNX will be credited within 8 day at zero fees.
          </div>
        </div> */}
        {/* <div className={styles.container}>
          <Checkbox
            className={styles.checkbox}
            content="I acknowledge and agree that 2.00% of the principal will be deducted in express redemption transaction fees."
            value={acknowledge}
            onChange={() => {
              setAcknowledge(!acknowledge);
              simpleValidator.current.showMessageFor("acknowledge");
            }}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("acknowledge", acknowledge, "accepted")}
          </span>
        </div> */}
        <div className={styles.container}>
          <Checkbox
            className={styles.checkbox}
            // content="I have read and agree to the Bitdenex BDNX Staking User Agreement"
            content={`I have read and agree to the <a href="/legal-center" target="_blank" rel="noopener noreferrer" class="${styles.link}">Bitdenex Staking User Agreement.</a>`}
            value={agreement}
            onChange={() => {
              setAgreement(!agreement);
              simpleValidator.current.showMessageFor("agreement");
            }}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("agreement", agreement, "accepted")}
          </span>
        </div>
        <div className={styles.info}>
          <button
            className={cn("button", styles.button)}
            onClick={() => submitHandler(stakeAmount)}
          >
            Stake
          </button>
        </div>
      </div>

    </>
  );
};

export default Successfully;
