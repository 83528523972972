import { Fragment, h } from 'preact'
import { memo } from 'preact/compat'
import style from './CameraPanel.scss'

export const CameraPanel = memo(() => {
  return (
    <div>
      {['canvasSegments', 'canvasHough', 'canvasCanny', 'canvasCapture'].map(
        (name) => (
          <Fragment key={name}>
            <h4>{name}</h4>
            <canvas id={name} style={style.debugCanvas} />
          </Fragment>
        )
      )}
    </div>
  )
})
