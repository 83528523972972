import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import { Link } from "react-router-dom";
import Notice from "./Notice";

const items = [
  {
    title: "Create Account",
    image2x: "/images/content/step-1@2x.png",
    image: "/images/content/step-1.png",
    content:
      "KYC-checks required.",
  },
  {
    title: "Link your bank account",
    image2x: "/images/content/step-2@2x.png",
    image: "/images/content/step-2.png",
    content:
      "Bank account verification.",
  },
  {
    title: "Start buying & selling",
    image2x: "/images/content/step-3@2x.png",
    image: "/images/content/step-3.png",
    content:
      "Place your first Order.",
  },
  {
    title: "Earn money",
    image2x: "/images/content/step-4@2x.png",
    image: "/images/content/step-4.png",
    content:
      "Become smart trader to earn.",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h3", styles.title)}>How it works</h2>
          <div className={styles.info}>
            Bitdenex supports a variety of the most popular cryptocurrencies. Get the benefits of our LOW Fees and simple to use Exchange.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.preview}>
                <img
                  srcSet={`${x.image2x} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </div>
          ))}
        </div>
        <div className={styles.details}>
          <Link className={cn("button", styles.button)} to={'exchange/BTC-SRD'}>
            Start Earning Today
          </Link>
        </div>
        <Notice />
      </div>
    </div>
  );
};

export default Steps;
