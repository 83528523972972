// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-DocumentAuto-DevTools-devTools{z-index:10;background:#a9a9a9;position:fixed;top:0;right:0;display:-webkit-box;display:-webkit-flex;display:flex;gap:24px}.onfido-sdk-ui-DocumentAuto-DevTools-panel{border-top:solid 3px #000;z-index:100;max-height:50%;background:#a9a9a9;overflow-y:scroll;position:fixed;text-align:left;bottom:0;left:0;right:0}.onfido-sdk-ui-DocumentAuto-DevTools-icon{width:36;height:36}.onfido-sdk-ui-DocumentAuto-DevTools-buttonRow{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-around;justify-content:space-around;margin-bottom:2%}.onfido-sdk-ui-DocumentAuto-DevTools-resultRow{font-size:x-small}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"devTools": "onfido-sdk-ui-DocumentAuto-DevTools-devTools",
	"panel": "onfido-sdk-ui-DocumentAuto-DevTools-panel",
	"icon": "onfido-sdk-ui-DocumentAuto-DevTools-icon",
	"buttonRow": "onfido-sdk-ui-DocumentAuto-DevTools-buttonRow",
	"resultRow": "onfido-sdk-ui-DocumentAuto-DevTools-resultRow"
};
export default ___CSS_LOADER_EXPORT___;
