import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";
import WalletSkeleton from "../../../components/Skeleton/WalletSkeleton";
import CustomDropdown from "../../../components/CustomDropdown";

const Funds = ({ statusText, setStatusText, statusValue, setStatusValue,
  statusOptions, children, cashbackListData, handleLoadMore, pageNo, loading }) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <button className={styles.link}>
          <span>Cashback & Rewards List</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.dropdown}>
          <CustomDropdown
            className={styles.dropdown}
            label=""
            value={statusValue}
            setValue={setStatusValue}
            text={statusText}
            setText={setStatusText}
            options={statusOptions}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Currency</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Order Id</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Date</div>
        </div>
        {
          loading ? <WalletSkeleton rowCount={10} colCount={6} /> :
            cashbackListData?.length > 0 ?
              <>
                {cashbackListData?.map((x, index) => {
                  return (
                    <Item
                      className={styles.item}
                      item={x}
                      key={index}
                      children={children}
                      statusValue={statusValue}
                    />
                  )
                })}
                {cashbackListData?.length === (10 * pageNo) && <div className={styles.customButton}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                    <span>Load more...</span>
                  </button>
                </div>
                }
              </>
              :
              <div className={styles.btns}>
                <NoDataFound />
              </div>
        }
      </div>
    </div>
  );
};

export default Funds;
