import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../components/helper"

const Item = ({ item, index, statusValue, setItemData, setVisibleModal, activateCouponHandler, userStatus }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.couponCard}>
          <div className={styles.couponHeader}>
            <h3>
              {statusValue === parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING) ?
                item?.amount
                :
                `${item?.symbol} ${item?.amount}`
              }
            </h3>
            <h4>
              Remaining: {statusValue !== parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING) ?
                `${item.symbol} ${getDigitsAfterDecimal(item?.pending_amount, 8)}` : "---"}
            </h4>
            <h4>
              Exchange: {statusValue !== parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING) ?
                item.name : "---"
              }
            </h4>
          </div>
          {
            (statusValue === parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING)) &&
            ((statusValue === parseInt(process.env.REACT_APP_COUPON_ACTIVATION_PENDING) && userStatus?.switchRegion === true) ?
              <div className={styles.couponContent}>
                <button className={cn("button-small", styles.flexChildButton)}
                  onClick={() => { setVisibleModal(true); setItemData(item); }}>Activate</button>
              </div>
              :
              <div className={styles.couponContent}>
                <button className={cn("button-small", styles.flexChildButton)}
                  onClick={() => { activateCouponHandler(item?.id, item?.coupon_id, process.env.REACT_APP_INTERNATIONAL_MARKETS) }}>Activate</button>
              </div>
            )
          }
          <div className={styles.couponFooter}>
            <span>This coupon will expire on {toLocaleStringDateFormat(item?.expireDate)}.</span>
          </div>
        </div>
      </div >
    </>
  );
};

export default Item;
