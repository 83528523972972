import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Item = ({ item, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.info}>{item?.from_module?.replace(/_/g, ' ')?.toUpperCase()}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item?.to_module?.replace(/_/g, ' ')?.toUpperCase()}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item?.amount}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item?.symbol?.toUpperCase()}</div>
          </div>
          <div className={styles.col}>
            <div className={cn("category-blue", styles.details)}>{item?.status}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{toLocaleStringDateFormat(item?.createdAt)}</div>
          </div>
        </div>
        <div className={styles.btns}>{children}</div>
      </div>
    </>
  );
};

export default Item;
