// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ProofOfAddress-PoAIntro-content{text-align:initial;margin:0 3em auto}@media(max-width: 479px){.onfido-sdk-ui-ProofOfAddress-PoAIntro-content{margin:0 0 auto}}[dir=\"ltr\"] .onfido-sdk-ui-ProofOfAddress-PoAIntro-list{padding-left:1em}[dir=\"rtl\"] .onfido-sdk-ui-ProofOfAddress-PoAIntro-list{padding-right:1em}.onfido-sdk-ui-ProofOfAddress-PoAIntro-requirements{font-weight:600;color:var(--osdk-color-content-body);margin-bottom:1em}.onfido-sdk-ui-ProofOfAddress-PoAIntro-requirement{display:list-item;padding-bottom:1em}.onfido-sdk-ui-ProofOfAddress-PoAIntro-requirement::marker{color:var(--osdk-color-background-alert-info)}.onfido-sdk-ui-ProofOfAddress-PoAIntro-bolder{display:inline-block;font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"content": "onfido-sdk-ui-ProofOfAddress-PoAIntro-content",
	"list": "onfido-sdk-ui-ProofOfAddress-PoAIntro-list",
	"requirements": "onfido-sdk-ui-ProofOfAddress-PoAIntro-requirements",
	"requirement": "onfido-sdk-ui-ProofOfAddress-PoAIntro-requirement",
	"bolder": "onfido-sdk-ui-ProofOfAddress-PoAIntro-bolder"
};
export default ___CSS_LOADER_EXPORT___;
