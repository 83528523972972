import { ComponentChildren, Fragment, h } from 'preact'
import { useState } from 'react'
import { CameraPanel } from './CameraPanel'
import { ParametersPanel, ParametersPanelProps } from './ParametersPanel'
import { InfoPanel, InfoPanelProps } from './InfoPanel'
import { Icon } from '@onfido/castor-react'
import { IconName } from '@onfido/castor-icons'
import style from './DevTools.scss'

export const panelStyle = {
  zIndex: 100000,
  background: 'antiquewhite',
  overflowY: 'scroll',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
}

export type PanelProps = {
  visible: boolean
  children: ComponentChildren
}

export const Panel = ({ children, visible }: PanelProps) => (
  <div
    className={style.panel}
    style={{ visibility: visible ? 'initial' : 'hidden' }}
  >
    {children}
  </div>
)

type DevToolsProps = {
  onStart: () => void
  onReset: () => void
} & ParametersPanelProps &
  InfoPanelProps

export const DevTools = ({
  stateValue,
  edgeDetection,
  params,
  onParams,
  video,
  stream,
  frame,
  onStart,
  onReset,
}: DevToolsProps) => {
  const [currentPanel, setCurrentPanel] = useState<string>('')
  return (
    <Fragment>
      <div className={style.devTools}>
        <Icon
          className={style.icon}
          style={{
            color: stateValue !== 'idle' ? 'white' : 'initial',
          }}
          aria-label={'Play'}
          name={'play'}
          onClick={() => onStart()}
        />
        <Icon
          className={style.icon}
          aria-label={'Reset'}
          name={'reset'}
          onClick={() => onReset()}
        />
        <Icon
          className={style.icon}
          style={{
            color: 'camera' === currentPanel ? 'white' : 'initial',
          }}
          aria-label={'camera'}
          name={'camera' as IconName}
          onClick={() =>
            setCurrentPanel('camera' === currentPanel ? '' : 'camera')
          }
        />
        <Icon
          className={style.icon}
          style={{
            color: 'parameters' === currentPanel ? 'white' : 'initial',
          }}
          aria-label={'parameters'}
          name={'cog' as IconName}
          onClick={() =>
            setCurrentPanel('parameters' === currentPanel ? '' : 'parameters')
          }
        />
        <Icon
          className={style.icon}
          style={{
            color: 'info' === currentPanel ? 'white' : 'initial',
          }}
          aria-label={'info'}
          name={'info-circle' as IconName}
          onClick={() => setCurrentPanel('info' === currentPanel ? '' : 'info')}
        />
        <Icon
          key={'copy'}
          className={style.icon}
          style={{
            color: 'initial',
          }}
          aria-label={'copy'}
          name={'copy'}
          onClick={async () => {
            await navigator.clipboard.writeText(window.location.toString())
          }}
        />
      </div>

      <Panel key={'camera'} visible={currentPanel === 'camera'}>
        <CameraPanel />
      </Panel>
      <Panel key={'parameters'} visible={currentPanel === 'parameters'}>
        <ParametersPanel params={params} onParams={onParams} />
      </Panel>
      <Panel key={'info'} visible={currentPanel === 'info'}>
        <InfoPanel
          frame={frame}
          stateValue={stateValue}
          video={video}
          stream={stream}
          edgeDetection={edgeDetection}
        />
      </Panel>
    </Fragment>
  )
}
