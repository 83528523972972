// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ActiveVideo-Intro-intro{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;width:100%}.onfido-sdk-ui-ActiveVideo-Intro-introVideo{width:140px;height:140px;min-width:140px;min-height:140px}.onfido-sdk-ui-ActiveVideo-Intro-wrapperBody{-webkit-box-pack:justify !important;-webkit-justify-content:space-between !important;justify-content:space-between !important}.onfido-sdk-ui-ActiveVideo-Intro-list{padding:0;margin:0 0 32px;width:100%;row-gap:8px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;text-align:left}.onfido-sdk-ui-ActiveVideo-Intro-list .onfido-sdk-ui-ActiveVideo-Intro-listItem{list-style:none}.onfido-sdk-ui-ActiveVideo-Intro-list .onfido-sdk-ui-ActiveVideo-Intro-listItem .onfido-sdk-ui-ActiveVideo-Intro-text{font-family:inherit;font-size:1rem;font-weight:400;line-height:1.5rem;text-transform:none;color:var(--osdk-color-content-body);display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center}[dir=\"ltr\"] .onfido-sdk-ui-ActiveVideo-Intro-list .onfido-sdk-ui-ActiveVideo-Intro-listItem .onfido-sdk-ui-ActiveVideo-Intro-text::before{margin-right:16px}[dir=\"rtl\"] .onfido-sdk-ui-ActiveVideo-Intro-list .onfido-sdk-ui-ActiveVideo-Intro-listItem .onfido-sdk-ui-ActiveVideo-Intro-text::before{margin-left:16px}.onfido-sdk-ui-ActiveVideo-Intro-list .onfido-sdk-ui-ActiveVideo-Intro-listItem .onfido-sdk-ui-ActiveVideo-Intro-text::before{display:inline-block;content:\"\";width:6px;height:6px;min-width:6px;min-height:6px;border-radius:50%;background:rgba(var(--ods-color-primary-500), 1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro": "onfido-sdk-ui-ActiveVideo-Intro-intro",
	"introVideo": "onfido-sdk-ui-ActiveVideo-Intro-introVideo",
	"wrapperBody": "onfido-sdk-ui-ActiveVideo-Intro-wrapperBody",
	"list": "onfido-sdk-ui-ActiveVideo-Intro-list",
	"listItem": "onfido-sdk-ui-ActiveVideo-Intro-listItem",
	"text": "onfido-sdk-ui-ActiveVideo-Intro-text"
};
export default ___CSS_LOADER_EXPORT___;
