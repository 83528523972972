import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ item }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} >
          <div className={styles.col}>
            {item?.amount}
          </div>
          <div className={styles.col}>
            {item?.min_deposit}
          </div>
          <div className={styles.col}>
            {item?.validity_after_activate}
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
