import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";

const ConfirmationModal = ({
  setShowPopup,
  primaryBankHandler,
  accountNumber
}) => {

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h3", styles.title)}>
          Confirmation!
        </div>
        <div className={styles.info}>
          Are you sure you want to select this bank as primary?
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-small button-red", styles.button)}
            onClick={() => {
              setShowPopup(false);
            }}
          >
            No
          </button>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={() => {
              setShowPopup(false);
              primaryBankHandler(accountNumber);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
