import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";


const Form = ({ changePasswordHandler }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator(
    {
      messages: {
        in: "New Password and Confirm Password should be matched!"
      }
    },
  ));
  const [forceUpdate, setForceUpdate] = useState();

  return (
    <form className={styles.form}>
      <div className={cn("h3", styles.title)}>New password</div>
      <TextInput
        className={styles.field}
        label="old password"
        name="old-password"
        type="password"
        placeholder="Old Password"
        value={oldPassword}
        onChange={(e) => { setOldPassword(e.target.value) }}
      />
      <span className={styles.danger}>
        {simpleValidator.current.message("old password", oldPassword, `required|min:9`)}
      </span>
      <TextInput
        className={styles.field}
        label="new password"
        name="new-password"
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => { setNewPassword(e.target.value) }}
      />
      <span className={styles.danger}>
        {simpleValidator.current.message("new password", newPassword, `required|min:9`)}
      </span>
      <TextInput
        className={styles.field}
        label="confirm password"
        name="confirm-password"
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => { setConfirmPassword(e.target.value) }}
      />
      <span className={styles.danger}>
        {simpleValidator.current.message("Confirm password", confirmPassword, `required|in:${newPassword}`)}
      </span>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            changePasswordHandler(oldPassword, newPassword, confirmPassword);
          } else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >
        Change password
      </button>
    </form>
  );
};

export default Form;
