import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Player from "../../../components/Player";

const breadcrumbs = [
  {
    title: "Learn crypto",
    url: "/learn-crypto",
  },
  {
    title: "Trading",
    url: "/learn-crypto",
  }
];

const Main = ({ blog }) => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>{blog ? blog.title : null}</h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
        <Player
          className={styles.player}
          image={blog?.imageBlog}
        />
      </div>
    </div>
  );
};

export default Main;
