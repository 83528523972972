import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Exchange.module.sass";
import Main from "./Main";
//import Trades from "./Trades";
import Balance from "./Balance";
import Currency from "./Currency";
import Table from "./Table";
import Actions from "./Actions";
import Charts from "./Charts";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
// import { io } from 'socket.io-client';
import Successfully from "./Successfully";
import Modal from '../../components/Modal'
import { addNotification } from "../../components/Notification";
import requestHandler from "../../actions/httpClient";
import { userStatusCreator } from "../../actions/getUserStatus";
import { socket } from "../../socket";
import MarketScroller from "../../components/MarketScroller";

const navigation = ["Chart", "Order books", "Trades"];

const Exchange = () => {

  const dispatch = useDispatch();
  const { slug } = useParams();

  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [marketLoading, setMarketLoading] = useState(false)
  const [cancelOrder, setCancelOrder] = useState([])
  const [socketData, setSocketData] = useState([])

  const [buyOrdersData, setBuyOrdersData] = useState([])
  const [sellOrdersData, setSellOrdersData] = useState([])

  const [marketTrades, setMarketTrades] = useState([])
  const [currentMarketDetails, setCurrentMarketDetails] = useState([])
  const [openOrders, setOpenOrders] = useState([])
  const [exchangeData, setExchangeData] = useState([])

  const [orderActionResponse, setOrderActionResponse] = useState([])
  const [allMarketsData, setAllMarketsData] = useState([]);
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const [currentMarketPrice, setCurrentMarketPrice] = useState();
  const [dataMarkets, setDataMarkets] = useState([]);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [favoriteMarkets, setFavoriteMarkets] = useState(userStatus?.userFavMarkets?.length > 0 ? userStatus?.userFavMarkets : []);
  const [scrollerMarkets, setScrollerMarkets] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!flag && dataMarkets?.length > 0) {
      setScrollerMarkets([...dataMarkets]);
      setFlag(true);
    }
  }, [dataMarkets, flag]);

  useEffect(() => {
    if (userStatus.length === 0) {
      dispatch(userStatusCreator())
    }

    // getRegion();

  }, [])

  useEffect(() => {
    createOrdersData(slug);
    // sockets connection  
    socket.connect();
    socket.on("connect", () => {
    });
    socket.on("suriname_markets_web", (data) => {
      const sortedMarkets = data[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
      setDataMarkets(sortedMarkets);
    });
    socket.on('orderBook_' + slug.toLowerCase(), function (order) {
      sortOrdersData(order)
    });
    socket.on('orderBookPrice_' + slug.toLowerCase(), function (order) {
      setCurrentMarketPrice(order);
    });

    return () => {
      socket.off("suriname_markets_web");
      socket.off("orderBook_" + slug.toLowerCase());
      socket.off("orderBookPrice_" + slug.toLowerCase());
      socket.disconnect();
    }
  }, [slug, orderActionResponse])

  useEffect(() => {
    createOrdersData(slug);
  }, [slug, orderActionResponse])

  const createOrdersData = async (slug) => {
    setCurrentMarketPrice();
    try {
      setLoading(true);
      const formData = {
        market_slug: slug,
        signature: localStorage.getItem("signature")
      }
      const ordersDataPayload = await requestHandler("getOpenOrders", "post", formData, 'jwt_token');
      setExchangeData(ordersDataPayload)

      setMarketTrades(ordersDataPayload.data.data.market_trades)
      setOpenOrders(ordersDataPayload.data.data.open_order)
      setCurrentMarketDetails(ordersDataPayload.data.data.current_market_details)

      setBuyOrdersData(createOrderbook(ordersDataPayload.data.data.buy))
      setSellOrdersData(createOrderbook(ordersDataPayload.data.data.sell))

      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  }

  const createOrderbook = (orders) => {
    let createOrders = [];
    let tempData = [];
    for (let order of orders) {
      if (tempData.indexOf(parseFloat(order.fiatPrice)) < 0) {
        createOrders.push(order)
        tempData.push(parseFloat(order.fiatPrice));
      } else {
        let index = tempData.indexOf(parseFloat(order.fiatPrice));
        createOrders[index].remcrypto = parseFloat(order.remcrypto) + parseFloat(createOrders[index].remcrypto);
        createOrders[index].remfiat = parseFloat(order.remfiat) + parseFloat(createOrders[index].remfiat);
      }
    }
    return createOrders;
  }

  const sortOrdersData = (order) => {
    if (order && order.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER)) {
      if (order.orderAction === "new_order")
        setBuyOrdersData(buyOrdersData => createOrderbook([...buyOrdersData.filter(buyOrder => buyOrder.orderId !== order.orderId), order]));
      else
        setBuyOrdersData(buyOrdersData => createOrderbook([...buyOrdersData.filter(buyOrder => buyOrder.orderId !== order.orderId)]));
    } else {
      if (order.orderAction === "new_order")
        setSellOrdersData(sellOrdersData => createOrderbook([...sellOrdersData.filter(sellOrder => sellOrder.orderId !== order.orderId), order]));
      else
        setSellOrdersData(sellOrdersData => createOrderbook([...sellOrdersData.filter(sellOrder => sellOrder.orderId !== order.orderId)]));
    }
  }

  const getRegion = async () => {
    try {
      const regionPayload = await requestHandler("region", "post");
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == process.env.REACT_APP_SURINAME_MARKETS) {
          getData(regionPayload?.data?.data[i]?.id);
        }
      }

    }
    catch (e) {
    };
  };

  const getData = async (id) => {
    try {
      setMarketLoading(true);
      let data = {
        type: "allMarkets",
        region: id,
        signature: localStorage.getItem('signature')

      };
      const marketsDataPayload = await requestHandler("get_markets", "post", data, "jwt_token");
      const sortedMarkets = marketsDataPayload?.data?.data[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
      setAllMarketsData(marketsDataPayload?.data?.data);
      setMarketLoading(false);
      setDataMarkets(sortedMarkets);
    }
    catch (e) {
      setMarketLoading(false);
    };
  }

  const orderHandler = (x) => {
    setCancelOrder(x);
    setVisible(true)
  }

  const clearSocketData = () => {
    setSocketData(null)
  }

  const orderResponse = (response) => {
    setOrderActionResponse(response)
  }

  return (
    <div className={styles.exchange}>
      <div className={styles.marketScroll}>
        {scrollerMarkets?.length > 0 && (
          <MarketScroller markets={scrollerMarkets} />
        )}
      </div>
      <div className={cn("containerEx", styles.containerEx)}>
        <Main currentMarketDetails={currentMarketDetails} currentMarketPrice={currentMarketPrice} />
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {isTablet ? (
          <>
            <Actions currentMarketPrice={currentMarketPrice} currentMarketDetails={currentMarketDetails} exchangeData={exchangeData} orderResponse={orderResponse} />
            {activeIndex === 0 && (
              <div className={styles.box}>
                <Charts currentMarketDetails={currentMarketDetails} slugTV={slug} slug={slug.replace("-", "").toUpperCase()} />
                <Table openOrders={openOrders} marketTrades={marketTrades} orderHandler={orderHandler} />
              </div>
            )}
            {activeIndex === 1 && (
              <div className={styles.box}>
                <Balance currentMarketPrice={currentMarketPrice} buy={buyOrdersData} sell={sellOrdersData} currentMarketDetails={currentMarketDetails} socketData={socketData} clearSocketData={clearSocketData} />
              </div>
            )}
            {activeIndex === 2 && (
              <div className={styles.box}>
                <Currency
                  favoriteMarkets={favoriteMarkets}
                  setFavoriteMarkets={setFavoriteMarkets}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.col}>
                <Balance currentMarketPrice={currentMarketPrice} buy={buyOrdersData} sell={sellOrdersData} currentMarketDetails={currentMarketDetails} socketData={socketData} clearSocketData={clearSocketData} loading={loading} />
              </div>
              <div className={styles.col}>
                <Charts currentMarketDetails={currentMarketDetails} slugTV={slug} slug={slug.replace("-", "").toUpperCase()} />
                <Actions currentMarketPrice={currentMarketPrice} currentMarketDetails={currentMarketDetails} exchangeData={exchangeData} orderResponse={orderResponse} />
              </div>
              <div className={styles.col}>
                <Currency
                  allMarketsData={allMarketsData}
                  marketLoading={marketLoading}
                  dataMarkets={dataMarkets}
                  setDataMarkets={setDataMarkets}
                  favoriteMarkets={favoriteMarkets}
                  setFavoriteMarkets={setFavoriteMarkets}
                />
              </div>
            </div>
            <Table openOrders={openOrders} marketTrades={marketTrades} orderHandler={orderHandler} amountDecimal={currentMarketDetails[0]?.amountDecimalPrecision} priceDecimal={currentMarketDetails[0]?.priceDecimalPrecision} />
          </>
        )}

        <Modal
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <Successfully
            item={cancelOrder}
            cancelOrderLoading={cancelOrderLoading}
            closeModal={() => setVisible(false)}
            cancelOrder={async (orderId) => {
              try {
                setCancelOrderLoading(true)
                const data = {
                  signature: localStorage.getItem("signature"),
                  orderId: orderId
                }
                const payload = await requestHandler('cancelOrder', 'post', data, 'jwt_token')
                if (payload.status === 200) {
                  setCancelOrderLoading(false)
                  addNotification({
                    title: 'success', message: 'Order cancelled successfully'
                    , type: 'success'
                  })
                  dispatch(userStatusCreator())
                  setVisible(false)
                  setOrderActionResponse(payload)
                }


              } catch (error) {
                addNotification({
                  title: 'error', message: error?.data?.message[0]
                  , type: 'danger'
                })
                setVisible(false)
              }

            }
            }

          />
        </Modal>
      </div>
    </div>
  );
};

export default Exchange;
