// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-ActiveVideo-Header-header{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;text-align:center}.onfido-sdk-ui-ActiveVideo-Header-title{font-size:var(--osdk-font-size-title);color:var(--osdk-color-content-title);font-family:var(--osdk-font-family-title);font-weight:var(--osdk-font-weight-title);margin:32px 0;line-height:1.34}.onfido-sdk-ui-ActiveVideo-Header-title:not(:last-child){margin-bottom:8px}.onfido-sdk-ui-ActiveVideo-Header-subtitle{font-family:inherit;font-size:1rem;font-weight:400;line-height:1.5rem;text-transform:none;color:rgba(var(--ods-color-neutral-600), 1);margin:0 0 32px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "onfido-sdk-ui-ActiveVideo-Header-header",
	"title": "onfido-sdk-ui-ActiveVideo-Header-title",
	"subtitle": "onfido-sdk-ui-ActiveVideo-Header-subtitle"
};
export default ___CSS_LOADER_EXPORT___;
