import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

// const items = [
//   {
//     title: "Fiat and Spot balance",
//     content: "0.27894652",
//     currency: "BTC",
//     price: "$10,098.36",
//   },
//   {
//     title: "Spot balance",
//     content: "0.13894652",
//     currency: "BTC",
//     price: "$10,098.36",
//   },
//   {
//     title: "Fiat balance",
//     content: "0.18564652",
//     currency: "BTC",
//     price: "$10,098.36",
//   },
// ];
const Main = ({ meta }) => {
  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>Orders Overview</h4>
      <div className={styles.list}>
        <div className={styles.item} >
          {/* <div className={styles.info}>Total Orders</div> */}
          <div className={styles.line}>
            <div className={styles.currency}>{meta?.totalCount}</div>

          </div>
          {/* <div className={styles.price}>{x.price}</div> */}
        </div>
      </div>
    </div>
  );
};

export default Main;
