import React, { useEffect, useState } from "react";
import Education from "./Education";
import Main from "./Main";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const LegalCenter = () => {
  const [loading, setLoading] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [cashbackData, setCashbackkData] = useState([]);

  useEffect(() => {
    const getCustomerVerifictionStatusHandler = async () => {
      try {
        let data = {
          signature: localStorage.getItem("signature"),
        };
        const payload = await requestHandler('getCustomerVerifictionStatus', 'post', data, 'jwt_token')
        getReferralList(payload?.data?.data?.id);
      }
      catch (error) {
      };
    };
    getCustomerVerifictionStatusHandler();
    getLevelDataHandler();
  }, []);

  const getReferralList = async (id) => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        userId: id,
      };
      const getReferralPayload = await requestHandler("getRefferal", "post", data, "jwt_token");
      setReferralData(getReferralPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      // addNotification({
      //   title: "Error",
      //   message: e?.data?.message[0]?.msg,
      //   type: "danger"
      // });
    };
  };

  const getLevelDataHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    }
    try {
      const getLevelPayload = await requestHandler("rewardsInfo", "post", data, "jwt_token");
      setLoading(false);
      setLevelData(getLevelPayload?.data?.data?.level_info);
      setCashbackkData(getLevelPayload?.data?.data?.referral_cashback);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Main
        levelData={levelData}
      />
      <Education
        loading={loading}
        referralData={referralData}
        levelData={levelData}
        cashbackData={cashbackData}
      />
    </>
  );
};

export default LegalCenter;
