import { Rect, Rectangle } from './Geometrie'
import { DetectionParams } from './DetectionParams'
import { drawLine, drawRectangle, drawSelectedSegments } from './Debug'
import { CV, Mat, Point } from '@onfido/opencv'
import { cropImage, resizeImage } from './Resize'

export type ComputedParams = {
  scaledOverlayCoordinates: Rect // the coordinates of the overlay, resized to the smaller frame
  scaledInsideMargin: number // the inside margin, rescaled to the smaller frame
  resizedImageCoordinates: Rect // the coordinates of the resized image, in the rescaled image referential
  scaledOffset: number
}

export type EdgeDetectionResult = {
  detected: boolean
  score: number
}

export type EdgeDetectionAnalytics = {
  totalDuration: number // ms
  houghLinesDuration: number
  detectSegmentDuration: number
}

export type DetectSegmentResult = Positioned<EdgeDetectionResult> & {
  totalDetected: number
}

export type EdgeDetectionResults = {
  analytics: EdgeDetectionAnalytics
  segments: DetectSegmentResult
}

type Line = Array<number> // a line contains 4 numbers: x1,y1 (start point) & x2,y2 (endpoint)
type LineBuffer = Positioned<Line[]> // a set that can contain multiple small lines after hough detection

export type Position = 'left' | 'right' | 'bottom' | 'top'
type Positioned<T> = Record<Position, T>

export const getDetectionAreasFromBox = (
  box: Rect,
  insideMargin: number,
  offset: number
): Positioned<{ rect: Rect }> => {
  return {
    left: {
      rect: Rect.fromCoordinates(
        box.left,
        box.top,
        box.left + insideMargin + offset,
        box.bottom
      ),
    },
    right: {
      rect: Rect.fromCoordinates(
        box.right - insideMargin - offset,
        box.top,
        box.right,
        box.bottom
      ),
    },
    top: {
      rect: Rect.fromCoordinates(
        box.left,
        box.top,
        box.right,
        box.top + insideMargin + offset
      ),
    },
    bottom: {
      rect: Rect.fromCoordinates(
        box.left,
        box.bottom - insideMargin - offset,
        box.right,
        box.bottom
      ),
    },
  }
}

type Timed<T> = T & {
  duration: number
}

export const calculateHoughLines = (
  cv: CV,
  params: DetectionParams,
  cp: ComputedParams,
  src: Mat,
  debugHoughLines: Mat | null
): Timed<LineBuffer> => {
  const lines = new cv.Mat()

  cv.HoughLinesP(
    src,
    lines,
    1,
    params.houghLine.theta,
    params.houghLine.threshold,
    params.houghLine.minLineLength,
    params.houghLine.maxLineGap
  )

  const houghLineSelectionStart = Date.now()

  const detectionBoxes = getDetectionAreasFromBox(
    cp.resizedImageCoordinates,
    cp.scaledInsideMargin,
    cp.scaledOffset
  )

  const extendedDetectionBox = Object.fromEntries(
    Object.entries(detectionBoxes).map(([key, x]) => {
      return [key, { ...x, currentFrameLines: new Array<Line>() }]
    })
  )

  for (let i = 0; i < lines.rows; ++i) {
    const startPoint = new cv.Point(
      lines.data32S[i * 4],
      lines.data32S[i * 4 + 1]
    )
    const endPoint = new cv.Point(
      lines.data32S[i * 4 + 2],
      lines.data32S[i * 4 + 3]
    )

    debugHoughLines && drawLine(cv, debugHoughLines, startPoint, endPoint)

    Object.entries(extendedDetectionBox).forEach(
      ([position, { rect, currentFrameLines }]) => {
        if (rect.containsPoint(startPoint) && rect.containsPoint(endPoint)) {
          const angleInDeg = getNormalizedAngleForLine(startPoint, endPoint)
          const referenceAngle = isHorizontalPosition(position as Position)
            ? 0
            : 90

          if (
            Math.abs(angleInDeg - referenceAngle) <
            params.edgeDetection.angleTolerance
          ) {
            currentFrameLines.push([
              startPoint.x,
              startPoint.y,
              endPoint.x,
              endPoint.y,
            ])
          }
        }
      }
    )
  }

  const lineBuffer: LineBuffer = {
    left: [],
    right: [],
    top: [],
    bottom: [],
  }

  Object.entries(extendedDetectionBox).forEach(
    ([key, { currentFrameLines }]) => {
      lineBuffer[key as Position] = currentFrameLines
    }
  )

  lines.delete()

  return { ...lineBuffer, duration: Date.now() - houghLineSelectionStart }
}

export const getNormalizedAngleForLine = (p1: Point, p2: Point) => {
  const { max, min, atan2, PI } = Math

  const angleInRad = atan2(
    max(p1.y, p2.y) - min(p1.y, p2.y),
    max(p1.x, p2.x) - min(p1.x, p2.x)
  )

  return (angleInRad * 360) / (2 * PI)
}

// returns a [0,1] score. Higher the score is, higher the current edge was detected.
const detectSegmentScore = (
  lineSpaceIndex: number[],
  frameBufferLines: Line[],
  horizontal: boolean,
  params: DetectionParams
) => {
  // holds the segments "detected or not" boolean value.
  const lineSpaceArray: boolean[] = new Array(
    params.edgeDetection.numSegment - 1
  ).fill(false)

  for (const line of frameBufferLines) {
    const p1: number = horizontal ? line[0] : line[1]
    const p2: number = horizontal ? line[2] : line[3]
    for (let s = 0; s < lineSpaceIndex.length - 1; s++) {
      const current = lineSpaceIndex[s]
      const next = lineSpaceIndex[s + 1]

      if (
        (p1 > current && p1 < next) ||
        (p2 > current && p2 < next) ||
        (p1 < current && p2 > next) ||
        (p2 < current && p1 > next)
      ) {
        lineSpaceArray[s] = true
      }
    }
  }

  const segments = countBoolean(lineSpaceArray)

  return segments / (params.edgeDetection.numSegment - 1)
}

export const countBoolean = (array: boolean[]) => {
  return array.reduce((sum, current) => sum + (current ? 1 : 0), 0)
}

export const isHorizontalPosition = (p: Position): boolean => {
  return p === 'top' || p === 'bottom'
}

export const detectSegments = (
  cv: CV,
  lb: LineBuffer,
  params: DetectionParams,
  cp: ComputedParams,
  debugSegments: Mat | null
): Timed<DetectSegmentResult> => {
  const start = Date.now()
  const box = cp.scaledOverlayCoordinates

  const segments: Record<Position, number[]> = {
    left: [box.top, box.bottom],
    right: [box.top, box.bottom],
    top: [box.left, box.right],
    bottom: [box.left, box.right],
  }

  const entries = Object.entries(segments).map(([key, value]): [
    PropertyKey,
    EdgeDetectionResult
  ] => {
    const [p1, p2] = value
    const isHorizontal = isHorizontalPosition(key as Position)

    const lineSpaceIndex = lineSpace(p1, p2, params.edgeDetection.numSegment)

    const score = detectSegmentScore(
      lineSpaceIndex,
      lb[key as Position],
      isHorizontal,
      params
    )

    const detected = score > params.edgeDetection.detectLineThreshold

    debugSegments &&
      drawSelectedSegments(
        cv,
        key as Position,
        score,
        debugSegments,
        cp,
        params.edgeDetection.detectLineThreshold,
        detected
      )

    return [
      key,
      {
        detected,
        score,
      },
    ]
  })

  const result: DetectSegmentResult = (Object.fromEntries(
    entries
  ) as unknown) as DetectSegmentResult

  result.totalDetected = Object.values(entries).filter(
    ([, { detected }]) => detected
  ).length

  return { ...result, duration: Date.now() - start }
}

const lineSpace = (
  startValue: number,
  stopValue: number,
  cardinality: number
) => {
  const arr: Array<number> = []
  const step = (stopValue - startValue) / (cardinality - 1)
  for (let i = 0; i < cardinality; i++) {
    arr.push(startValue + step * i)
  }
  return arr
}

export const edgeValidation = (
  cv: CV,
  params: DetectionParams,
  rectangle: Rectangle,
  image: ImageData
): EdgeDetectionResults => {
  const start = Date.now()
  const gray = new cv.Mat()
  const frameCanny = new cv.Mat()

  const cropCoordinatesInOriginalFrameWithoutOffset = Rect.fromCoordinates(
    rectangle.left,
    rectangle.top,
    rectangle.right,
    rectangle.bottom
  )
  const overlayCoordinatesInCroppedFrame = Rect.fromCoordinates(
    params.crop.offset,
    params.crop.offset,
    params.crop.offset + cropCoordinatesInOriginalFrameWithoutOffset.width,
    params.crop.offset + cropCoordinatesInOriginalFrameWithoutOffset.height
  )
  const original = cv.matFromImageData(image)

  params.debug && cv.imshow('canvasCapture', original)

  const croppedImage = cropImage(
    cv,
    original,
    cropCoordinatesInOriginalFrameWithoutOffset,
    params.crop.offset
  )

  const resizedImage = resizeImage(cv, params.resizePixels, croppedImage)

  const smallerImageSize = resizedImage.size()
  const croppedImageSize = croppedImage.size()
  const scaleFactor = smallerImageSize.width / croppedImageSize.width // newWidth / oldWidth

  const computedParams: ComputedParams = {
    scaledOverlayCoordinates: overlayCoordinatesInCroppedFrame.scale(
      scaleFactor
    ),
    scaledInsideMargin: params.edgeDetection.insideMargin * scaleFactor,
    resizedImageCoordinates: Rect.fromCoordinates(
      0,
      0,
      resizedImage.size().width,
      resizedImage.size().height
    ),
    scaledOffset: params.crop.offset * scaleFactor,
  }

  cv.cvtColor(resizedImage, gray, cv.COLOR_BGR2GRAY)
  cv.GaussianBlur(gray, gray, new cv.Size(7, 7), 1.5, 1.5)
  cv.Canny(
    gray,
    frameCanny,
    params.canny.threshold1,
    params.canny.threshold2,
    params.canny.aperture
  )

  params.debug && cv.imshow('canvasCanny', frameCanny)

  const debugHoughLines = params.debug ? resizedImage.clone() : null
  const debugSegments = params.debug ? resizedImage.clone() : null

  const lineBuffer = calculateHoughLines(
    cv,
    params,
    computedParams,
    frameCanny,
    debugHoughLines
  )

  debugHoughLines && cv.imshow('canvasHough', debugHoughLines)

  // draw the main "overlay" rectangle
  debugSegments &&
    drawRectangle(cv, computedParams.scaledOverlayCoordinates, debugSegments)

  // draw the secondary "insideMargin" rectangle
  debugSegments &&
    drawRectangle(
      cv,
      computedParams.scaledOverlayCoordinates.addMargin(
        computedParams.scaledInsideMargin
      ),
      debugSegments
    )

  const segments = detectSegments(
    cv,
    lineBuffer,
    params,
    computedParams,
    debugSegments
  )

  if (debugSegments) {
    // draw the lines that were selected after the houghLines step. Draw them here so they can overwrite existing rectangles.
    const pos = ['top', 'left', 'right', 'bottom']
    pos.forEach((position) => {
      const buffer = lineBuffer[position as Position]
      buffer.forEach((line) => {
        drawLine(
          cv,
          debugSegments,
          new cv.Point(line[0], line[1]),
          new cv.Point(line[2], line[3]),
          'GREEN'
        )
      })
    })
  }

  debugSegments && cv.imshow('canvasSegments', debugSegments)

  original.delete()
  croppedImage.delete()
  resizedImage.delete()
  gray.delete()
  frameCanny.delete()
  debugHoughLines && debugHoughLines.delete()
  debugSegments && debugSegments.delete()

  return {
    analytics: {
      totalDuration: Date.now() - start,
      houghLinesDuration: lineBuffer.duration,
      detectSegmentDuration: segments.duration,
    },
    segments,
  }
}
