import { init } from 'onfido-sdk-ui'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { userStatusCreator } from '../../actions/getUserStatus'
import requestHandler from '../../actions/httpClient'

const Onfido = ({ token, workFlowRunId }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {

        var signature = localStorage.getItem("signature");

        init({
            token: token,
            containerId: 'onfido-mount',
            onComplete: async function (data) {

                let body = {
                    signature: signature,
                    type: process.env.REACT_APP_KYC_STATUS_UNDER_VERIFICATION
                }
                await requestHandler('updateKycStatus', 'post', body, 'jwt_token')
                dispatch(userStatusCreator())
                navigate('/profile-info')

            },
            onError: async function (error) {
                let body = {
                    signature: signature,
                    type: process.env.REACT_APP_KYC_STATUS_ERROR
                }
                await requestHandler('updateKycStatus', 'post', body, 'jwt_token')
                dispatch(userStatusCreator())
                navigate('/user-kyc-verification')

            },
            onUserExit: async function (userExit) {
                let body = {
                    signature: signature,
                    type: process.env.REACT_APP_KYC_STATUS_USER_EXIT
                }
                await requestHandler('updateKycStatus', 'post', body, 'jwt_token')
                dispatch(userStatusCreator())
                navigate('/user-kyc-verification')
            },
            workflowRunId: workFlowRunId,
            customUI: {
                "colorBackgroundButtonPrimary": "#22767D", "colorBackgroundButtonPrimaryHover": "#123D41"
            }
        })
    }, [])
    return (
        <div id="onfido-mount" style={{ padding: 20 }}></div>
    )
}

export default Onfido