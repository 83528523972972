import React from "react";
import cn from "classnames";
import styles from "./Releases.module.sass";
import Item from "./Item";

const newItems = [
  {
    title: "What is BDNX?",
    content: "BDNX is our own platform token and driven by our believe to create a crypto ecosystem which should be simple, secure and accessible to everyone around the world. As a platform token, the top priority for BDNX is to provide a diverse range of exclusive benefits to holders, so that we can benefit together from Bitdenex’s growth.",
  },
  {
    title: "Technology",
    content: "Our self-developed high-performance in-memory matching engine can handle up to 10,000+ queries per second (QPS), enabling seamless and secure trading even under extreme market conditions. With outstanding market depth within the industry, we never experienced downtime and security incidents under extreme market conditions.",
  },
  {
    title: "Operations",
    content:
      "Bitdenex operates from its headquarter in Almere, the Netherlands (Europe), and from its local offices in Paramaribo, Suriname and Chandigarh, India. With this approach, we aim to provide 24/7/365 support to our customers.",
  },
];

const Releases = ({ scrollToRef }) => {

  return (
    <div
      className={cn("section-bg section-mb0", styles.releases)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.wrap}>
            <h2 className={cn("h2", styles.title)}>About BDNX</h2>
          </div>
        </div>
        <div className={styles.list}>
          {newItems.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Releases;
