import {
    GET_USER_STATUS_REQUEST, GET_USER_STATUS_SUCCESS, GET_USER_STATUS_FAILED
} from '../actions/getUserStatus/actionType'

const initialState = {
    loading: false,
    userStatus: [],
    error: '',
    walletData: []

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                userStatus: action.payload.data.data,
                walletData: action.payload.data.data.wallet
            }
        case GET_USER_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }



}



export default reducer