import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Modal from "../../../../components/Modal";
import Successfully from "./Successfully";
import requestHandler from "../../../../actions/httpClient";
import { addNotification } from "../../../../components/Notification";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";
import WalletSkeleton from "../../../../components/Skeleton/WalletSkeleton";
import { useDispatch } from "react-redux";
import { userStatusCreator } from "../../../../actions/getUserStatus";

const Item = ({ item, children, handleRefresh, loading }) => {
  const [visible, setVisible] = useState(false);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} >
          {
            loading ? <WalletSkeleton /> :
              <div className={styles.col}>
                {/* <div className={styles.currency}> */}
                {/* <div className={styles.icon}>
                <img src={item.icon} alt="Currency" />
              </div> */}
                <div className={styles.details}>
                  <div className={styles.info}>{item?.amount}</div>
                  {/* <div className={styles.text}>{item.content}</div> */}
                </div>
                {/* </div> */}
              </div>}
          <div className={styles.col}>
            <div className={styles.info}>{item.currency}</div>
            {/* <div className={styles.text}>{item.priceTotal}</div> */}
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item.reference}</div>
            {/* <div className={styles.text}>{item.priceAvailable}</div> */}
          </div>
          <div className={styles.col}>
            {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_STATUS_PENDING) && (
              <div className={cn("category-blue")}> Pending </div>
            )}
            {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_STATUS_APPROVED) && (
              <div className={cn("category-blue")}> Approved </div>
            )}
            {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_STATUS_CANCELLED) && (
              <div className={cn("category-red")}> Cancelled </div>
            )}
            {/* {item?.status == process.env.REACT_APP_ORDER_STATUS_OPEN && (
              <div className={cn("category-orange")}> Open </div>
            )} */}

            {/* <div className={styles.info}>{item.currencyAvailable}</div>
            <div className={styles.text}>{item.priceAvailable}</div> */}
          </div>
          <div className={styles.col}>
            {/* <div className={styles.info}>{new Date(item.updatedAt).toLocaleString().slice(0, 10)}</div> */}
            <div className={styles.info}>{toLocaleStringDateFormat(item.updatedAt)}</div>
            {/* <div className={styles.text}>{item.priceInterest}</div> */}
          </div>
          <div className={styles.col}>
            {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_STATUS_PENDING) && (<button
              className={cn("button-small button-red", styles.button)}
              onClick={() => {
                setVisibleSuccessfully(true)
              }}
            >
              Cancel
            </button>)}
          </div>
        </div>
        <div className={styles.btns}>{children}</div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <Successfully
          item={item}
          closeModal={() => setVisibleSuccessfully(false)}
          cancelOrder={async (id, reference) => {
            let data = {
              id: id,
              reference: reference,
              signature: localStorage.getItem('signature')

            }
            try {
              const response = await requestHandler('/cancel_withdraw_request', 'post', data, 'jwt_token');

              if (response.status === 200) {
                dispatch(userStatusCreator())
                addNotification({
                  title: 'success', message: 'Withdraw Request cancelled'
                  , type: 'success'
                })
              }
              handleRefresh()
            } catch (error) {
              addNotification({
                title: 'error', message: 'Soething went wrong'
                , type: 'danger'
              })
            }

            setVisibleSuccessfully(false)
          }}
        />
      </Modal>
    </>
  );
};

export default Item;
