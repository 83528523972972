import { assign, createMachine, InvokeCreator, EventObject } from 'xstate'
import { escalate } from 'xstate/lib/actions'

export const SELECTION_TIMEOUT = 1500

interface Context {
  image: ImageData | undefined
  score: number
  timer: number
}

const defaultContext: Context = {
  image: undefined,
  score: 0,
  timer: 0,
}

export const createMultiCaptureBlurMachine = (
  captureImage: InvokeCreator<Context, EventObject>
) =>
  createMachine<Context>(
    {
      id: 'selectImage',
      initial: 'idle',
      context: defaultContext,
      predictableActionArguments: true,

      states: {
        idle: {
          after: {
            SELECTION_DELAY: {
              actions: 'initTimer',
              target: 'capturing',
            },
          },
        },

        capturing: {
          invoke: {
            src: 'captureImage',
            onDone: {
              actions: 'scoreImage',
              target: 'scored',
            },
            onError: {
              target: 'errored',
            },
          },
        },

        scored: {
          always: [
            {
              cond: 'isSelectionTimeout',
              target: 'done',
            },
          ],
          after: {
            CAPTURE_DELAY: {
              target: 'capturing',
            },
          },
        },
        errored: {
          type: 'final',
          entry: escalate({ message: 'Capture Error' }),
        },
        done: {
          type: 'final',
          data: (ctx) => ({ image: ctx.image, blurVariance: ctx.score }),
        },
      },
    },
    {
      services: {
        captureImage,
      },
      actions: {
        initTimer: assign({
          timer: ({}) => Date.now(),
        }),
        scoreImage: assign((ctx, { data }) => {
          if (!ctx.image || data.blur.variance > ctx.score) {
            return { image: data.image, score: data.blur.variance }
          }

          return ctx
        }),
      },
      guards: {
        isSelectionTimeout: ({ timer }) =>
          Date.now() - timer > SELECTION_TIMEOUT,
      },
      delays: {
        SELECTION_DELAY: 1000,
        CAPTURE_DELAY: 100,
      },
    }
  )
