import { CV, Mat, Size } from '@onfido/opencv'
import { Rect } from './Geometrie'

const createOffsetRectangle = (
  cv: CV,
  rect: Rect,
  bounds: Size,
  offset: number
) => {
  const left = Math.max(rect.left - offset, 0)
  const top = Math.max(rect.top - offset, 0)
  const width = Math.min(rect.width + 2 * offset, bounds.width)
  const height = Math.min(rect.height + 2 * offset, bounds.height)

  return new cv.Rect(left, top, width, height)
}

export const cropImage = (
  cv: CV,
  src: Mat,
  rect: Rect,
  offset: number
): Mat => {
  // Setup a rectangle to define your region of interest
  const roi = createOffsetRectangle(cv, rect, src.size(), offset)

  return src.roi(roi)
}
export const resizeImage = (cv: CV, expectedPixels: number, src: Mat): Mat => {
  const dst = new cv.Mat()
  const size = src.size()

  const newWidth = Math.sqrt((expectedPixels * size.width) / size.height)
  const newHeight = expectedPixels / newWidth

  const newSize = new cv.Size(newWidth, newHeight)

  cv.resize(src, dst, newSize)

  return dst
}
