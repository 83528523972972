import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";

import PDF from "./Documents/SU_Bitdenex_Privacy_Policy_V.01.03.2024.pdf"

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h3>Privacy Policy</h3>
          <p>
            <a href={PDF} target="_blank" rel="noreferrer">Complete Privacy-Policy can be downloaded and viewed here</a>
          </p>
          <p>
            When visiting the Bitdenex website, you automatically agree to the Privacy Policy. This policy is in compliance with the Privacy Legislation, the General Data Protection Regulation (GDPR). We think it is very important that you are well-informed about the Privacy Legislation.           </p>
          <h3>Article 1: Management</h3>
          <p>
            The website www.su.bitdenex.com is managed by Bitdenex You can find all the contact information on the website.
          </p>

          <figure>
            <img src="/images/content/privacy/article.jpg" alt="Content" />
          </figure>
          <h3>Article 2: Visitor information</h3>
          <p>
            2.1 Some information resulting from one or more visits to the website will be saved. All information that is not required for the service of Bitdenex will be made anonymous. Identifiable information required for the services of Bitdenex will be saved if necessary. All laws of the Personal Data Protection Act will be respected in this. </p>
          <p>
            2.2 Bitdenex can use the information provided by the customer for an number of purpose, including the following: <br />
            • Sending one or more mails to determine your identity and verify your account. <br />
            • Sending one or more e-mails such as, but not limited to, an e-mail containing log-in information. <br />
            • To highlight important news regarding Bitdenex services. <br />
            • The deliberate/incidental sending of e-mails if this is necessary for further collaboration between the customer and Bitdenex.  <br />
            • The performance of the services requested by the customer as offered on the website of Bitdenex. <br />
          </p>
          <h3>Article 3: Cookies</h3>
          <p>
            3.1 Bitdenex uses cookies for making the functionality of certain pages of the website possible and optimizing these features.
          </p>
          <p>
            3.2 Visitors can decide themselves how cookies are handled. You can configure your browser so that it allows, disallows or partially allows cookies. For the last case, it can be configured for which websites cookies are allowed. Be aware that if you do not allow cookies, certain features cannot be performed. In this case, you cannot make full use of our services you are entitled to.
          </p>
          <p>
            3.3 Cookies can always be deleted from a computer in the browser options.
          </p>

          <h3>Article 4: Security</h3>
          <p>
            The information provided to Bitdenex by the customer is stored in a secure environment.
          </p>
          <p>
            4.1 Your personal information is encrypted, stored offline and securely stored. There are only a few employees who have access to this information.
          </p>
          <p>
            4.2 The information necessary for the performance of customer service is not stored offline and is accessible to our customer service staff.
          </p>

          <h3>Article 5: Sharing of data with third parties</h3>
          <p>
            The information (within the Know Your Customer) provided by Bitdenex by the customer will never be passed on to third parties. However, there are exceptions to this rule:
          </p>
          <p>
            5.1 When a court or legal order has been issued to provide data.
          </p>
          <p>
            5.2 In case of fraud, scams or other undesirable acts, the information may be provided to the competent authorities.
          </p>


          <h3>Article 6: Changes to customer data</h3>
          <p>
            The customer has at all times the opportunity to change the information provided. Bitdenex can ask the customer in such a case to pass the change in a manner prescribed by Bitdenex and, in some cases, identification may be required.          </p>
          <p>
            6.1 Bitdenex is legally obliged to store your personal information in the context of the customer acceptance policy.
          </p>
          <p>
            6.2 Bitdenex will only store and / or process the collected information if you have explicitly given permission.
          </p>
          <p>
            6.3 In the event of withdrawal of your consent, we will immediately stop collecting and processing the information. In that case you will be informed of the consequences. This may mean that you cannot use certain services from Bitdenex.
          </p>

          <h3>Article 7: Questions</h3>
          <p>
            Visitors can contact the customer service of Bitdenex with their questions about this privacy policy. The contact details are listed on the website.          </p>

          <h3>Article 8: Disclaimer</h3>
          <p>
            Bitdenex is entitled to change the content of this privacy policy without notifying the visitor. The implementation of the change on the website is sufficient.          </p>

          <h3>View Complete Privacy-Policy </h3>
          <p>
            <a href={PDF} target="_blank" rel="noreferrer">Complete Privacy-Policy can be downloaded and viewed here</a>
          </p>
        </div>

      </div>
    </div>
  );
};

export default Article;
