
import requestHandler from '../httpClient'
import {
    NEW_ORDER_REQUEST, NEW_ORDER_SUCCESS, NEW_ORDER_FAILED, NEW_ORDER_EMPTY
} from './actionType'


const newOrderRequest = () => {

    return {
        type: NEW_ORDER_REQUEST
    }
}




const newOrderSuccess = (payload) => {

    return {
        type: NEW_ORDER_SUCCESS,
        payload: payload,
        loadingSell: false
    }
}

const newOrderEmpty = () => {

    return {
        type: NEW_ORDER_EMPTY
    }
}



const newOrderFailed = (error) => {

    return {
        type: NEW_ORDER_FAILED,
        error: error
    }
}


const newOrderCreator = (market_id, order_type, amount = null, sellAmount = null, limiPrice = null) => {
    return async (dispatch) => {
        dispatch(newOrderRequest());
        try {

            const signature = localStorage.getItem("signature")
            let data = {
                'marketId': market_id,
                'orderType': parseInt(order_type),
                'fiat_price': limiPrice,
                'fiat_spend': amount,
                'crypto_spend': parseFloat(sellAmount),
                'signature': signature,
            }

            const payload = await requestHandler('new_order', 'post', data, 'jwt_token')


            dispatch(newOrderSuccess(payload))

        } catch (error) {
            dispatch(newOrderFailed(error))
        }
    }

}

export {
    newOrderCreator, newOrderFailed, newOrderSuccess, newOrderRequest, newOrderEmpty
}