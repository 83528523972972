import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";

const Item = ({ className, item, key }) => {
  return (
    < Link className={cn(className, styles.item)} to={localStorage.getItem('signature') ? item.url : item.preUrl} >
      {item && item.id % 2 === 0 ?
        <>
          <div className={styles.preview}>
            <img className={styles.imgSetting} srcSet={`${item.image2x} 2x`} src={item.image} alt="Item" />
            <button className={cn("button", styles.button)} >
              {item.categoryText}
            </button>
          </div>
          <div className={styles.details}>

            <h4 className={cn("h4", styles.title)}>{item.title}</h4>
            <div className={styles.currency}>{item.currency}</div>
            <div className={styles.content}>{item.content}</div>
          </div>
        </>
        :
        <>
          <div className={styles.detailsOdd}>
            <h4 className={cn("h4", styles.title)}>{item.title}</h4>
            <div className={styles.currency}>{item.currency}</div>
            <div className={styles.content}>{item.content}</div>
          </div>
          <div className={styles.previewOdd}>
            <img className={styles.imgSetting} srcSet={`${item.image2x} 2x`} src={item.image} alt="Item" />
            <button className={cn("button", styles.button)} >
              {item.categoryText}
            </button>
          </div>

        </>
      }
    </Link >
  );
};

export default Item;
