import React, { useEffect, useState } from "react";
import cn from "classnames";
import { NavLink, Link, useLocation } from "react-router-dom";
import styles from "./Wallet.module.sass";
import Icon from "../Icon";
import Modal from "../Modal";
import Withdraw from "../Withdraw";
import WalletTransfer from "../WalletTransfer";
import Transfer from "../Transfer";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
import BitdenexPayTransfer from "../BitdenexPayTransfer";

const regionUserNavigation = [
  {
    title: "Overview",
    color: "#23262F",
    url: "/wallet-overview",
    separator: true,
  },
  {
    title: "Crypto Deposit Overview",
    color: "#FFD166",
    url: "/deposit-list",
  },
  {
    title: "Crypto Withdraw Overview",
    color: "#FFD166",
    url: "/withdraw-list",
    separator: true,
  },
  {
    title: "Wallet Transfer List",
    color: "#FFD166",
    url: "/wallet-transfer-list",
  },
  // {
  //   title: "Transfer Crypto List",
  //   color: "#FFD166",
  //   url: "/transfer-crypto-list",
  // },
  {
    title: "SRD Deposit Overview",
    color: "#FFD166",
    url: "/fiat-deposit-list",
  },
  {
    title: "SRD Withdraw Overview",
    color: "#FFD166",
    url: "/fiat-withdraw-list",
    separator: true,
  },
  {
    title: "Cashback and Reward Overview",
    color: "#FFD166",
    url: "/cashback-and-reward-list",
    separator: true,
  },
  {
    title: `${process.env.REACT_APP_COIN_TRANSFER_LIST_NAME} Overview`,
    color: "#FFD166",
    url: "/coin-transfer-details",
  },
  {
    title: "Bitdenex Pay Transactions",
    color: "#FFD166",
    url: "/bitdenex-pay-transactions",
  },
];

const internationalUserNavigation = [
  {
    title: "Overview",
    color: "#23262F",
    url: "/wallet-overview",
    separator: true,
  },
  {
    title: "Crypto Deposit Overview",
    color: "#FFD166",
    url: "/deposit-list",
  },
  {
    title: "Crypto Withdraw Overview",
    color: "#FFD166",
    url: "/withdraw-list",
    separator: true,
  },
  {
    title: "Wallet Transfer List",
    color: "#FFD166",
    url: "/wallet-transfer-list",
  },
  {
    title: "SRD Deposit Overview",
    color: "#FFD166",
    url: "/fiat-deposit-list",
  },
  {
    title: "Euro Withdraw Overview",
    color: "#FFD166",
    url: "/fiat-withdraw-list",
    separator: true,
  },
  {
    title: "Cashback and Reward Overview",
    color: "#FFD166",
    url: "/cashback-and-reward-list",
    separator: true,
  },
  {
    title: "Bitdenex Pay Transactions",
    color: "#FFD166",
    url: "/bitdenex-pay-transactions",
  },
];

const Wallet = ({ className, children }) => {
  const { pathname } = useLocation();
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const dispatch = useDispatch();
  const activeItem = internationalUserNavigation.find((x) => pathname.includes(x.url));
  const [visibleModal, setVisibleModal] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const [navigation, setNavigation] = useState([]);

  useEffect(() => {
    if (userStatus.length === 0) {
      dispatch(userStatusCreator());
    }
    if (userStatus?.switchRegion === true) {
      setNavigation(regionUserNavigation);
    }
    else {
      setNavigation(internationalUserNavigation);
    }
  }, [userStatus]);

  return (
    <>
      <div className={cn(className, styles.wallet)}>
        <div className={styles.sidebar}>
          <div className={cn(styles.group, { [styles.active]: visibleMenu })}>
            <div
              className={styles.top}
              onClick={() => setVisibleMenu(!visibleMenu)}
            >
              <div
                className={styles.bg}
                style={{ backgroundColor: activeItem?.color }}
              ></div>
              {activeItem?.title}
            </div>
            <div className={styles.menu}>
              {navigation.map((item, index) =>
                item.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.separator]: item.separator,
                    })}
                    activeClassName={styles.active}
                    to={item.url}
                    key={index}
                  >
                    {item.color && (
                      <div
                        className={styles.bg}
                        style={{ backgroundColor: item.color }}
                      ></div>
                    )}
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </NavLink>
                ) : (
                  <button
                    className={cn(styles.item, {
                      [styles.separator]: item.separator,
                    })}
                    key={index}
                    onClick={() => setVisibleTransfer(!visibleTransfer)}
                  >
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </button>
                )
              )}
            </div>
          </div>
          {userStatus?.switchRegion === true && (
            <div className={styles.btns}>
              <button
                className={cn("button button-small", styles.button)}
                onClick={() => setVisibleTransfer(!visibleTransfer)}
              >
                {process.env.REACT_APP_COIN_TRANSFER_LIST_NAME}
              </button>
            </div>
          )}
          <div className={styles.btnsNew}>
            <button
              className={cn("button button-small", styles.button)}
              onClick={() => setVisibleModal(!visibleModal)}
            >
              {process.env.REACT_APP_BITDENEX_PAY_TEXT}
            </button>
            <Link
              className={cn("button button-small", styles.button)}
              to={"/staking"}
            >
              Stake BDNX
            </Link>
            <Link
              className={cn("button button-small", styles.button)}
              to={userStatus?.kyc_verification && userStatus?.kyc_verification !== 5 ? "/user-kyc-verification" :
                userStatus?.bank_verification && userStatus?.bank_verification !== 3 ? "/bank-verification" :
                  "/deposit-fiat"}
            >
              Deposit SRD
            </Link>
            <Link
              className={cn("button button-small button-red", styles.button)}
              to={userStatus?.kyc_verification && userStatus?.kyc_verification !== 5 ? "/user-kyc-verification" :
                userStatus?.bank_verification && userStatus?.bank_verification !== 3 ? "/bank-verification" :
                  "/withdraw-fiat"}
            >
              Withdraw SRD
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>{children}</div>
      </div>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw />
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <WalletTransfer setVisibleTransfer={setVisibleTransfer} />
      </Modal>
      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <BitdenexPayTransfer
          setVisibleModal={setVisibleModal}
          pageRefreshFlag={pageRefreshFlag}
          setPageRefreshFlag={setPageRefreshFlag}
        />
      </Modal>
    </>
  );
};

export default Wallet;
