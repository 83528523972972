const EXCHANGE_REQUEST = 'EXCHANGE_REQUEST';
const EXCHANGE_SUCCESS = 'EXCHANGE_SUCCESS';
const EXCHANGE_FAILED = 'EXCHANGE_FAILED';
const EXCHANGE_EMPTY = 'EXCHANGE_EMPTY';
const EXCHANGE_UPDATE_BUY = 'EXCHANGE_UPDATE_BUY';
const EXCHANGE_UPDATE_SELL = 'EXCHANGE_UPDATE_SELL';

export {
    EXCHANGE_REQUEST, EXCHANGE_SUCCESS, EXCHANGE_FAILED,EXCHANGE_EMPTY,EXCHANGE_UPDATE_BUY,EXCHANGE_UPDATE_SELL
}
