export type DetectionParams = {
  houghLine: HoughLine
  canny: Canny
  crop: {
    offset: number // how many more pixels than the rectangle do we need to crop in all 4 directions.
  }
  blur: Blur
  edgeDetection: EdgeDetection
  resizePixels: number // How many pixels to resize to before doing expensive computation such as Edge detection, Blur detection, etc. The lower, the faster the detection will run.
  debug: boolean // true to enable the "Devtools" interface, which contains useful information to troubleshoot the whole automatic document detection feature.
}

export type HoughLine = {
  maxLineGap: number
  minLineLength: number
  threshold: number
  theta: number
}

export type Canny = {
  threshold1: number
  threshold2: number
  aperture: number
}

export type Blur = {
  threshold: number // laplacian variance: the min blur threshold for validation. Need to lower it for documents that have a uniform appearance (same color, not much text, no photo, etc)
}

export type EdgeDetection = {
  insideMargin: number // Padding (inside the box) where we allow the document's edges to be. In px relative to the original frame size, will be rescaled.
  angleTolerance: number // how not really vertical or horizontal do we allow lines to be
  requiredEdges: number // how many lines detected to validate
  numSegment: number // How many segments to split the four edges
  detectLineThreshold: number // What's the min score required to say "ok edge detected" for a particular line
}

export const defaultParams: DetectionParams = {
  houghLine: {
    maxLineGap: 5,
    minLineLength: 5,
    threshold: 40,
    theta: Math.PI / 180,
  },

  canny: {
    aperture: 3,
    threshold1: 50,
    threshold2: 100,
  },

  blur: {
    threshold: 900,
  },

  edgeDetection: {
    detectLineThreshold: 0.5,
    requiredEdges: 4,
    numSegment: 8,
    insideMargin: 146,
    angleTolerance: 10,
  },

  resizePixels: 100000,

  crop: {
    offset: 20,
  },

  debug: false,
}
