// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-PageTitle-title{color:var(--osdk-color-content-title);font-family:var(--osdk-font-family-title);font-weight:var(--osdk-font-weight-title);margin-top:0;margin-bottom:inherit;font-size:var(--osdk-font-size-title);line-height:1.34}@media(max-width: 479px){.onfido-sdk-ui-PageTitle-title{font-size:1.5em}}.onfido-sdk-ui-PageTitle-title:focus{outline:none}.onfido-sdk-ui-PageTitle-subTitle{color:var(--osdk-color-content-subtitle);font-size:var(--osdk-font-size-subtitle);font-family:var(--osdk-font-family-subtitle);font-weight:var(--osdk-font-weight-subtitle);margin:0}.onfido-sdk-ui-PageTitle-titleWrapper{margin:2em 0 1em;-webkit-flex-shrink:0;flex-shrink:0;-webkit-transform:translateZ(0);transform:translateZ(0)}@media(max-width: 479px){.onfido-sdk-ui-PageTitle-titleWrapper{margin:0 0 1em}}.onfido-sdk-ui-PageTitle-smaller{margin-top:0}.onfido-sdk-ui-PageTitle-fullScreen{color:rgb(var(--ods-color-neutral-white));margin:0;z-index:1}.onfido-sdk-ui-PageTitle-fullScreen .onfido-sdk-ui-PageTitle-title,.onfido-sdk-ui-PageTitle-fullScreen .onfido-sdk-ui-PageTitle-subTitle{color:rgb(var(--ods-color-neutral-white))}.onfido-sdk-ui-PageTitle-fullScreen .onfido-sdk-ui-PageTitle-title{margin-bottom:.5em;font-size:var(--onfido-font-size-base);line-height:1.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"title": "onfido-sdk-ui-PageTitle-title",
	"subTitle": "onfido-sdk-ui-PageTitle-subTitle",
	"titleWrapper": "onfido-sdk-ui-PageTitle-titleWrapper",
	"smaller": "onfido-sdk-ui-PageTitle-smaller",
	"fullScreen": "onfido-sdk-ui-PageTitle-fullScreen"
};
export default ___CSS_LOADER_EXPORT___;
