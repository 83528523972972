// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[dir=rtl] .onfido-sdk-ui-QRCode-qrCodeHelpIcon{-webkit-transform:scaleX(-1);transform:scaleX(-1)}.onfido-sdk-ui-QRCode-qrCodeHelp{min-height:unset}.onfido-sdk-ui-QRCode-qrCodeHelpButton{cursor:pointer}.onfido-sdk-ui-QRCode-qrCodeHelpIcon{width:1.25em;height:1em;display:inline-block;vertical-align:text-bottom}.onfido-sdk-ui-QRCode-qrCodeHelpList{font-size:var(--onfido-font-size-small);text-align:initial;margin-bottom:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"qrCodeHelpIcon": "onfido-sdk-ui-QRCode-qrCodeHelpIcon",
	"qrCodeHelp": "onfido-sdk-ui-QRCode-qrCodeHelp",
	"qrCodeHelpButton": "onfido-sdk-ui-QRCode-qrCodeHelpButton",
	"qrCodeHelpList": "onfido-sdk-ui-QRCode-qrCodeHelpList"
};
export default ___CSS_LOADER_EXPORT___;
