import requestHandler from "../httpClient";
import {
    GET_COINS_FAILED,
    GET_COINS_REQUEST,
    GET_COINS_SUCCESS,
    GET_EMPTY_COINS
} from "./actionType";

const getCoinsRequest = () => {
    return {
        type: GET_COINS_REQUEST,
    };
};

const getCoinsSuccess = (payload) => {
    return {
        type: GET_COINS_SUCCESS,
        payload: payload,
    };
};


const getEmptyCoins = () => {

    return {
        type: GET_EMPTY_COINS
    }

}
const getCoinsFailed = (err) => {
    return {
        type: GET_COINS_FAILED,
        message: err,
    };
};

const getCoinsCreator = () => {
    return async (dispatch) => {
        dispatch(getCoinsRequest());
        try {
            let data = {
                signature: localStorage.getItem("signature"),
            };
            const payload = await requestHandler("getCoins", "post", data, "jwt_token");
            dispatch(getCoinsSuccess(payload));
        }
        catch (e) {
            dispatch(getCoinsFailed(e));
        };
    };
};

export { getCoinsRequest, getCoinsSuccess, getCoinsFailed, getCoinsCreator, getEmptyCoins };