import { ComponentChildren, h, Fragment } from 'preact'
import style from './Overlay.scss'
import classNames from 'classnames'

type OverlayProps = {
  children: ComponentChildren
  header?: ComponentChildren
  footer?: ComponentChildren
  frame?: ComponentChildren
  status: `idle` | `capture` | `success`
}

export const Overlay = ({
  children,
  header,
  footer,
  frame,
  status = 'idle',
}: OverlayProps) => {
  return (
    <div data-onfido-qa={`overlay-${status}`} className={style.wrapper}>
      <div className={classNames(style.frameContainer, style[status])}>
        {children}
      </div>

      <div id={'frame'} className={classNames(style.frame, style[status])}>
        {status === 'success' ? (
          <div aria-label="Success" className={style.successIcon} />
        ) : null}
        {status !== 'success' ? frame : null}
      </div>
      <div className={style.header}>{header}</div>
      <div className={style.footer}>{footer}</div>
    </div>
  )
}

export const OverlayMessage = ({
  message,
  spinner,
}: {
  message: string
  spinner?: boolean
}) => (
  <div className={style.chip} role="status">
    {spinner ? <div className={style.spinner} /> : null}
    {message}
  </div>
)

export type OverlayErrorProps = {
  title: string
  message?: string
  children: ComponentChildren
}

export const OverlayError = ({
  title,
  message,
  children,
}: OverlayErrorProps) => (
  <div className={style.error}>
    <div className={style.badge} />
    <div className={style.content}>
      <Fragment>
        <div className={style.title}>{title}</div>
        <div className={style.message}>{message || children}</div>
      </Fragment>
    </div>
  </div>
)
