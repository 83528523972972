import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Item = ({ item, children }) => {
  const [visible, setVisible] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)}>
          <div className={styles.col}>
            <div className={styles.info}>{item.txid}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>Bitdenex {capitalizeFirstLetter(item.from.toLowerCase())}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>Bitdenex {capitalizeFirstLetter(item.to.toLowerCase())}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item.amount + ' ' + item.currency.toUpperCase()}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{toLocaleStringDateFormat(item.updatedAt)}</div>
          </div>
        </div>
        <div className={styles.btns}>{children}</div>
      </div>
    </>
  );
};

export default Item;
