import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ item, index }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} >
          <div className={styles.col}>
            {index + 1}
          </div>
          <div className={styles.col}>
            {item?.uid}
          </div>
          <div className={styles.col}>
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) && "Pending"}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_USER_EXIT) && "User Exit"}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_UNDER_VERIFICATION) && "In Process"}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_ERROR) && "Cancelled"}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) && "Complete"}
            {item?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_INREVIEW) && "In process"}
          </div>
        </div >
      </div >
    </>
  );
};

export default Item;
