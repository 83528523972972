import React, { useRef } from "react";
import Download from "../Home/Download";
import Main from "./Main";
import Releases from "./Releases";

const BuyAndSellTron = () => {
  const scrollToRef = useRef(null);
  const scrollToRefCatalog = useRef(null);

  return (
    <>
      <Main scrollToRef={scrollToRef} scrollToRefCatalog={scrollToRefCatalog} />
      <Releases scrollToRef={scrollToRef} />
      <Download />
    </>
  );
};

export default BuyAndSellTron;
