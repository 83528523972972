import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./BankDetails.module.sass";
import Table from "../Table";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import Radio from "../../../components/Radio";
import { addNotification } from "../../../components/Notification";
import CustomDropdown from "../../../components/CustomDropdown";
import BankSkeleton from "../../../components/Skeleton/BankSkeleton";
import requestHandler from "../../../actions/httpClient";

const BankDetails = ({
  loading,
  bankList,
  userStatus,
  bankDetailsData,
  gateWayList,
  primaryBankHandler
}) => {

  const [paymentMode, setPaymentMode] = useState("Select a value");
  const [text, setText] = useState('Select a value');
  const [textIssuer, setTextIssuer] = useState('Select a value');
  const [bankIssuer, setBankIssuer] = useState("Select a value");
  const [account, setAccount] = useState('');
  const [bankProof, setBankProof] = useState();
  const [onlineBanking, setOnlineBanking] = useState(false);
  const [paymentTypeValue, setPaymentTypeValue] = useState("");
  const [property, setProperty] = useState()
  const [selected, setSelected] = useState([])
  const [option, setOption] = useState();
  const [option2, setOption2] = useState([]);

  useEffect(() => {
    gateWayList.map(x => {
      if (x.gateway_name == "SEPA") {
        setPaymentTypeValue(x.id)
      }
    })
  }, [gateWayList])

  const handleSepa = (x, index) => {
    setProperty(index)
    setPaymentTypeValue(x.id);
    setOnlineBanking(false)
  };

  const bankVerificationHandler = async () => {
    bankDetailsData(paymentTypeValue, account, bankProof);
  };

  return (
    <>
      {userStatus?.add_more_accounts && (
        <div className={styles.wrap} key={1}>
          <div className={styles.title} key={2}>Use your bank account from which you want to deposit and withdraw USD.</div>
          <div className={styles.info} key={3}> Maximum bank accounts you can add : {userStatus.max_bank_account_no}</div>
          {loading ? <BankSkeleton count={3} height={30} />
            :
            gateWayList?.map((x, index) => {
              if (x.gateway_name === 'SEPA') {
                return (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      <Checkbox
                        className={styles.checkbox}
                        content="Wire Transfer"
                        value={true}
                        onChange={() => handleSepa(x, index)}
                        key={index}
                      />
                    </div>
                  </div>
                )
              }
            })}
          <div className={styles.row}>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Account number"
                name="account-number"
                type="text"
                required
                placeholder="Enter your account number."
                value={account}
                onChange={(e) => {
                  const re = /^[A-Za-z0-9]*$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setAccount(e.target.value)
                  }
                }}
              />
            </div>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Proof of bank account"
                name="bank-proof"
                type="file"
                required
                onChange={e => setBankProof(e.target.files[0])}
              />
            </div>
          </div>
          <div className={styles.stage}>Note: Please enter an account number in <span className={styles.bankText}>
            {(text.toLowerCase() === "bancontact") ? "BExx-xxxx-xxxx-xxxx" : (text.toLowerCase() === "giropay") ? "DExx-xxxx-xxxx-xxxx-xxxx-xx" : "NLxx-xxxx-xxxx-xxxx-xx"}
          </span> format.</div>

          <button disabled={loading} className={cn("button", styles.button)}
            onClick={(e) => {
              if (account !== "" && bankProof !== undefined) {
                bankVerificationHandler();
              }
              else {
                addNotification({
                  title: "Alert",
                  message: "Please fill all the fields.",
                  type: "danger",
                });
              }
            }
            }
          >
            Submit
          </button>
        </div>
      )}

      <br />
      <div className={styles.wrap}>
        <Table
          bankList={bankList}
          loading={loading}
          primaryBankHandler={primaryBankHandler}
        />
      </div>
    </>
  );
};

export default BankDetails;
