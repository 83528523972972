import React from "react";
import cn from "classnames";
import styles from "./PaymentDetails.module.sass";
import LoaderScreen from "../../../components/LoaderScreen";

const PaymentDetails = ({ selectedPrice, currency, withdrawFiatHandler, loading }) => {

  return (
    <div className={styles.item}>
      {loading && <LoaderScreen />}
      <div className={styles.title}>Payment details</div>
      <div className={styles.info}>Your are withdrawing {selectedPrice} {currency}</div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)}
          disabled={''}
          onClick={() => withdrawFiatHandler(selectedPrice?.trim(), currency)}>
          Withdraw Fiat
        </button>
      </div>
    </div>
  );
};

export default PaymentDetails;
