import cn from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userStatusCreator } from "../../../actions/getUserStatus";
import requestHandler from "../../../actions/httpClient";
import Deposit from "../../../components/Deposit";
import LoaderScreen from "../../../components/LoaderScreen";
import Modal from "../../../components/Modal";
import { addNotification } from "../../../components/Notification";
import TradeSkeleton from "../../../components/Skeleton/TradeSkeleton";
import Withdraw from "../../../components/Withdraw";
import styles from "./AssetBalances.module.sass";
import Item from "./Item";
import MarginTradingTransfer from "../../../components/FundTransfer";

const AssetBalances = ({
  coinsData,
  userStatus,
  walletData,
  search,
  hideZero,
  loading,
  activeTab
}) => {
  const [selectedCoin, setSelectedCoin] = useState([])
  const [imageUrl, setImageUrl] = useState()
  const [loader, setLoader] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleDeposit, setVisibleDeposit] = useState(false);
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(true);
  const [walletAddressData, setWalletAddressData] = useState([])
  const [newCoinGeneratedID, setNewCoinGeneratedId] = useState()
  const [depositLoadting, setDepositLoading] = useState(false)
  const [fireBlockId, setFireBlockId] = useState([])
  const [networkSlug, setNetworkSlug] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visibleTransfer, setVisibleTransfer] = useState(false);

  // const withdrawHandler = (x, img_url,) => {
  //   if (userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
  //     history.push('/user-kyc-verification')
  //   } else {
  //     if (x.coinId !== parseInt(process.env.REACT_APP_SRD_COIN)) {
  //       setSelectedCoin(x)
  //       setImageUrl(img_url)
  //       getWalletAddressCreator(x)
  //     } else {
  //       history.push('/withdraw-fiat')
  //     }
  //   }
  // }

  const withdrawHandler = (x, img_url,) => {
    if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
      if (x.coinId !== parseInt(process.env.REACT_APP_SRD_ID)) {
        setSelectedCoin(x)
        setImageUrl(img_url)
        getWalletAddressCreator(x)
      } else {
        navigate('/withdraw-fiat')
      }
    }
    else {
      if (userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
        navigate('/user-kyc-verification')
      } else {
        if (x.coinId !== parseInt(process.env.REACT_APP_SRD_ID)) {
          setSelectedCoin(x)
          setImageUrl(img_url)
          getWalletAddressCreator(x)
        } else {
          navigate('/withdraw-fiat')
        }
      }
    }
  }

  const depositHandler = (x, img_url,) => {
    if (userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE) {
      setNetworkSlug([])
      if (x.coinId !== parseInt(process.env.REACT_APP_SRD_ID)) {
        if (x.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && x.stableCoin) {
          getFireblockNetworkId(x)
        }
        setVisibleDeposit(true)
        setSelectedCoin(x)
        setImageUrl(img_url)
      } else {
        navigate('/deposit-fiat')
      }
    }
    else {
      if (userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
        navigate('/user-kyc-verification')
      } else {
        setNetworkSlug([])
        if (x.coinId !== parseInt(process.env.REACT_APP_SRD_ID)) {
          if (x.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && x.stableCoin) {
            getFireblockNetworkId(x)
          }
          setVisibleDeposit(true)
          setSelectedCoin(x)
          setImageUrl(img_url)
        } else {
          navigate('/deposit-fiat')
        }
      }
    }
  }

  // const depositHandler = (x, img_url,) => {
  //   if (userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
  //     history.push('/user-kyc-verification')
  //   } else {
  //     setNetworkSlug([])
  //     if (x.coinId !== parseInt(process.env.REACT_APP_SRD_COIN)) {
  //       if (x.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN) && x.stableCoin) {
  //         getFireblockNetworkId(x)
  //       }
  //       setVisibleDeposit(true)
  //       setSelectedCoin(x)
  //       setImageUrl(img_url)
  //     } else {
  //       history.push('/deposit-fiat')
  //     }
  //   }
  // }

  const getFireblockNetworkId = async (x) => {
    setLoader(true)
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        coinId: x.coinId
      };
      const payload = await requestHandler("getFireblockNetworkId", "post", data, "jwt_token");
      setVisibleDeposit(true)
      setNetworkSlug(payload.data.data[0].network_slug)
      setFireBlockId(payload.data.data[0].fireblock_network_id)
      // if (x.coinId === 3) {
      //   setNetworkSlug(payload.data.data[0].network_slug?.slice(1))
      //   setFireBlockId(payload.data.data[0].fireblock_network_id?.slice(1))
      // } else {
      //   setNetworkSlug(payload.data.data[0].network_slug)
      //   setFireBlockId(payload.data.data[0].fireblock_network_id)
      // }

      setLoader(false)
    }
    catch (e) {

      setLoader(false)
    };

  }

  const getWalletAddressCreator = async (x) => {
    setLoader(true)
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        coinId: x.coinId
      };
      const payload = await requestHandler("getAddress", "post", data, "jwt_token");
      setLoader(false)
      setWalletAddressData(payload.data.data)
      //opens modal after all data set        
      setVisibleWithdraw(true);
    }
    catch (e) {
      setVisibleWithdraw(true);
      setLoader(false)
    };
  };

  const newWithdrawCreator = async (data) => {
    try {
      setLoader(true)
      const payload = await requestHandler("newWithdrawCrypto", "post", data, "jwt_token", "file");
      setLoader(false)
      if (payload && payload.status === 201) {
        setNewCoinGeneratedId(payload?.data?.message?.data.id)
        setVisibleSuccessfully(false);
        setActiveIndex(1);
      }
    }
    catch (e) {
      setVisibleWithdraw(false);
      setActiveIndex(0);
      setLoader(false)
      addNotification({
        title: "Error",
        message: e.data.message[0].msg,
        type: "danger"
      });
    };
  };

  const verifyOtpWithdrawCreator = async (data) => {
    try {
      setLoader(true)
      const payload = await requestHandler("verifyWithdrawCrypto", "post", data, "jwt_token");
      setLoader(false)
      if (payload.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: "Success",
          message: payload.data.message[0].msg,
          type: "Success"
        });
        setActiveIndex(2);
      };
    }
    catch (e) {
      setLoader(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger"
      });
    };
  };

  const cryptoAddressGenerater = async (coinId, fireBlock_network_id) => {
    setDepositLoading(true)
    const data = {
      coinId: coinId,
      signature: localStorage.getItem("signature"),
      fireBlock_network_id: fireBlock_network_id,
    }
    try {
      const payload = await requestHandler("generateCryptoAddress", "post", data, "jwt_token");
      setDepositLoading(false)
      if (payload.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: "Success",
          message: payload.data.message[0].msg,
          type: "Success"
        });
        //history.push('/wallet-overview')
        setVisibleDeposit(false)
      };
    }
    catch (e) {
      setDepositLoading(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0].msg,
        type: "danger"
      });
    };
  };

  const codeScreenHandler = (firstField, secondField, thirdField, fourthField, fifthField, sixthField, verificationType) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    let data = {
      code: code,
      id: newCoinGeneratedID,
      verification_type: verificationType,
      signature: localStorage.getItem("signature"),
    };
    verifyOtpWithdrawCreator(data)
  };

  const withdrawNewAddressHandler = (coin, withdrawAddress, ownership, addressProof, enteredAmount, token) => {
    let data = {
      signature: localStorage.getItem("signature"),
      amount: enteredAmount,
      coinId: coin,
      ownership: ownership,
      scanOwnership: addressProof,
      network: token ? token : null,
      address: withdrawAddress,
    };

    newWithdrawCreator(data);
  };

  const withdrawAddressHandler = (coinId, amount, address, token, tag) => {
    let data = {
      coinId: coinId,
      amount: amount,
      address: address?.trim(),
      // ownership: ownership,
      signature: localStorage.getItem("signature"),
      network: token ? token : null,
      tag: tag
    };
    newWithdrawCreator(data);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Asset</div>
          <div className={styles.col}>Total balance</div>
          <div className={styles.col}>SRD balance</div>
          {activeTab?.toLowerCase() === "spot" && <div className={styles.col}>Instant Trade</div>}
          <div className={styles.col}>Action</div>
        </div>
        {loading ? <TradeSkeleton rowCount={25} colCount={5} />
          :
          activeTab?.toLowerCase() === "spot" ?
            userStatus?.wallet?.balances?.sort((a, b) => {
              if (parseInt(a.is_srd_market) === 2 && parseInt(b.is_srd_market) !== 2) return -1;
              if (parseInt(a.is_srd_market) !== 2 && parseInt(b.is_srd_market) === 2) return 1;
              return 0;
            }).map((item, index) => {
              return hideZero === 0 && parseFloat(item.balance) === 0 || search !== "" && item.name.toLowerCase().indexOf(search.toLowerCase()) === -1 ? null
                :
                <Item
                  item={item}
                  key={index}
                  walletData={walletData}
                  userStatus={userStatus}
                  withdrawHandler={withdrawHandler}
                  depositHandler={depositHandler}
                  activeTab={activeTab}
                />
            })
            :
            userStatus?.stake_wallet?.balances?.filter(x => x?.symbol?.toLowerCase() === "bdnx")?.sort((a, b) => {
              if (parseInt(a.is_srd_market) === 2 && parseInt(b.is_srd_market) !== 2) return -1;
              if (parseInt(a.is_srd_market) !== 2 && parseInt(b.is_srd_market) === 2) return 1;
              return 0;
            }).map((item, index) => {
              return hideZero === 0 && parseFloat(item.balance) === 0 || search !== "" && item.name.toLowerCase().indexOf(search.toLowerCase()) === -1 ? null
                :
                <Item
                  item={item}
                  key={index}
                  walletData={walletData}
                  userStatus={userStatus}
                  withdrawHandler={withdrawHandler}
                  depositHandler={depositHandler}
                  setVisibleTransfer={setVisibleTransfer}
                  activeTab={activeTab}
                />
            })
        }
      </div>
      <Modal visible={visibleDeposit} onClose={() => setVisibleDeposit(false)}>
        <Deposit state={selectedCoin} imageUrl={imageUrl}
          userStatus={userStatus}
          cryptoAddressGenerater={cryptoAddressGenerater}
          depositLoadting={depositLoadting}
          networkSlug={networkSlug}
          fireBlockId={fireBlockId}
          loader={loader}
        />
      </Modal>
      {loader && <LoaderScreen />}
      <Modal
        visible={visibleWithdraw}
        onClose={() => { setVisibleWithdraw(false); setActiveIndex(0) }
        }>
        <Withdraw
          activeIndex={activeIndex}
          visibleSuccessfully={visibleSuccessfully}
          codeScreenHandler={codeScreenHandler}
          state={selectedCoin}
          walletAddressData={walletAddressData}
          withdrawAddressHandler={withdrawAddressHandler}
          coinsData={coinsData}
          coinOptions={coinsData?.data?.data}
          withdrawNewAddressHandler={withdrawNewAddressHandler}
          setVisibleSuccessfully={setVisibleSuccessfully}
          userStatus={userStatus}
          networkSlug={networkSlug}
          loader={loader}
        />
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <MarginTradingTransfer
          setVisibleFundTransfer={setVisibleTransfer}
        />
      </Modal>
    </div>
  );
};

export default AssetBalances;
