import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./FiatWithdrawList.module.sass";
import Wallet from "../../components/Wallet";
import Modal from "../../components/Modal";
import Withdraw from "../../components/Withdraw";
import Funds from "./Funds";
import requestHandler from "../../actions/httpClient";

const FiatWithdrawList = () => {

    const [visibleWithdraw, setVisibleWithdraw] = useState(false);
    const [withdrawData, setWithdrawData] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(false)
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [pageNo, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getWithdrawData(pageNo);
    }, [forceRefresh, pageNo])

    const handleLoadMore = () => {
        setPageNo(pageNo + 1);
    };

    //get data on page
    const getWithdrawData = async (page) => {
        setLoading(true);
        let data = {
            signature: localStorage.getItem('signature'),
            page: page,
        }
        try {
            let response = await requestHandler('get_withdraw_list', 'post', data, 'jwt_token')
            setLoading(false);
            setWithdrawData(response.data.data)

        } catch (error) {
            setLoading(false);
        }
    }

    const handleRefresh = () => {
        setForceRefresh(!forceRefresh)
    }

    return (
        <>
            <Wallet userStatus={userStatus}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <div className={styles.body}>
                            <Funds
                                withdrawData={withdrawData}
                                handleRefresh={handleRefresh}
                                pageNo={pageNo}
                                handleLoadMore={handleLoadMore}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </Wallet>
            <Modal
                visible={visibleWithdraw}
                onClose={() => setVisibleWithdraw(false)}
            >
                <Withdraw />
            </Modal>
        </>
    );
};


export default FiatWithdrawList;