import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getDigitsAfterDecimal } from "../helper";
import CustomDropdown from "../CustomDropdown";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../Notification";
import LoaderScreen from "../LoaderScreen";
import SimpleReactValidator from "simple-react-validator";
import { userStatusCreator } from "../../actions/getUserStatus";
import { useNavigate } from "react-router";
import { firstLetterCapitalHandler } from "../helper";

const fromDirectionOptions = ["Bitdenex International"];
const toDirectionOptions = ["Bitdenex Europe"];
const coinOptions = ["USDT Tether"];

const Transfer = ({ setVisibleTransfer }) => {
  const [fromDirection, setFromDirection] = useState(fromDirectionOptions[0]);
  const [toDirection, setToDirection] = useState(toDirectionOptions[0]);
  const [totalAmount, setTotalAmount] = useState("");
  const [text, setText] = useState("Select Coin");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [userBal, setUserBal] = useState();
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fromRegion, setFromRegion] = useState("");
  const [toRegion, setToRegion] = useState("");

  useEffect(() => {
    getTransferCoinHandler();
  }, []);

  const getUserCurrentBal = async (value) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      coinId: value
    };
    try {
      const getUserCurrentBalPayload = await requestHandler("getCoinBalance", "post", data, "jwt_token");
      setLoading(false);
      setUserBal(getUserCurrentBalPayload?.data?.data?.length > 0 ? getDigitsAfterDecimal(parseFloat(getUserCurrentBalPayload?.data?.data[0]?.balance), 4) : "0.00");
    }
    catch (e) {
      setLoading(false);
    };
  };

  const transferAmountHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      symbol: text.split("(")[0].toLowerCase(),
      amount: totalAmount,
    };
    try {
      const transferPayload = await requestHandler("transfer_ie", "post", data, "jwt_token");
      setLoading(false);
      if (transferPayload && transferPayload.status === 200) {
        addNotification({
          title: "Success",
          message:
            transferPayload?.data?.message[0]?.msg,
          type: "success",
        });
        setVisibleTransfer(false);
        dispatch(userStatusCreator());
        navigate("/coin-transfer-details");
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const getTransferCoinHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getTransferCoinPayload = await requestHandler("getExchangeTransferCoinList", "post", data, "jwt_token");
      setLoading(false);
      setFromRegion(getTransferCoinPayload?.data?.data[1]?.from);
      setToRegion(getTransferCoinPayload?.data?.data[1]?.to);
      if (getTransferCoinPayload && getTransferCoinPayload.status === 200) {
        const newArr = getTransferCoinPayload?.data?.data[0]?.map((x) => {
          return { "cId": x.id, "cValue": `${x.symbol.toUpperCase()}(${x.name.toUpperCase()})` }
        });
        setOptions([...newArr]);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <div className={styles.transfer}>
      {loading && <LoaderScreen />}
      <div className={cn("h5", styles.title)}>
        {/* <Icon name="arrow-left" size="32" /> */}
        {process.env.REACT_APP_COIN_TRANSFER_LIST_NAME}
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          Available <br></br>balance
        </div>
        <div className={styles.details}>
          <div className={styles.currency}>{userBal ? userBal : "0.00"}</div>
          <div className={styles.price}>{text === "Select Coin" ? "---" : text.split("(")[0].toUpperCase()}</div>
        </div>
      </div>
      {/* <div className={styles.field}>
        <Dropdown
          className={styles.dropdown}
          label="from"
          value={fromDirection}
          setValue={setFromDirection}
          options={fromDirectionOptions}
        />
      </div> */}
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="From"
          value={"Bitdenex " + firstLetterCapitalHandler(fromRegion)}
          disabled
        />
      </div>
      <div className={styles.sign}>
        <Icon name="arrows" size="16" />
      </div>
      {/* <div className={styles.field}>
        <Dropdown
          className={styles.dropdown}
          label="to"
          value={toDirection}
          setValue={setToDirection}
          options={toDirectionOptions}
        />
      </div> */}
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="To"
          value={"Bitdenex " + firstLetterCapitalHandler(toRegion)}
          disabled
        />
      </div>
      <div className={styles.field}>
        <CustomDropdown
          className={styles.dropdown}
          label="coin"
          value={value}
          setValue={setValue}
          text={text}
          setText={setText}
          options={options}
          handleChange={getUserCurrentBal}
          walletTransferFlag={true}
        />
      </div>
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="Amount to transfer"
          name="amount"
          type="text"
          placeholder="Please enter amount"
          value={totalAmount}
          onChange={(e) => { setTotalAmount(e.target.value); }}
          required
        />
        <button className={cn("button-stroke button-small", styles.button)} onClick={() => {
          setTotalAmount(userBal);
        }}>
          Max amount
        </button>
      </div>
      <span className={styles.danger}>
        {simpleValidator.current.message("amount", totalAmount, "required")}
      </span>
      <button className={cn("button", styles.button)} onClick={(e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
          if ((parseFloat(totalAmount) > parseFloat(userBal))) {
            addNotification({
              title: "Alert",
              message:
                "You don't have that much of amount available in your wallet.",
              type: "danger",
            });
          }
          else if (parseFloat(totalAmount) <= 0) {
            addNotification({
              title: "Alert",
              message:
                "Amount should be greater than 0.",
              type: "danger",
            });
          }
          else if (text.toLowerCase() === "select coin") {
            addNotification({
              title: "Alert",
              message:
                "Please select coin.",
              type: "danger",
            });
          }
          else {
            transferAmountHandler();
          };
        }
        else {
          simpleValidator.current.showMessages();
          setForceUpdate(forceUpdate + 1);
        };
      }}>Transfer</button>
    </div>
  );
};

export default Transfer;
