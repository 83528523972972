import cn from "classnames";
import styles from "./Main.module.sass";
import { Link } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import QrCode from "../../../components/QrCode";

const Main = () => {
  let url2 = 'http://173.16.1.143:3000/user-redirect'

  const linkHandler = () => {
    if (navigator.userAgent.match(/Android/)) {
      window.location.assign("https://play.google.com/store/apps/details?id=com.bitdenex")

    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {

      window.location.assign("https://apps.apple.com/in/app/bitdenex-buy-sell-crypto/id1640819532", '_blank');

    } else {

    }
  }
  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Download the app
          </div>
          <h3 className={cn("h3", styles.title)}> Trade.  <span>  Anywhere.</span> </h3>
          <div className={styles.text}>
            All the power of Bitdenex cryptocurrency exchange, in the palm of your hand. Download the Bitdenex mobile crypto trading app today.
          </div>
          <div>
            <QrCode value={process.env.REACT_APP_MOBILE_REDIRECT_URL} logoPath="/images/Qrcode.jpg" width={40} height={40} />
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/bitdenex-pay@2x.png"
            src="/images/content/bitdenex-pay.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
