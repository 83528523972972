
import React from "react";
import cn from "classnames";
import Skeleton from 'react-loading-skeleton'
import styles from "./OrderSkeleton.module.sass";
import 'react-loading-skeleton/dist/skeleton.css'

const OrderSkeleton = ({ rowCount, colCount }) => {
    var themeMode = localStorage.getItem('darkMode');
    return (
        Array(rowCount)
            .fill()
            .map((item, index) => (
                <div className={cn(styles.item)} key={index}>
                    <div className={styles.row}>
                        {Array(colCount)
                            .fill()
                            .map((i, x) => (
                                <div className={styles.col} key={x}>
                                    <Skeleton
                                        height={25}
                                        baseColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_BASE_DARK : process.env.REACT_APP_SKELETON_BASE_LIGHT}
                                        highlightColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_HIGHLIGHT_DARK : process.env.REACT_APP_SKELETON_HIGHLIGHT_LIGHT}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))
    );
};

export default OrderSkeleton;

