import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";

const Item = ({ className, item }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{item?.title}</h3>
        <p className={styles.text}>{item?.content}</p>
      </div>
    </div>
  );
};

export default Item;
