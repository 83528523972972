// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-BulletList-itemIcon{background-color:var(--osdk-color-background-icon);border-radius:50%}[dir=rtl] .onfido-sdk-ui-BulletList-itemIcon{-webkit-transform:scaleX(-1);transform:scaleX(-1)}.onfido-sdk-ui-BulletList-content{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center}.onfido-sdk-ui-BulletList-list{list-style:none;padding:0}.onfido-sdk-ui-BulletList-stage{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;padding-bottom:2em;position:relative}[dir=\"ltr\"] .onfido-sdk-ui-BulletList-stage::before{margin-left:1.4375em}[dir=\"rtl\"] .onfido-sdk-ui-BulletList-stage::before{margin-right:1.4375em}.onfido-sdk-ui-BulletList-stage::before{content:\"\";display:block;background-color:rgb(var(--ods-color-neutral-800));width:.125em;height:100%;position:absolute;top:0}.onfido-sdk-ui-BulletList-stage:last-child::before{display:none}.onfido-sdk-ui-BulletList-itemIcon{background-size:contain;-webkit-flex-shrink:0;flex-shrink:0;float:left;height:3em;width:3em;position:relative;z-index:1}[dir=\"ltr\"] .onfido-sdk-ui-BulletList-itemMessage{margin-left:.9375em}[dir=\"rtl\"] .onfido-sdk-ui-BulletList-itemMessage{margin-right:.9375em}.onfido-sdk-ui-BulletList-itemMessage{text-align:initial;-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1;-webkit-flex-basis:100%;flex-basis:100%;color:var(--osdk-color-content-body)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"itemIcon": "onfido-sdk-ui-BulletList-itemIcon",
	"content": "onfido-sdk-ui-BulletList-content",
	"list": "onfido-sdk-ui-BulletList-list",
	"stage": "onfido-sdk-ui-BulletList-stage",
	"itemMessage": "onfido-sdk-ui-BulletList-itemMessage"
};
export default ___CSS_LOADER_EXPORT___;
