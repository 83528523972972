import React from "react";
import Main from "./Main";

const HelpCenter = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default HelpCenter;
