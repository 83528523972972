import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";

const items = [
  {
    title: "General",
    items: [
      {
        title: "Do you issue refunds?",
        content:
          'Yes, we issue refunds of fiat, when applicable. We don’t issue refund of crypto coins. Once the trade is successful, no refund can be issued.',
      },
      {
        title: "Can I close my account any time?",
        content:
          'Yes, you can close your Bitdenex account anytime. You will get the chance to withdraw your cryptocurrency before closing the account.',
      },
      {
        title: "What payment methods do you accept?",
        content:
          'We accept the post used method in your country at this stage. See our available methods, depends on your residence. ',
      },
      {
        title: "Does it matter where I am from?",
        content:
          'Yes, All residence from an Embargoed country and the U.S.A. will not be accepted.',
      },
      {
        title: "Is there any limit of referrals?",
        content:
          'No, there is no limit on the amount of referrals. See the applicable terms and conditions of the Referral Program.',
      },
      {
        title: "Can you have more then one referral account?",
        content:
          'No, it is not allow to have multiple account for referrals. Any misuse of the Referral Program will be seen as a breach of the term of use.',
      },
    ],
  },
];

const Faq = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <h2 className={cn("h2", styles.title)}>Frequently asked questions</h2>
        </div>
        <div className={styles.list}>
          {items
            .find((x) => x.title === category)
            .items.map((x, index) => (
              <Item
                className={styles.item}
                item={x}
                index={index}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
