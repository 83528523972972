import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";

const Funds = ({
  children,
  walletTransferData,
}) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <button className={styles.link}>
          <span>Wallet Transfer Listing</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>From Wallet</div>
          <div className={styles.col}>To Wallet</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Coin</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date</div>
        </div>
        {
          walletTransferData?.length > 0 ?
            <>
              {walletTransferData?.map((x, index) => (
                <Item
                  className={styles.item}
                  item={x}
                  key={index}
                  children={children}
                />
              ))}
            </>
            :
            <div className={styles.btns}>
              <NoDataFound />
            </div>
        }
      </div>
    </div>
  );
};

export default Funds;
