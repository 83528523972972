import React, { useState } from "react";
import cn from "classnames";
import styles from "./Releases.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const navigationList = ["Bitcoin", "Blockchain", "Tutorials"];

const items = [
  {
    id: 1,
    slug: 'how-to-protect-yourself',
    title: "How to Protect Yourself Against Phishing",
    currency: "Phishing",
    content: `Trezor, a popular Bitcoin (BTC) hardware wallet, has informed that its newsletter has been compromised, alerting users of phishing attacks.
      `,
    category: "red",
    categoryText: "new",
    image: "/images/content/blog/How-to-Protect-Yourself-Against-Phishing.jpg",
    image2x: "/images/content/blog/How-to-Protect-Yourself-Against-Phishing.jpg",
    url: "/learn-crypto-details",
  },
  {
    id: 2,
    slug: 'what-do-you-mean-by-blockchain',
    title: "What do you mean by Blockchain Technology",
    currency: "Blockchain",
    content: `Blockchain technology eliminates the requirement for a trusted group to facilitate digital relationships and is the backbone of cryptocurrencies.
      `,
    category: "green",
    categoryText: "beginner",
    image: "/images/content/blog/What-do-you-mean-by-Blockchain-Technology.jpg",
    image2x: "/images/content/blog/What-do-you-mean-by-Blockchain-Technology.jpg",
    url: "/learn-crypto-details",
  },
  {
    id: 3,
    slug: 'how-to-store-bitcoin',
    title: "How to Store Your Bitcoin(BTC)?",
    currency: "Bitcoin",
    content: `Bitcoin wallets store the private keys that you need to access a Bitcoin address and expend your funds. But which type suits you best?
     `,
    category: "red",
    categoryText: "new",
    image: "/images/content/blog/How-to-Store-Your-Bitcoin.jpg",
    image2x: "/images/content/blog/How-to-Store-Your-Bitcoin.jpg",
    url: "/learn-crypto-details",
  },
];

const Releases = ({ scrollToRef }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [navigation, setNavigation] = useState(navigationList[0]);

  return (
    <div
      className={cn("section-bg section-mb0", styles.releases)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}></div>
          <div className={styles.wrap}>
            <h2 className={cn("h2", styles.title)}>Latest Releases</h2>
            <div className={styles.info}> </div>
            <div className={styles.nav}>
              {navigationList.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={navigation}
                setValue={setNavigation}
                options={navigationList}
              />
            </div>
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Releases;
