import React, { useState } from "react";
import cn from "classnames";
import styles from "./Download.module.sass";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../Icon";
import { Link } from "react-router-dom";
import QrCode from "../../QrCode";

const Download = ({ className }) => {
  const [visible, setVisible] = useState(false);
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.settings, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <Icon name="barcode" size="26" />
        </button>
        <div className={styles.body}>
          <QrCode value={process.env.REACT_APP_MOBILE_REDIRECT_URL} logoPath="/images/Qrcode.jpg" width={40} height={40} />  
          <div className={styles.menu}>Scan to download our app  </div>
          <Link className={styles.flag} to={'/bitdenex-app'}>   <span className={styles.language}>Read More..</span></Link>
        </div>
      </div>

    </OutsideClickHandler>
  );
};

export default Download;
