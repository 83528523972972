import { h, Fragment, FunctionComponent } from 'preact'
import styles from './style.module.scss'
import { Header } from '../Header'
import { Disclaimer } from '../Disclaimer'
import ScreenLayout from 'components/Theme/ScreenLayout'
import { localised } from '~core/localisation'
import { StepComponentProps } from '~types/routers'
import type { WithLocalisedProps } from '~core/localisation/types'
import { WithTrackingProps } from '~types/hocs'
import { trackComponent } from 'Tracker'
import classNames from 'classnames'
import theme from '../../Theme/style.scss'
import { Button } from '@onfido/castor-react'
import { StepOptionFace } from '~types/steps'

type Props = StepComponentProps & WithLocalisedProps & WithTrackingProps

const Intro: FunctionComponent<Props> = ({
  nextStep,
  trackScreen,
  translate,
  steps,
  currentStepType,
}: Props) => {
  const items = [
    translate('avc_intro.list_item_one'),
    translate('avc_intro.list_item_two'),
  ]

  const faceStep = steps.find(({ type }) => type === currentStepType)
  const recordMotionAudio = !!(faceStep?.options as StepOptionFace)
    ?.recordMotionAudio

  const actions = (
    <Fragment>
      <Disclaimer
        text={translate(
          recordMotionAudio
            ? 'avc_intro.disclaimer_camera_and_audio_on'
            : 'avc_intro.disclaimer'
        )}
      />
      <Button
        type="button"
        variant="primary"
        className={classNames(theme['button-centered'], theme['button-lg'])}
        onClick={() => {
          trackScreen('intro_ready_clicked')
          nextStep()
        }}
        data-onfido-qa="motion-continue-btn"
      >
        {translate('avc_intro.button_primary_ready')}
      </Button>
    </Fragment>
  )

  return (
    <ScreenLayout
      actions={actions}
      className={styles.wrapperBody}
      pageId={'MotionIntro'}
    >
      <div className={styles.intro}>
        <video autoPlay loop muted playsInline className={styles.introVideo}>
          <source
            src={`${process.env.ACTIVE_VIDEO_LIVENESS}/assets/intro-optimized.webm`}
            type="video/webm"
          />
          <source
            src={`${process.env.ACTIVE_VIDEO_LIVENESS}/assets/intro-optimized.mp4`}
            type="video/mp4"
          />
        </video>

        <Header
          title={translate('avc_intro.title')}
          subtitle={translate('avc_intro.subtitle')}
        />

        <ul className={styles.list}>
          {items.map((item, index) => (
            <li className={styles.listItem} key={index}>
              <span className={styles.text}>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </ScreenLayout>
  )
}

export default trackComponent(localised(Intro), 'intro')
