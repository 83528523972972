import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";



const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h4>Token/Coin Listing on Bitdenex</h4>
          <p>
            As we continue to expand, we aim to make the best efforts to select top-quality tokens/coins, and allow traders easy access to their preferred crypto assets!
          </p>
          <h4>How to get my project token/coin listed on Bitdenex?</h4>
          <p>The process is straightforward — simply submit your application via this <a href="https://docs.google.com/forms/d/e/1FAIpQLSf9M8EDfqxl_Hje0Rjf4Z-og35psXsL9CzZ445Kyf2tRyqaAw/viewform?usp=share_link" target="_blank">Google form! </a>Our spot team will reach back out if you pass the initial review.</p>
          <h3>What to expect during the review process?</h3>
          <p>We may require additional information. However, this does not guarantee that your
            project token/coin will be listed on Bitdenex. If your project passes our initial review, a
            dedicated Business Development (BD) manager will contact you via email.</p>

          <h3>What to expect after my project token/coin gets listed successfully?</h3>
          <p>Please update the dedicated BD manager on your project progress regularly (i.e. each week or every month), and provide Bitdenex with promotional resources.</p>

          <p>Thank you for your support!</p>






          <h3>NOTE: </h3>
          <p>
            1. Bitdenex mandates a strict and one-way non-disclosure agreement (NDA) at the first point
            of contact
          </p>
          <p>
            2. Bitdenex won’t charge any listing fees
          </p>
          {/* <figure>
            <img src="/images/content/terms/article.jpg" alt="Content" />
          </figure> */}

          <p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf9M8EDfqxl_Hje0Rjf4Z-og35psXsL9CzZ445Kyf2tRyqaAw/viewform?usp=share_link" target="_blank">Click here to get started</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Article;
