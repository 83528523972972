
import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "./PanelSkeleton.module.sass";

const PanelSkeleton = ({ rowCount }) => {
  var themeMode = localStorage.getItem('darkMode');
  return (
    Array(rowCount)
      .fill()
      .map((item, index) => (
        <div className={styles.item} key={index}>
          <Skeleton
            height={80}
            baseColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_BASE_DARK : process.env.REACT_APP_SKELETON_BASE_LIGHT}
            highlightColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_HIGHLIGHT_DARK : process.env.REACT_APP_SKELETON_HIGHLIGHT_LIGHT}
          />
        </div>
      ))
  );
};

export default PanelSkeleton;

