import { h, FunctionComponent } from 'preact'
import { useLocales } from '~core/localisation'
import classNames from 'classnames'
import style from './style.module.scss'
import { Button, Icon } from '@onfido/castor-react'
import { useRef, useState } from 'preact/hooks'

type Props = {
  src: string
  ariaLabel: string
  className?: string
}

const IntroVideo: FunctionComponent<Props> = ({
  src,
  ariaLabel,
  className,
}) => {
  const { translate } = useLocales()

  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const toggleVideo = () => {
    const video = videoRef.current
    if (video) {
      if (isPlaying) {
        video.pause()
      } else {
        video.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      toggleVideo()
    }
  }

  const handleLoadedData = () => {
    setIsLoading(false)
  }

  const handleError = () => {
    setIsLoading(false)
  }

  return (
    <div
      className={classNames(style.introVideo, className)}
      data-onfido-qa="intro-video"
    >
      <button className={style.videoWrapper} onKeyDown={handleKeyDown}>
        <video
          className={style.video}
          autoPlay
          loop
          muted
          playsInline
          aria-label={ariaLabel}
          ref={videoRef}
          onLoadedData={handleLoadedData}
          onError={handleError}
        >
          <source src={`${src}.webm`} type="video/webm" />
          <source src={`${src}.mp4`} type="video/mp4" />
        </video>
      </button>
      {!isLoading && (
        <Button
          className={style.button}
          kind="action"
          variant="primary"
          aria-label={translate(
            isPlaying
              ? 'video_intro.button_pause_accessibility'
              : 'video_intro.button_play_accessibility'
          )}
          onClick={toggleVideo}
        >
          <Icon aria-hidden="true" name={isPlaying ? 'pause' : 'play'} />
        </Button>
      )}
    </div>
  )
}

export default IntroVideo
