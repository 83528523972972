import cn from "classnames";
import styles from "./Article.module.sass";
import Share from "../../../components/Share";
import Favorite from "../../../components/Favorite";

const Article = ({ blog }) => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h4>{blog ? blog.h1 : null}</h4>
          <p>{blog ? blog.P1 : null}</p>

          {blog && blog.h2 ? <h3> {blog.h2} </h3> : null}
          {blog && blog.P2 ? <p> {blog.P2}</p> : null}
          {/* <p>
            Mix-and-match dozens of responsive elements to quickly configure
            your favorite landing page layouts or hit the ground running with 10
            pre-built templates, all in light or dark mode."
          </p> */}
          <figure>
            <img src={blog?.imageBlog2} alt="Content" />
          </figure>
          {blog && blog.h3 ? <h3> {blog.h3} </h3> : null}
          {blog && blog.P3 ? <p> {blog.P3}</p> : null}

          {/* <p>
            Mix-and-match dozens of responsive elements to quickly configure
            your favorite landing page layouts or hit the ground running with 10
            pre-built templates, all in light or dark mode."
          </p> */}

          {/* {blog.listing ? blog.listing.map((x) => <li>{x.li}</li>) : null} */}

          {blog && blog.h4 ? <h3> {blog.h4} </h3> : null}
          {blog && blog.P4 ? <p> {blog.P4}</p> : null}

          {/* <p>
            The Stacks series of products: Stacks: Landing Page Kit, Stacks:
            Portfolio Kit, Stacks: eCommerce Kit. "Stacks is a production-ready
            library of stackable content blocks built in React Native.
          </p>
          <p>
            Mix-and-match dozens of responsive elements to quickly configure
            your favorite landing page layouts or hit the ground running with 10
            pre-built templates, all in light or dark mode."
          </p> */}

          {blog && blog.h5 ? <h3> {blog.h5} </h3> : null}
          {blog && blog.P5 ? <p> {blog.P5}</p> : null}

          {blog && blog.h6 ? <h3> {blog.h6} </h3> : null}
          {blog && blog.P6 ? <p> {blog.P6}</p> : null}

          {blog && blog.h7 ? <h3> {blog.h7} </h3> : null}
          {blog && blog.P7 ? <p>{blog.P7}</p> : null}



          {blog && blog.h8 ? <h3> {blog.h8} </h3> : null}
          {blog && blog.P8 ? <p>{blog.P8}</p> : null}




          {blog && blog.h9 ? <h3> {blog.h9} </h3> : null}
          {blog && blog.P9 ? <p>{blog.P9}</p> : null}




          {blog && blog.h10 ? <h3> {blog.h10} </h3> : null}
          {blog && blog.P10 ? <p>{blog.P10}</p> : null}


          {blog && blog.h11 ? <h3> {blog.h11} </h3> : null}
          {blog && blog.P11 ? <p>{blog.P11}</p> : null}

          {blog && blog.h12 ? <h3> {blog.h12} </h3> : null}
          {blog && blog.P12 ? <p>{blog.P12}</p> : null}

          {blog && blog.h13 ? <h3> {blog.h13} </h3> : null}
          {blog && blog.P13 ? <p>{blog.P13}</p> : null}


        </div>
        <div className={styles.actions}>
          <Share openUp />
          <Favorite className={styles.favorite} />
        </div>
      </div>
    </div>
  );
};

export default Article;
