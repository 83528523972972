import React from "react";
import cn from "classnames";
import styles from "./Releases.module.sass";
import Item from "./Item";

const newItems = [
  {
    title: "Spot Trading",
    content: "Buy BDNX with EUR, USDT and USDC.",
  },
  {
    title: "Buy with fiat",
    content: "Buy BDNX with fiat through Quick Buy.",
  },
  // {
  //   title: "Convert small balances",
  //   content:
  //     'Users can convert assets worth less than 5 USDT in their account for BDNX at a 2% exchange fee. Select "Convert small balances to BDNX" on the Spot section of Assets page on the website or the app.',
  // },
];

const Releases = ({ scrollToRef }) => {

  return (
    <div
      className={cn("section-mb0", styles.releases)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.wrap}>
            <h2 className={cn("h2", styles.title)}>Get BDNX</h2>
          </div>
        </div>
        <div className={styles.list}>
          {newItems.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Releases;
