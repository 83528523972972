import { EXCHANGE_REQUEST, EXCHANGE_SUCCESS, EXCHANGE_FAILED, EXCHANGE_EMPTY, EXCHANGE_UPDATE_BUY, EXCHANGE_UPDATE_SELL } from '../actions/exchange/actionType';


const initialState = {

    loading: false,
    exchangeData: [],
    exchangeError: [],
    buy: [],
    sell: [],
    marketTrades: [],
    openOrders: [],
    currentMarketDetails: []

}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case EXCHANGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EXCHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                exchangeData: action.payload,
                buy: action.payload.data.data.buy,
                sell: action.payload.data.data.sell,
                marketTrades: action.payload.data.data.market_trades,
                openOrders: action.payload.data.data.open_order,
                currentMarketDetails: action.payload.data.data.current_market_details,
            };

        case EXCHANGE_FAILED:
            return {
                ...state,
                loading: false,
                exchangeError: action.error.data.message[0]
            };
        case EXCHANGE_EMPTY:
            return {
                state: null
            };


        case EXCHANGE_UPDATE_BUY:
            state.buy = []
            action.data.map(x => { state.buy.push(x) })
            return state;

        case EXCHANGE_UPDATE_SELL:
            state.sell = []
            action.sell.map(x => { state.sell.push(x) })
            return state;
        default:
            return state
    };
};

export default reducer;