import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { getDigitsAfterDecimal } from "../../../../components/helper";

const ConfirmationModal = ({
  redeemStakeHandler,
  setVisibleRedeemModal,
  redeemingItem
}) => {

  const totalStakeProfit = parseFloat(redeemingItem?.totalStakeProfit) || 0;
  const earlyRedeemPenalty = parseInt(redeemingItem?.penality) || 0;

  const penaltyAmount = (totalStakeProfit * earlyRedeemPenalty) / 100;

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h3", styles.title)}>
          Confirmation!
        </div>
        <div className={styles.info}>
          Are you sure to Redeem this Stake?
        </div>
        <p className={styles.customWrap}>Please note that if this is an early redemption, early redeem penalty will be applied to the redeemed amount.</p>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsGrid}>
            <div className={styles.detail}>
              <span className={styles.label}>Contract Id:</span>
              <span className={styles.value}>{redeemingItem?.contract_id === null || redeemingItem?.contract_id === undefined ? "---" : redeemingItem?.contract_id}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Total Earnings:</span>
              <span className={styles.value}>{getDigitsAfterDecimal(redeemingItem?.totalStakeProfit, redeemingItem?.amountDecimalPrecision)} <small>{redeemingItem?.coinSymbol?.toUpperCase()}</small></span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Early Redeem Penalty:</span>
              {/* <span className={styles.value}>{getDigitsAfterDecimal(parseFloat(redeemingItem?.totalStakeProfit) * parseInt(redeemingItem?.earlyRedeemPenalty) / 100, redeemingItem?.amountDecimalPrecision)} <small>{redeemingItem?.coinSymbol?.toUpperCase()}</small></span> */}
              <span className={styles.value}>{getDigitsAfterDecimal(penaltyAmount, redeemingItem?.amountDecimalPrecision)} <small>{redeemingItem?.coinSymbol?.toUpperCase()}</small></span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Total Days:</span>
              <span className={styles.value}>{redeemingItem?.dayDifference}/{redeemingItem?.totalDays}</span>
            </div>
            <div className={styles.btns}>
              <button
                className={cn("button-small button-red", styles.button)}
                onClick={() => {
                  setVisibleRedeemModal(false);
                }}
              >
                No
              </button>
              <button
                className={cn("button-small button-green", styles.button)}
                onClick={() => {
                  redeemStakeHandler(redeemingItem?.id);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
