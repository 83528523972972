import cn from "classnames";
import styles from "./StepOne.module.sass";

const StepOne = ({ goNext, userStatus }) => {

  return (
    <div>
      {(userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) || userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_ERROR)) && (
        <>
          <div className={styles.stage}>
            Enable KYC access on your account to Enjoy  more benefits.
          </div>
          <div className={cn("h3", styles.title)}>
            KYC Verification  is <span>Pending</span>
          </div></>
      )}


      {(userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_UNDER_VERIFICATION) || userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_INREVIEW)) && (
        <>
          <div className={cn("h3", styles.title)}>
            KYC Verification  is <span style={{ color: 'blue' }}>under Process</span>
          </div>
        </>
      )}

      {userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE) && (
        <>
          <div className={cn("h3", styles.title)}>
            Your Kyc verification is  <span style={{ color: 'green' }} >completed</span>
          </div>

        </>
      )}
      {/* <div className={styles.email}>
        <Icon name="email" size="24" />
        schinner@ui8.net
      </div> */}
      {/* <div className={styles.subtitle}>Enable KYC</div> */}
      {/* <div className={styles.info}>
        To start KYC  Verification process hit the button below
      </div> */}
      {/* <div className={styles.row}>
        <TextInput
          className={styles.field}
          label="password"
          name="password"
          type="password"
          placeholder="Password"
          required
          view
        />
        <TextInput
          className={styles.field}
          label="2FA code"
          name="code"
          type="text"
          required
        />
      </div> */}
      <div className={styles.btns}>
        {(userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) || userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_ERROR)) && (
          <button className={cn("button", styles.button)} onClick={goNext}>
            Start Now
          </button>)}
      </div>
    </div>
  );
};

export default StepOne;
