// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-Confirm-fullscreen{overflow:unset}.onfido-sdk-ui-Confirm-previewsContainer{width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-flex:1;-webkit-flex:1;flex:1;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}.onfido-sdk-ui-Confirm-previewsContainer.onfido-sdk-ui-Confirm-previewsContainerIsFullScreen{top:0}.onfido-sdk-ui-Confirm-title{-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto}.onfido-sdk-ui-Confirm-message{margin:2em 0 1em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-message{margin:1em 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"fullscreen": "onfido-sdk-ui-Confirm-fullscreen",
	"previewsContainer": "onfido-sdk-ui-Confirm-previewsContainer",
	"previewsContainerIsFullScreen": "onfido-sdk-ui-Confirm-previewsContainerIsFullScreen",
	"title": "onfido-sdk-ui-Confirm-title",
	"message": "onfido-sdk-ui-Confirm-message"
};
export default ___CSS_LOADER_EXPORT___;
