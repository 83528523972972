import { Store } from 'react-notifications-component';

export const addNotification = (message) => {
    const notification = {
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        onRemoval: (id, removedBy) => { },
        dismiss: {
            duration: 5000,
            onScreen: true,
            timingFunction: 'ease-out',
            pauseOnHover: true
        }
    };
    Store.removeAllNotifications();
    return Store.addNotification({
        ...notification,
        title: message.title,
        message: message.message,
        type: message.type
    });
}