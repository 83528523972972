import { poaDocumentTypes } from 'components/DocumentSelector/documentTypes'
import { sign } from '~core/Crypto'
import {
  DocumentImageResponse,
  ImageQualityValidationPayload,
} from '~types/api'
import { CountryData, DocumentSides } from '~types/commons'
import { DocumentCapture, MetadataState } from '~types/redux'
import { DocumentTypes, PoaTypes } from '~types/steps'
import { blobToArrayBuffer, isOfMimeType, mimeType } from '~utils/blob'
import {
  uploadDocumentVideoMedia,
  UploadDocumentVideoMediaPayload,
} from '~utils/onfidoApi'

// create sdk validation payload to send to the backend.
const getIqsValidationRequestPayload = (
  maxRetryCount: number,
  imageQualityRetries: number,
  blob: Blob,
  documentType: DocumentTypes | undefined,
  side: DocumentSides,
  documentCountry: CountryData | undefined,
  poaDocumentType?: PoaTypes | undefined
): ImageQualityValidationPayload => {
  const isPoA = poaDocumentType && poaDocumentTypes.includes(poaDocumentType)
  const isRomanianNationalIdBack =
    documentType === 'national_identity_card' &&
    side === 'back' &&
    documentCountry?.country_alpha3 === 'ROU'
  const shouldPerformImageQualityValidations =
    !isOfMimeType(['pdf'], blob) && !isPoA && !isRomanianNationalIdBack
  const shouldDetectDocument = !isPoA
  const shouldReturnErrorForImageQuality =
    imageQualityRetries <= maxRetryCount - 1 // `imageQualityRetries` is zero index based
  const imageQualityErrorType = shouldReturnErrorForImageQuality
    ? 'error'
    : 'warn'

  // if the specific document detection (for example, detect_glare) issue happens on the backend:
  //  - if it was requested as an 'error', then an error 422 will be sent from the backend.
  //  - if it was requested as a 'warn', then there will be no HTTP error.
  // If an error is returned from the backend, the idea is to force the user to retry with a new document capture.
  // If a warning is returned, the end user can either retry a new document capture or upload the current document anyway.
  return {
    ...(shouldDetectDocument ? { detect_document: 'error' } : {}),
    ...(shouldPerformImageQualityValidations
      ? {
          detect_cutoff: imageQualityErrorType,
          detect_glare: imageQualityErrorType,
          detect_blur: imageQualityErrorType,
        }
      : {}),
  }
}

const uploadMFCVideo = (
  document_video: DocumentCapture & MetadataState,
  res: DocumentImageResponse,
  url: string | undefined,
  token: string,
  sign_document_video?: boolean
) => {
  const {
    blob,
    filename = `document_video_capture.${mimeType(blob)}`,
    sdkMetadata,
  } = document_video

  const data: UploadDocumentVideoMediaPayload = {
    file: { blob, filename },
    sdkMetadata,
    document_id: res.id,
  }

  if (sign_document_video) {
    return blobToArrayBuffer(blob)
      .then((buffer) => sign(buffer, token))
      .then(({ signature, nonce }) => {
        return uploadDocumentVideoMedia(
          data,
          url,
          token,
          undefined,
          undefined,
          {
            s: signature,
            n: nonce,
          }
        )
      })
      .then(() => res)
  }

  return uploadDocumentVideoMedia(data, url, token).then(() => res)
}

export { getIqsValidationRequestPayload, uploadMFCVideo }
