import React, { useState } from "react";
import cn from "classnames";
import styles from "./StepThree.module.sass";
import requestHandler from "../../../actions/httpClient";
import LoaderScreen from "../../../components/LoaderScreen";


const StepThree = ({ goNext, getToken }) => {
  const [loading, setLoading] = useState(false)
  // const { sdkToken } = useSelector((state) => { return state.getSdkToken });
  // const [sdkToken, setSdkToken] = useState()
  // const [workFlowRunId, setWorkFlowRunId] = useState()

  const handleButton = async () => {
    setLoading(true)
    try {

      let data = {
        signature: localStorage.getItem("signature"),
      };
      const payload = await requestHandler('getSdkToken', 'post', data, 'jwt_token')
      // setSdkToken(payload.data.data.sdk_token)
      // setWorkFlowRunId(payload.data.data.workflow_run_id)
      if (payload.status == 200) {
        setLoading(false)
        getToken(payload.data.data.sdk_token, payload.data.data.workflow_run_id)
        goNext()
      }

    } catch (error) {
      setLoading(false)
    }


  }
  // if (workFlowRunId && workFlowRunId !== '' && sdkToken && sdkToken !== '') {
  //   getToken(sdkToken, workFlowRunId)
  //   goNext()
  // }


  return (
    <div>
      {/* <div className={styles.preview}>
        <img
          srcSet="/images/content/lock@2x.png 2x"
          src="/images/content/lock.png"
          alt="Lock"
        />
      </div> */}

      <div className={cn("h4", styles.title)}>
        Please take Orignal KYC Document along with you
      </div>



      <div className={cn("h7", styles.text)}>
        Only upload the valid documents (ID Card, Driving License or Passport) with the same name as filled in the form on previous step.
      </div>
      {/* <div className={styles.email}>
        <Icon name="email" size="24" />
        schinner@ui8.net
      </div> */}
      <div className={styles.btns}>
        {/* <button className={cn("button-stroke", styles.button)} onClick={goNext}>
          Go Back
        </button> */}
        <button className={cn("button", styles.button)} onClick={() => handleButton()}>
          Start Document Verification
        </button>
      </div>
      {loading && <LoaderScreen />}
    </div>
  );
};

export default StepThree;
