import { h } from 'preact'
import { mimeType } from '~utils/blob'
import {
  uploadDocument,
  UploadDocumentPayload,
  UploadVideoPayload,
} from '~utils/onfidoApi'
import { CapturePayload, DocumentCapture, FaceCapture } from '~types/redux'
import { getIqsValidationRequestPayload, uploadMFCVideo } from './utils'
import {
  CallbackNames,
  CALLBACK_TYPES,
  ConfirmProps,
  OnApiErrorCallback,
  OnApiSuccessCallback,
  OnSubmitCallback,
  useConfirm,
} from './useConfirm'
import Spinner from 'components/Spinner'
import Previews from './Previews'

export type PoaConfirmProps = Omit<
  ConfirmProps,
  'upload' | 'submitUpload' | 'method'
>

export const PoaConfirm = (props: PoaConfirmProps) => {
  const maxRetryCount =
    props.sdkConfiguration?.document_capture?.max_total_retries || 0

  const getIssuingCountry = () => {
    const { poaDocumentCountry } = props

    return { issuing_country: poaDocumentCountry?.country_alpha3 || 'GBR' }
  }

  const uploadCaptureToOnfido = (
    capture: CapturePayload,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback,
    onSubmitCallback: OnSubmitCallback
  ) => {
    const {
      side,
      token,
      poaDocumentType,
      imageQualityRetries,
      isDecoupledFromAPI,
      urls,
    } = props
    if (!token) {
      throw new Error('token not provided')
    }
    const url = urls.onfido_api_url
    const { blob, filename, sdkMetadata } = capture

    const issuingCountry = getIssuingCountry()

    // Make sure documents always have a filename
    // A `filename` might have been defined when the capture is created
    // if filename is not present, check if `blob` has a property `name` (only available for File types, which come from the html5 file picker)
    // alternatively use default filename
    const blobName =
      filename || (blob as File).name || `document_capture.${mimeType(blob)}`
    const { documentType: type } = capture as DocumentCapture
    const data: UploadDocumentPayload = {
      file: { blob, filename: blobName },
      type,
      side,
      validations: getIqsValidationRequestPayload(
        maxRetryCount,
        imageQualityRetries,
        blob,
        props.documentType,
        side,
        props.idDocumentIssuingCountry,
        poaDocumentType
      ),
      sdkMetadata: { ...sdkMetadata },
      ...issuingCountry,
    }
    if (isDecoupledFromAPI) {
      onSubmitCallback(data, CALLBACK_TYPES.document)
    } else {
      uploadDocument(data, url, token)
        .then((res) => {
          // Multi Frame Capture video
          if (props.captures.document_video) {
            return uploadMFCVideo(
              props.captures.document_video,
              res,
              url,
              token
            )
          }

          return res
        })
        .then(onApiSuccess)
        .catch(onApiError)
    }
  }

  const submitUpload = (
    data: FaceCapture | UploadVideoPayload | UploadDocumentPayload,
    callbackName: CallbackNames,
    url: string | undefined,
    token: string,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback
  ) => {
    uploadDocument(
      data as UploadDocumentPayload,
      url,
      token,
      onApiSuccess,
      onApiError
    )
    return
  }
  const {
    actions,
    capture,
    completeStep,
    crossDeviceClientError,
    enterpriseFeatures,
    nextStep,
    previousStep,
    resetSdkFocus,
    token,
    trackScreen,
    triggerOnError,
    urls,
  } = props

  const { uploadInProgress, confirmError, onConfirm, onRetake } = useConfirm(
    actions,
    capture,
    'poa',
    token,
    urls,
    completeStep,
    nextStep,
    previousStep,
    resetSdkFocus,
    submitUpload,
    trackScreen,
    triggerOnError,
    uploadCaptureToOnfido,
    crossDeviceClientError,
    enterpriseFeatures
  )

  if (uploadInProgress) {
    return <Spinner />
  }

  return (
    <Previews
      isFullScreen={!!props.isFullScreen}
      capture={props.capture}
      retakeAction={onRetake}
      confirmAction={onConfirm}
      isUploading={uploadInProgress}
      error={confirmError}
      trackScreen={props.trackScreen}
      method={'poa'}
      documentType={props.documentType}
      forceRetake={confirmError?.type === 'error'}
    />
  )
}

export default PoaConfirm
