import React, { useState } from "react";
import cn from "classnames";
import styles from "./SelectCurrency.module.sass";
import Dropdown from "../../../components/Dropdown";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { useEffect } from "react";
import CustomDropdown from "../../../components/CustomDropdown";
import Icon from "../../../components/Icon";
import { addNotification } from "../../../components/Notification";
import requestHandler from "../../../actions/httpClient";
import Checkbox from "../../../components/Checkbox";

const currencyOptions = ["SRD"];
const priceVariants = ["50.00 SRD", "100.00 SRD", "200.00 SRD", "500.00 SRD"];

let bankOptions = [];

const SelectCurrency = ({ calculateTransactionFee, calculatePriceAfterFee,
  price, setPrice, absoluteFee, setAbsoluteFee, relativeFee, setRelativeFee, depositFeeData, setDepositFeeData,
  setLoading, sepaData, referenceNumberGenerator, bankData, goNext, userDataHandler, handleSepaPayment,
  depositFlag, checkboxHandler, checkboxValue }) => {

  const [currency, setCurrency] = useState(currencyOptions[0]);
  const [bank, setBank] = useState();
  const [bankText, setBankText] = useState("Select a value");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();
  const [gateWayID, setGatewayId] = useState();
  const [name, setName] = useState("");

  // const handleValidate = (e) => {
  //   e.preventDefault();
  //   if (simpleValidator.current.allValid()) {
  //     if (bankText.toLowerCase().includes("sepa")) {
  //       if (price <= parseFloat(process.env.REACT_APP_MAX_SEPA_DEPOSIT_PER_TXN)) {
  //         if (price > 0) {
  //           userDataHandler(price, currency, bank, referenceNumberGenerator(), bankText, gateWayID);
  //           goNext();
  //         }
  //         else {
  //           addNotification({
  //             title: 'Alert',
  //             message: "You can't add negative values and 0 in price field.",
  //             type: 'Danger'
  //           })
  //         }
  //       }
  //       else {
  //         addNotification({
  //           title: 'Alert',
  //           message: "You can't add more than 50,000 EUR.",
  //           type: 'Danger'
  //         })
  //       }
  //     }
  //     else {
  //       if (price <= parseFloat(process.env.REACT_APP_MAX_ONLINE_DEPOSIT_PER_TXN)) {
  //         if (price > 0) {
  //           userDataHandler(price, currency, bank, referenceNumberGenerator(), bankText, gateWayID);
  //           goNext();
  //         }
  //         else {
  //           addNotification({
  //             title: 'Alert',
  //             message: "You can't add negative values and 0 in price field.",
  //             type: 'Danger'
  //           })
  //         }
  //       }
  //       else {
  //         addNotification({
  //           title: 'Alert',
  //           message: "You can't add more than 10,000 EUR from your bank account.",
  //           type: 'Danger'
  //         })
  //       }
  //     }
  //   }
  //   else {
  //     simpleValidator.current.showMessages();
  //     setForceUpdate(forceUpdate + 1);
  //   }
  // }

  const handleValidate = (enteredPrice) => {
    if (simpleValidator.current.allValid()) {
      if (bankText.toLowerCase().includes("wire")) {
        if (parseFloat(enteredPrice) <= parseFloat(process.env.REACT_APP_MAX_SEPA_DEPOSIT_PER_TXN)) {
          if (parseFloat(enteredPrice) > 0) {
            userDataHandler(enteredPrice, currency, bank, referenceNumberGenerator(), bankText, gateWayID);
            goNext();
          }
          else {
            addNotification({
              title: 'Alert',
              message: "You can't add negative values and 0 in price field.",
              type: 'Danger'
            })
          }
        }
        else {
          addNotification({
            title: 'Alert',
            message: "You can't add more than 50,000 SRD.",
            type: 'Danger'
          })
        }
      }
    }
    else {
      simpleValidator.current.showMessages();
      setForceUpdate(forceUpdate + 1);
    }
  };

  useEffect(() => {
    if (bankData && bankData?.length > 0) {
      bankOptions = [];
      bankData.map((x) => {
        bankOptions.push({
          "cId": x.id,
          "cValue": x.payment_mode,
          'cGatewayId': x.payment_gateway_id,
          "name": x.name,
        });
      })
    }
  }, [bankData]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSepaClick = (id, val, gateway_id) => {
    setGatewayId(gateway_id)
    if (sepaData.length === 0) {
      if (val.toLowerCase().includes("wire")) {
        handleSepaPayment();
      }
    }
  }

  const getDepositFeeHandler = async (text, name) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      name: name,
    };
    try {
      const getDepositFeePayload = await requestHandler("getFiatDepositFee", "post", data, "jwt_token");
      setLoading(false);
      setDepositFeeData(getDepositFeePayload?.data?.data);
      if (getDepositFeePayload.status === 200) {
        setRelativeFee(getDepositFeePayload?.data?.data[0]?.relative_fee);
        setAbsoluteFee(getDepositFeePayload?.data?.data[0]?.absolute_fee);
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <form className={styles.item} action="" onSubmit={() => handleSubmit()}>
      <div className={styles.title}>Select currency and payment method</div>
      {/* <div className={styles.secondText}>Note : This amount will reflect in your USDT wallet.</div> */}
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Select currency</div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={currency}
            setValue={setCurrency}
            options={currencyOptions}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Select payment</div>
          <CustomDropdown
            className={styles.dropdown}
            value={bank}
            setValue={setBank}
            text={bankText}
            depositFlag={true}
            handleSepaClick={handleSepaClick}
            setText={setBankText}
            options={bankOptions}
            depositFiatFlag={true}
            depositFiatHandler={getDepositFeeHandler}
          />
          <span>
            {simpleValidator.current.message("payment", bank, "required")}
          </span>
        </div>
      </div>
      <div className={styles.label}>Amount</div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>SRD</div>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            name="price"
            className={styles.input}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            onBlur={() => simpleValidator.current.showMessageFor("price")}
          />
        </div>
      </div>

      <div className={styles.price}>
        {price ? parseInt(price) : "0.00"}<span>SRD</span>
        <span>
          {simpleValidator.current.message("price", price, "required")}
        </span>
      </div>
      {/* <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            onClick={() => setPrice(x.replace("SRD", ""))}
            key={index}>
            {x}
          </button>
        ))}
      </div> */}

      {/* {price !== "0.00" && <div className={styles.customTitle}>You are depositing {price} EUR and you will get {calculatePriceAfterFee()} EUR in your wallet.</div>} */}
      {/* {depositFeeData?.length > 0 && (!bankText.toLowerCase().includes("sepa")) && */}
      {/* {(bankText != "Select a value") && (!bankText.toLowerCase().includes("sepa")) &&
        <>
          <div className={styles.customInfo}>Amount Details</div>
          <div className={styles.list}>
            <div className={styles.line}>
              <div className={styles.subtitle}>Entered Amount</div>
              <div className={styles.details}>
                <div className={styles.content}>{price} <span className={styles.currencyText}>EUR</span></div>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>You Receive</div>
              <div className={styles.details}>
                <div className={styles.content}>{isNaN(calculatePriceAfterFee()) ? "0.00" : calculatePriceAfterFee()} <span className={styles.currencyText}>EUR</span></div>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Fees(Relative + Absolute)</div>
              <div className={styles.details}>
                <div className={styles.content}>{isNaN(calculateTransactionFee()) ? "0.00" : calculateTransactionFee()} <span className={styles.currencyText}>EUR</span></div>
                </div>
            </div>
          </div>
        </>
      } */}

      {/* <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={(e) => handleValidate(e)}>
          Continue
        </button>
      </div> */}
      {bankText && bankText.toLowerCase().includes("wire") && (
        <>
          <div className={styles.info}>Bank account</div>
          <div className={styles.list}>
            <div className={styles.line}>
              <div className={styles.subtitle}>Account name</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.name}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.name).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Account number / IBAN</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.account_number_iban}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.account_number_iban).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Address</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.address}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.address).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>BIC/SWIFT Code</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.bic_swift_code}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.bic_swift_code).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Bank Address</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.bank_address}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.bank_address).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* <div className={styles.checkbox}>
        <Checkbox
          className={styles.checkbox}
          value={checkboxValue}
          onChange={() => checkboxHandler()}
          content="Swift payments are subject to charges/transaction fees and is based on the amount you send. All charges and fees related to any Swift payments will be paid by the Customer. In case Bitdenex needs to pay any fees for accepting your deposit, this amount will be deducted from your deposit amount. The left over deposit amount will be credited in your wallets as USDT."
        />
      </div> */}
      <div className={styles.btns}>
        <button
          // disabled={!checkboxValue}
          className={cn("button", styles.button)}
          onClick={(e) => {
            e.preventDefault();
            handleValidate(price);
            // if (checkboxValue) {
            //   handleValidate(e);
            // }
            // else {
            //   addNotification({
            //     title: "Alert",
            //     message: "Please select the checkbox.",
            //     type: "danger",
            //   });
            // }
          }
          }>
          Continue
        </button>
      </div>
    </form >
  );
};

export default SelectCurrency;
