import React from "react";
import Main from "./Main";


const BitdenexApp = () => {
  if (navigator.userAgent.match(/Android/)) {
    window.location.assign(process.env.REACT_APP_ANDROID_APP_URL)
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {
    window.location.assign(process.env.REACT_APP_IOS_APP_URL, '_blank');
  }

  return (
    <Main />
  );
};

export default BitdenexApp;
