import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";
import { useDispatch } from "react-redux";
import requestHandler from "../../../actions/httpClient";


const items = [
  {
    title: "Profile",
    icon: "user",
    content: "Important account details",
    url: "/profile-info",
  },

  {
    title: "Kyc Verification",
    icon: "eye",
    content: "Submit your KYC",
    url: "/user-kyc-verification",
  },

  {
    title: "Referrals & Affiliate",
    icon: "share",
    url: "/referral-and-affiliate",
    content: "Referral & Affiliate",
  },

  // {
  //   title: "2FA security",
  //   icon: "lock",
  //   content: "Setup 2FA for more security",
  //   url: "/2fa",
  // },
  // {
  //   title: "Settings",
  //   icon: "cog",
  //   content: "View additional settings",
  //   url: "/profile-info",
  // },

  {
    title: "Dark mode",
    icon: "theme-dark",
    content: "Switch dark/light mode",
  },

];

const User = ({ className, userStatus }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch()
  const logOutHandler = async () => {
    const data = {
      signature: localStorage.getItem('signature')
    }
    try {
      requestHandler('logout', 'post', data, 'jwt_token')
    } catch (error) {
    }
    deleteAllCookies();
    localStorage.removeItem('signature');
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('switch_exchange');
    dispatch({
      type: 'CLEAR_STATE',
    });
    window.location.href = process.env.REACT_APP_GLOBAL_URL;
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src="/images/content/avatar-user.jpg" alt="Avatar" />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) =>
              userStatus?.role === process.env.REACT_APP_CORPORATE_ROLE ?
                x.title === "Kyc Verification" ?
                  ""
                  :
                  x.url ? (
                    <Link
                      className={styles.item}
                      to={x.url}
                      onClick={() => setVisible(!visible)}
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.details}>
                        <div className={styles.title}>{x.title}</div>
                        <div className={styles.content}>{x.content}</div>
                      </div>
                    </Link>
                  ) : (
                    <div className={styles.item} key={index}>
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.details}>
                        <div className={styles.line}>
                          <div className={styles.title}>{x.title}</div>
                          <Theme className={styles.theme} small />
                        </div>
                        <div className={styles.content}>{x.content}</div>
                      </div>
                    </div>
                  )
                :
                x.url ? (
                  <Link
                    className={styles.item}
                    to={x.url}
                    onClick={() => setVisible(!visible)}
                    key={index}
                  >
                    <div className={styles.icon}>
                      <Icon name={x.icon} size="20" />
                    </div>
                    <div className={styles.details}>
                      <div className={styles.title}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </Link>
                ) : (
                  <div className={styles.item} key={index}>
                    <div className={styles.icon}>
                      <Icon name={x.icon} size="20" />
                    </div>
                    <div className={styles.details}>
                      <div className={styles.line}>
                        <div className={styles.title}>{x.title}</div>
                        <Theme className={styles.theme} small />
                      </div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                )
            )}
            <button
              className={styles.item}
              onClick={() => {
                logOutHandler();
              }}
            >
              <div className={styles.icon}>
                <Icon name="exit" size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.title}>Logout</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export default User;
