import React, { useState } from "react";
import cn from "classnames";
import styles from "./SelectCurrency.module.sass";
import Dropdown from "../../../components/Dropdown";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { addNotification } from "../../../components/Notification";
import { getDigitsAfterDecimal } from "../../../components/helper";
import LoaderScreen from "../../../components/LoaderScreen";

const currencyOptions = ["SRD"];
const priceVariants = ["50.00 SRD", "100.00 SRD", "200.00 SRD", "500.00 SRD"];

const SelectCurrency = ({ goNext, priceHandler, eurData, loading }) => {
  const [currency, setCurrency] = useState(currencyOptions[0]);
  const [price, setPrice] = useState("0.00");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form className={styles.item} action="" onSubmit={() => handleSubmit()}>
      {loading && <LoaderScreen />}
      <div className={styles.title}>Select currency and payment method</div>
      <div className={styles.title}>
        <Dropdown
          label="Select currency"
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={currency}
          setValue={setCurrency}
          options={currencyOptions}
        />
      </div>
      <div className={styles.label}>Amount</div>
      <div className={styles.text}>Available Balance: SRD {eurData?.balance ? getDigitsAfterDecimal(eurData?.balance, eurData?.decimalPrecision) : "0.00"}</div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>SRD</div>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            name="price"
            className={styles.input}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            onBlur={() => simpleValidator.current.showMessageFor("price")}
          />
        </div>
      </div>

      <div className={styles.price}>
        {price ? parseFloat(price) : "0.00"}<span>SRD</span>
        <span>
          {simpleValidator.current.message("price", price, "required")}
        </span>
      </div>
      {/* <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            onClick={() => setPrice(x.replace("SRD", ""))}
            key={index}
          >
            {x}
          </button>
        ))}
      </div> */}
      <div className={styles.text}>Note: We will approve your withdraw request within 24 hours during business days.</div>

      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (parseFloat(price) <= eurData?.balance) {
              if (price >= parseFloat(process.env.REACT_APP_MIN_FIAT_WITHDRAW_PER_TXN)) {
                priceHandler(price, currency)
                goNext();
              } else {
                addNotification({
                  title: 'Alert',
                  message: `You can't withdraw an amount less than ${parseFloat(process.env.REACT_APP_MIN_FIAT_WITHDRAW_PER_TXN)}.`,
                  type: 'Danger'
                })
              }
            }
            else {
              addNotification({
                title: 'Alert',
                message: "You don't have that much amount available in your wallet.",
                type: 'Danger'
              })
            }
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}>
          Continue
        </button>
      </div>
    </form>
  );
};

export default SelectCurrency;
