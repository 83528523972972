import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./FiatDepositList.module.sass";
import Wallet from "../../components/Wallet";
import Funds from "./Funds";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import LoaderScreen from "../../components/LoaderScreen";

const FiatDepositList = () => {
    const [transferCryptoData, setTransferCryptoData] = useState([]);
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);

    useEffect(() => {
        getTransferCryptoList();
    }, [pageNo]);

    const getTransferCryptoList = async () => {
        setLoading(true);
        try {
            let data = {
                signature: localStorage.getItem("signature"),
                page: pageNo,
            }
            const getTransferCryptoPayload = await requestHandler("getAllconvertCoin", "post", data, "jwt_token");
            setLoading(false);
            setTransferCryptoData(getTransferCryptoPayload?.data?.data);
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: "Something went wrong.",
                type: "danger"
            });
        }
    };

    return (
        <>
            {loading && <LoaderScreen />}
            <Wallet userStatus={userStatus}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <div className={styles.body}>
                            <Funds
                                transferCryptoData={transferCryptoData}
                                pageNo={pageNo}
                                setPageNo={setPageNo}
                            />
                        </div>
                    </div>
                </div>
            </Wallet>
        </>
    );
};


export default FiatDepositList;