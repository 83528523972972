// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[dir=\"ltr\"] .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon{margin-right:.875em}[dir=\"rtl\"] .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon{margin-left:.875em}.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon{height:2.25em;width:2.25em;float:left}[dir=rtl] .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon{-webkit-transform:none;transform:none}.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadList{display:inline-block;padding:0;margin-top:3em;margin-bottom:3.5em}.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem{display:-webkit-box;display:-webkit-flex;display:flex;margin-bottom:1.5em;list-style:none;text-align:left}.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-listText{line-height:2.25}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"icon": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon",
	"uploadList": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadList",
	"uploadListItem": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem",
	"listText": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-listText"
};
export default ___CSS_LOADER_EXPORT___;
