import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Download.module.sass";


const items = [
  {
    title: "App Store",
    content: "Download from",
    image: "/images/content/apple.svg",
    url: "https://apps.apple.com/in/app/bitdenex-buy-sell-crypto/id1640819532",
    qrcode: "/images/content/qrcode/qrcode-ios.png",
  },
  {
    title: "Google Play",
    content: "Download from",
    image: "/images/content/google-play.svg",
    url: 'https://play.google.com/store/apps/details?id=com.bitdenex',
    qrcode: "/images/content/qrcode/qrcode-android.png",
  }
];

const Download = () => {
  return (
    <div className={cn("section-mb0", styles.download)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/download-pic@2x.png 2x"
            src="/images/content/download-pic.png"
            alt="Download"
          />
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h3", styles.title)}>Trade anywhere</h2>
          <div className={styles.info}>
            Buy and sell cryptocurrencies On The Go. Trade whenever, wherever and always online. We strive to create a great user experience while trading on our Web-exchange or on our IOS and Android Apps.
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <a href={x.url} target="__blank" className={styles.item} key={index}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Logo" />
                </div>
                <div className={styles.details}>
                  <div className={styles.content}>{x.content}</div>
                  <div className={styles.subtitle}>{x.title}</div>
                </div>
                <div className={styles.qrcode}>
                  <img src={x.qrcode} alt="qrcode" />
                </div>
              </a>
            ))}
            {/* <QrCode value={process.env.REACT_APP_MOBILE_REDIRECT_URL} logoPath="/images/Qrcode.jpg" width={40} height={40} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
