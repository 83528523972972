import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({ active, handleClick }) => {
  return (
    <div className={styles.main}>
      <h4 className={cn("h4", styles.title)}>Orders History</h4>
      <div className={styles.list}>
        <div className={styles.item} >
          <div className={styles.line}>
            {active ?
              <>
                <button
                  className={cn("button button-small", styles.button)}
                  onClick={(e) => {
                    if (active === false) {
                      handleClick();
                    }
                  }}
                >
                  Limit Orders
                </button>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={(e) => {
                    if (active === true) {
                      handleClick();
                    }
                  }}
                >
                  Market Orders
                </button>
              </>
              :
              <>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={(e) => {
                    if (active === false) {
                      handleClick();
                    }
                  }}
                >
                  Limit Orders
                </button>
                <button
                  className={cn("button button-small", styles.button)}
                  onClick={(e) => {
                    if (active === true) {
                      handleClick();
                    }
                  }}
                >
                  Market Orders
                </button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
