import React, { useState, useEffect } from "react";
import Profile from "../../components/Profile";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
import Onfido from "../../components/Onfido";


const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "KYC verification",
  },
];

const UserKycVerification = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [token, setToken] = useState([]);
  const [workFlowRunId, setWorkFlowRunId] = useState();
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const dispatch = useDispatch()
  useEffect(() => {
    if (userStatus.length === 0) {
      dispatch(userStatusCreator())
    }
  }, [])

  const getToken = (token, workFlowRunId) => {
    setToken(token)
    setWorkFlowRunId(workFlowRunId)
  }



  return (
    <Profile title="KYC verification" breadcrumbs={breadcrumbs}>
      {activeIndex === 0 && (
        <StepOne goNext={() => setActiveIndex(1)} userStatus={userStatus} />
      )}

      {activeIndex === 1 && <StepTwo goNext={() => setActiveIndex(2)} userStatus={userStatus} />}
      {(activeIndex === 2 || userStatus?.kyc_verification === parseInt(process.env.REACT_APP_KYC_STATUS_USER_EXIT)) && <StepThree goNext={() => setActiveIndex(3)} getToken={getToken} />}
      {activeIndex === 3 && <Onfido token={token} workFlowRunId={workFlowRunId} />}
    </Profile>
  );
};

export default UserKycVerification;
