import { FunctionComponent, h } from 'preact'
import { trackComponent } from '../../Tracker'
import Selfie from '../Photo/Selfie'
import FaceVideo from '../FaceVideo'
import FaceMotion from '../FaceMotion'
import Uploader from '../Uploader'
import PageTitle from '../PageTitle'
import withCrossDeviceWhenNoCamera from './withCrossDeviceWhenNoCamera'
import GenericError from '../GenericError'
import FallbackButton from '../Button/FallbackButton'
import CustomFileInput from '../CustomFileInput'
import {
  isDesktop,
  addDeviceRelatedProperties,
  getUnsupportedMobileBrowserError,
} from '~utils'
import { buildStepFinder } from '~utils/steps'
import { randomId } from '~utils/string'
import { ImageValidationTypes, validateFile } from '~utils/file'
import { getInactiveError } from '~utils/inactiveError'
import { localised } from '~core/localisation'
import withTheme from '../Theme'
import theme from '../Theme/style.scss'
import style from './style.scss'
import { WithPageIdProps, WithTrackingProps } from '~types/hocs'
import type { WithLocalisedProps, ParsedTag } from '~core/localisation/types'
import { StepComponentBaseProps } from '~types/routers'
import { CapturePayload } from '~types/redux'
import { ImageResizeInfo } from '~types/commons'
import { CameraProps } from '~types/camera'
import { SdkError } from '~types/sdk'
import useMotion from '~contexts/useMotion'

const defaultPayload = {
  method: 'face',
  variant: 'standard',
  side: null,
}

const IDEAL_CAMERA_WIDTH_IN_PX = 1280

const WrappedError = withTheme(GenericError)

type FaceProps = {
  snapshotInterval: number
} & StepComponentBaseProps &
  WithLocalisedProps &
  WithTrackingProps &
  WithPageIdProps

const Face: FunctionComponent<FaceProps> = (props: FaceProps) => {
  const {
    pageId = undefined,
    actions: { createCapture, deleteCapture },
    nextStep,
    steps,
    mobileFlow,
    triggerOnError,
    changeFlowTo,
    hasCamera,
    translate,
  } = props
  const handleCapture = (payload: CapturePayload) => {
    const id = randomId()
    const faceCaptureData = {
      ...defaultPayload,
      ...payload,
      sdkMetadata: addDeviceRelatedProperties(payload.sdkMetadata, mobileFlow),
      id,
    }
    createCapture(faceCaptureData)
    nextStep()
  }

  const handleVideoCapture = (payload: CapturePayload) =>
    handleCapture({ ...payload, variant: 'video' })

  const handleUpload = (blob: Blob, imageResizeInfo?: ImageResizeInfo) =>
    handleCapture({
      blob,
      sdkMetadata: { captureMethod: 'html5', imageResizeInfo },
    })

  const handleError = (error: ImageValidationTypes | SdkError) => {
    const response = typeof error === 'string' ? { message: error } : error
    triggerOnError({ response })
    deleteCapture({ method: 'face' })
  }

  const handleFallbackClick = (callback?: () => void) => {
    changeFlowTo('crossDeviceSteps')
    callback && callback()
  }

  const handleFileSelected = (file: File) =>
    validateFile(file, handleUpload, handleError)

  const renderUploadFallback = ({ text }: ParsedTag) => (
    <CustomFileInput
      className={theme.errorFallbackButton}
      onChange={handleFileSelected}
      accept="image/*"
      capture="user"
    >
      {text}
    </CustomFileInput>
  )

  const renderCrossDeviceFallback = (
    { text }: ParsedTag,
    callback?: () => void
  ) => (
    <FallbackButton text={text} onClick={() => handleFallbackClick(callback)} />
  )

  const isUploadFallbackDisabled = () => !isDesktop && !uploadFallback

  const title = translate('selfie_capture.title')

  const cameraProps: Omit<CameraProps, 'buttonType'> = {
    renderTitle: <PageTitle title={title} smaller />,
    renderFallback: isDesktop
      ? renderCrossDeviceFallback
      : renderUploadFallback,
    isUploadFallbackDisabled: isUploadFallbackDisabled(),
  }

  const { deviceCanUseMotion } = useMotion()

  const faceStep = buildStepFinder(steps)('face')

  const {
    motionFallbackVariant = undefined,
    photoCaptureFallback = true,
    requestedVariant = 'standard',
    uploadFallback = true,
    useMultipleSelfieCapture = true,
    useUploader = false,
  } = faceStep?.options || {}

  // `hasCamera` is `true`/`false`, or `null` if the logic is still loading
  // its value.
  // We don't want to render while it's loading, otherwise we'll flicker
  // when we finally do get its value
  if (hasCamera === null) return null

  const isVideoCompatible = window.MediaRecorder != null

  if (hasCamera) {
    if (isVideoCompatible) {
      if (requestedVariant === 'motion' && deviceCanUseMotion) {
        return <FaceMotion {...props} {...cameraProps} />
      }

      if (
        requestedVariant === 'video' ||
        (requestedVariant === 'motion' && motionFallbackVariant === 'video')
      ) {
        return (
          <FaceVideo
            {...props}
            {...cameraProps}
            cameraClassName={style.faceContainer}
            onVideoCapture={handleVideoCapture}
            inactiveError={getInactiveError(!isDesktop)}
            onRedo={console.log}
          />
        )
      }
    }

    if (
      !useUploader &&
      (requestedVariant === 'standard' ||
        (requestedVariant === 'video' && photoCaptureFallback) ||
        (requestedVariant === 'motion' && motionFallbackVariant === 'standard'))
    ) {
      return (
        <Selfie
          {...props}
          {...cameraProps}
          onCapture={handleCapture}
          useMultipleSelfieCapture={useMultipleSelfieCapture}
          inactiveError={getInactiveError(isUploadFallbackDisabled())}
          idealCameraWidth={IDEAL_CAMERA_WIDTH_IN_PX}
          pageId={pageId}
        />
      )
    }
  }

  if (
    (requestedVariant === 'video' && !photoCaptureFallback) ||
    (requestedVariant === 'motion' && motionFallbackVariant !== 'standard')
  ) {
    return (
      <WrappedError
        disableNavigation={true}
        error={{ name: getUnsupportedMobileBrowserError() }}
      />
    )
  }

  if ((useUploader || hasCamera === false) && uploadFallback) {
    return (
      <Uploader
        {...props}
        onError={handleError}
        uploadType="face"
        onUpload={handleUpload}
        title={translate('photo_upload.title_selfie') || title}
        instructions={translate('photo_upload.body_selfie')}
        pageId={'SelfieUpload'}
      />
    )
  }

  return <GenericError error={{ name: 'INTERRUPTED_FLOW_ERROR' }} />
}

export default trackComponent(localised(withCrossDeviceWhenNoCamera(Face)))
