import React, { useState } from "react";
import cn from "classnames";
import styles from "./FiatDepositSuccess.module.sass";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const FiatDepositSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryString = useLocation().search;
  const id = new URLSearchParams(queryString).get("cko-session-id");
  const t_id = new URLSearchParams(queryString).get("transaction");
  const reference = new URLSearchParams(queryString).get("payment_reference");
  const [count, setCount] = useState(5);
  const [btnProperty, setButtonProperty] = useState(false)
  const [btnVisible, setBtnVisible] = useState(false)


  const someFunction = () => {
    setCount(count - 1);
  };

  useEffect(() => {
    if (count >= 1) {
      setTimeout(someFunction, 1000);
    }

    if (count === 5) {
      if (id) {
        deposit(id, reference);
      } else {
        deposit(t_id, reference);
      }
    }
  }, [count]);

  const buttonHandler = () => {
    setButtonProperty(true)
    if (id) {

      deposit(id, reference);
    } else {
      deposit(t_id, reference);
    }
  };

  const deposit = async (id, reference) => {
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        transaction_id: id,
        payment_reference: reference
      };
      const payload = await requestHandler("verify_payment", "post", data, "jwt_token");
      if (payload.status == 200) {
        navigate("/wallet-overview");
      }
    }
    catch (e) {
      setBtnVisible(true)
    };
  }
  return (
    <div className={styles.btns}>
      <div className={styles.labelContainer}>
        <div className={styles.heading}>
          Success
        </div>
        <div className={styles.label}>
          The page will redirect automatically after {count} seconds.....
        </div>
        {btnVisible && <div className={styles.label}>
          If not then click here..
        </div>}
      </div>
      {btnVisible && <button className={cn("button", styles.button)} onClick={() => buttonHandler()} disabled={btnProperty}>
        Continue
      </button>}
    </div >
  );
};

export default FiatDepositSuccess;
