import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ item }) => {

  return (
    <div className={styles.card}>
      <div className={styles.leftcolumn}>
        <svg width="32" height="32" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
          <g clip-path="url(#SmallExchange_svg__a)">
            <path d="M22.985 9.609V.932H.931v23.544h22.053V13.674" stroke="currentColor" stroke-width="1.865" stroke-linecap="round" stroke-linejoin="round"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.716 9.104H6.89a.622.622 0 0 0-.621.622v.621c0 .344.278.622.621.622h9.712c.554 0 .831-.67.44-1.061l-2.266-2.266a.622.622 0 0 0-1.061.44v1.022ZM9.726 15.655h6.513a.621.621 0 0 0 .622-.621v-.622a.621.621 0 0 0-.622-.622H6.838a.622.622 0 0 0-.44 1.061l2.267 2.266a.622.622 0 0 0 1.06-.44v-1.022Z" fill="#1DA2B4"></path>
          </g>
          <defs>
            <clipPath id="SmallExchange_svg__a">
              <path fill="#fff" d="M0 0h26.751v25.57H0z"></path>
            </clipPath>
          </defs>
        </svg>
        <strong className={styles.title}>{item.title}</strong>
      </div>
      <div className={styles.middlecolumn}>
        <p className={styles.description}>{item.content}</p>
      </div>
    </div>
  );
};

export default Item;
