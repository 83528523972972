
import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "./CardSkeleton.module.sass";

const CardSkeleton = ({ count }) => {
    var themeMode = localStorage.getItem('darkMode');
    return (
        Array(count)
            .fill()
            .map((item, index) => (
                <div className={styles.card} key={index}>
                    <Skeleton
                        height={100}
                        width="60%"
                        baseColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_BASE_DARK : process.env.REACT_APP_SKELETON_BASE_LIGHT}
                        highlightColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_HIGHLIGHT_DARK : process.env.REACT_APP_SKELETON_HIGHLIGHT_LIGHT}
                    />
                </div>
            ))
    );
};

export default CardSkeleton;

