import React from "react";
import Education from "../Contact/Education";
import Main from "./Main";

const LegalCenter = () => {
  return (
    <>
      <Main />
      <Education />
    </>
  );
};

export default LegalCenter;
