import React, { useRef } from "react";
import Main from "./Main";
import Releases from "./Releases";
import GetToken from "./GetToken";
import TokenInfo from "./TokenInfo";

const BitdenexToken = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Main />
      <Releases scrollToRef={scrollToRef} />
      <GetToken />
      <TokenInfo />
    </>
  );
};

export default BitdenexToken;
