
import {
    NEW_ORDER_REQUEST, NEW_ORDER_SUCCESS, NEW_ORDER_FAILED,NEW_ORDER_EMPTY
} from '../actions/newOrder/actionType'


const initialState = {
    loading: false,
    orderError: '',
    response: []

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case NEW_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_ORDER_SUCCESS:
            return {
                ...state,
                response: action.payload,
                loading:false
            }
        case NEW_ORDER_FAILED:
            return {
                ...state,
                orderError: action.error.data.message[0],
                loading:false
            }

            case NEW_ORDER_EMPTY:
            return {
                state:null
            }
        default:
            return state
    }
}

export default reducer;