import React from "react";
import styles from "./Funds.module.sass";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import Item from "./Item";
import cn from "classnames";
import WalletSkeleton from "../../../components/Skeleton/WalletSkeleton";

const Funds = ({ children, withdrawData, handleRefresh, handleLoadMore, pageNo, loading }) => {

  return (
    <div className={styles.wrap}>
      <div className={styles.line}>

        <button className={styles.link}>
          <span>Fiat withdraw Listing</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Currency</div>
          <div className={styles.col}>Reference</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Date</div>
          <div className={styles.col}>Cancel</div>
        </div>
        {
          loading ? <WalletSkeleton rowCount={10} colCount={6} /> :
            withdrawData?.length > 0 ?
              <>
                {
                  withdrawData?.map((x, index) => (
                    <Item
                      className={styles.item}
                      item={x}
                      key={index}
                      children={children}
                      handleRefresh={handleRefresh}
                      loading={loading}
                    />
                  ))
                }
                {withdrawData?.length === (10 * pageNo) && <div className={styles.customButton}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                    <span>Load more...</span>
                  </button>
                </div>
                }
              </>
              :
              <div className={styles.btns}>
                <NoDataFound />
              </div>
        }
      </div>
    </div>
  );
};

export default Funds;
