import React from "react";
import Main from "./Main";
import Faq from "./Faq";

const HelpCenter = () => {
  return (
    <>
      <Main />
      <Faq />
    </>
  );
};

export default HelpCenter;
