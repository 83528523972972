// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-DocumentAuto-docAutoCapture{padding:0 5%}.onfido-sdk-ui-DocumentAuto-footer{-webkit-box-flex:1;-webkit-flex:1;flex:1;display:grid;grid-template-rows:1fr 0fr;-webkit-box-align:start;-webkit-align-items:start;align-items:start}.onfido-sdk-ui-DocumentAuto-btn{cursor:pointer;outline-offset:.375em;background-color:rgb(var(--ods-color-neutral-white));font-size:inherit;border-radius:50%;border:.1875em solid rgb(var(--ods-color-neutral-black));-webkit-box-shadow:0 0 0 .25em rgb(var(--ods-color-neutral-white));box-shadow:0 0 0 .25em rgb(var(--ods-color-neutral-white));height:3.5em;margin-left:auto;margin-right:auto;padding:0;width:3.5em}.onfido-sdk-ui-DocumentAuto-btn:hover{background-color:var(--osdk-color-background-button-camera-hover)}.onfido-sdk-ui-DocumentAuto-btn:active{background-color:var(--osdk-color-background-button-camera-active)}.onfido-sdk-ui-DocumentAuto-btn:disabled{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"docAutoCapture": "onfido-sdk-ui-DocumentAuto-docAutoCapture",
	"footer": "onfido-sdk-ui-DocumentAuto-footer",
	"btn": "onfido-sdk-ui-DocumentAuto-btn"
};
export default ___CSS_LOADER_EXPORT___;
